#Announcement {
  width: 100%;
  min-height: 100vh;

  .Announcement-menu-box {
    // border: 1px solid red;
    width: 64px;
    height: 29px;
    border-radius: 444px;
    border: 1px solid var(--e1e1e1, #E1E1E1);


    .Announcement-menu {
      padding: 6px 10px;
      display: flex;
      align-items: center;
      color: var(--333, #333);
      text-align: center;
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      .Announcement-menu-img {
        margin-right: 4px;
      }
    }
  }


  .Announcement-title {
    border: 1px solid blue;

    .search-input {
      width: 75%;
      height: 38px;
      padding-left: 20%;
      // border: 1px solid red;
      // margin-top: 76px;
      position: relative;
      display: inline-block;
      border-radius: 12px;
      background: #F6F6F6;
      margin-left: 12px;
      position: fixed;
      top: 75px;
      left: 0;
      z-index: 1;
    }

    .search-input input[type="text"] {
      width: 65%;
      height: 38px;
      padding-left: 15%;
      outline: none;
      border: none;
      background: #F6F6F6;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }

    .search-inp::placeholder {
      color: #999;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }

    .search-input .search-icon {
      width: 18px;
      height: 18px;
      position: absolute;
      top: 52%;
      left: 25%;
      transform: translateY(-50%);
    }

    .Announcement-select {
      // border: 1px solid blue;
      position: fixed;
      left: 0;
      top: 112px;
      z-index: 1;
      padding-top: 2px;
      padding-bottom: 6px;
      background-color: #fff;
      border-bottom: 1px solid #AAB8C222;

      &-box {
        display: flex;
        align-items: center;
        justify-content: space-around;
        // height: 100px;
        //   width: 800px;
        padding: 14px 10px 0;

        &-item {
          font-size: 14px;
          color: #AAB8C2;
          padding-bottom: 10px;
        }

        .active {
          color: var(--color);
          position: relative;
          font-weight: bold;
        }

        .active::after {
          content: '';
          height: 3px;
          width: 100%;
          background: #4D33EF;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
  }


  .Announcement-center-Bigbox {
    // border: 1px solid red;
    margin-top: 158px;
    height: 630px;
    overflow: auto;

    .Announcement-center {
      // border: 1px solid red;
      border-radius: 12px;
      background: var(--grey_bg, #F6F6F6);
      backdrop-filter: blur(11px);
      margin-top: 14px;
      margin-left: 12px;
      margin-right: 12px;

      .Announcement-center-box {
        padding: 14px;
        color: #333;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;


        /* 21px */
        .Announcement-center-box-p {
          margin-top: 8px;
          color: #666;
          font-family: 'MiSans-Demibold';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          /* 20.28px */
        }
      }
    }
  }

}