#HelpCenter {
  width: 100%;
  min-height: 100vh;
  padding-top: 70px;

  .HelpCenter-box {
    width: 367px;
    margin: 0 auto;
  }

  .HelpCenter-h3 {
    margin-left: 8px;
    color: var(--black, #1D1D1F);
    font-family: PingFang SC;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 14px;
  }

  .HelpCenter-top {
    margin-left: 8px;
    display: flex;

    .HelpCenter-top-left {
      width: 174px;
      height: 74px;
      border-radius: 10px;
      background: var(--purple, #4D33EF);
      display: flex;

      .HelpCenter-top-left-p1 {
        color: #FFF;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-top: 12px;
        padding-left: 12px;
      }

      .HelpCenter-top-left-p2 {
        width: 100px;
        padding-left: 12px;
        color: rgba(255, 255, 255, 0.66);
        font-family: PingFang SC;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .HelpCenter-top-left-img {
        width: 68px;
        height: 68px;
        margin-top: 18px;
        margin-left: 12px;
        background: url(./../../../assets/imgs/HelpCenter-bj1.png) no-repeat;
        background-size: 50px;
      }
    }

    .HelpCenter-top-right {
      width: 174px;
      height: 74px;
      border-radius: 10px;
      background: var(--brand-brand-6-hover, #366EF4);
      margin-left: 12px;
      display: flex;

      .HelpCenter-top-right-p1 {
        color: #FFF;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-top: 12px;
        padding-left: 12px;
      }

      .HelpCenter-top-right-p2 {
        width: 100px;
        padding-left: 12px;
        color: rgba(255, 255, 255, 0.66);
        font-family: PingFang SC;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .HelpCenter-top-right-img {
        width: 68px;
        height: 68px;
        margin-top: 21px;
        margin-left: 12px;
        background: url(./../../../assets/imgs/HelpCenter-bj2.png) no-repeat;
        background-size: 50px;
      }
    }
  }

  .HelpCenter-center-h3 {
    color: var(--black, #1D1D1F);
    font-family: PingFang SC;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    margin-top: 22px;
    margin-left: 8px;
  }

  .HelpCenter-clooapse {
    margin-top: 20px;
    color: var(--black, #1D1D1F);
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    .HelpCenter-clooapse-panel {
      width: 369px;
      height: 64px;
      line-height: 40px;

      #RightOutline-RightOutlined {
        color: #1D1D1F;
      }

      #DownOutline-DownOutline {
        color: #1D1D1F;
      }
    }

    .HelpCenter-clooapse-panel-DownOutline {
      padding-bottom: 38px;
    }

    .HelpCenter-clooapse-panel-RightOutline {
      padding-bottom: 38px;
    }



  }

  .invit-wxbox {
    display: flex;
    padding: 24px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: var(--fff, #FFF);
    position: relative;
    width: 270px;
    height: 290px;
    box-sizing: border-box;
    margin-bottom: 69px;

    span {
      color: var(--black, #1D1D1F);
      text-align: center;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 29px;
      /* 207.143% */
      letter-spacing: 0.258px;
      margin-top: 14px;
    }

    &-close {
      position: absolute;
      bottom: -65px;
    }
  }
}

.HelpCenter-clooapse-panel-p {
  color: #666;
  font-family: PingFang SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 10px;
  width: 339px;
  height: 96px;

  .HelpCenter-clooapse-panel-a {
    color: #666;
  }
}

.HelpCenter-clooapse-panel-text {
  width: 329px;
  color: #666;
  font-family: PingFang SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.HelpCenter-clooapse-panel-text2 {
  width: 339px;
  height: 380px;
  color: #666;
}