#assetsDetail {
  min-height: 100vh;
  padding-bottom: 33px;
  padding-top: 20px;

  .title {
    font-weight: 700;
    font-size: 16px;
    color: var(--color);
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #header {
    background: #1d1d2c33;
    backdrop-filter: blur(9px);
  }

  .main {
    padding: 20px 0px 100px;

    .CommodityDetail-img {
      margin-top: 20px;
      width: 100%;
      height: 382px;
      object-fit: contain;
    }

    .box1 {
      display: flex;
      align-items: center;
      padding: 0 15px;
      margin-top: 14px;

      &-img {
        width: 17px;
        height: 17px;
        margin-right: 5px;
      }

      &-text {
        font-weight: 400;
        font-size: 12px;
        color: var(--color);
      }
    }

    .box2 {
      display: flex;
      align-items: center;
      margin-top: 8px;
      padding: 0 15px;

      &-text {
        font-weight: 700;
        font-size: 28px;
        color: var(--color);
        margin-right: 8px;
      }
    }

    .box3 {
      display: flex;
      align-items: center;
      padding: 0 15px;
      margin-top: 8px;

      &-item {
        display: flex;
        align-items: center;
        margin-right: 8px;

        &-text1 {
          background: #000;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px 8px;
          font-weight: 700;
          font-size: 10px;
          color: #fff;
        }

        &-text2 {
          background: #F6F6F6;
          height: 100%;
          border-radius: 0px 4px 4px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px 8px;
          font-size: 10px;
          font-family: ''MiSans-Demibold'-Demibold';
          color: #000;
        }
      }
    }

    .box4 {
      display: flex;
      flex-direction: column;
      margin: 18px 0 0;
      border-top: 1px solid #AAB8C2;
      border-bottom: 1px solid #AAB8C2;
      padding: 14px 15px;

      &-title {
        font-weight: 700;
        font-size: 16px;
        color: var(--color);
        margin-bottom: 10px;
      }

      &-data {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        &-text1 {
          font-weight: 400;
          font-size: 14px;
          color: var(--color);
        }

        &-text2 {
          font-weight: 400;
          font-size: 12px;
          color: var(--color);
          width: 72%;
          overflow: hidden;
          margin-right: 5px;
          text-overflow: ellipsis;
        }

        &-img {
          width: 62.18px;

          img {
            border-radius: 0;
          }
        }
      }
    }

    .box7 {
      display: flex;
      flex-direction: column;
      padding: 0 15px;

      .desc {
        display: flex;
        flex-direction: column;
        color: var(--gray);
        text-align: justify;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 21px */
        margin-top: 10px;

        img {
          width: 100%;
        }
      }
    }
  }

  .CommodityDetail-footer {
    display: flex;
    padding: 26px 14px 15px 13px;
    justify-content: space-between;
    align-items: self-end;
    background: rgba(255, 255, 255, 0.66);
    backdrop-filter: blur(2px);
    position: fixed;
    width: 100%;
    bottom: 0;
    box-sizing: border-box;

    &-left {
      display: flex;
      flex-direction: column;

      &-text1 {
        color: var(--gray);
        font-family: 'MiSans-Demibold';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 14.4px */
      }

      &-text2 {
        color: var(--color);
        font-family: 'MiSans-Demibold';
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
      }
    }

    &-btn {
      width: 176px;
      height: 61px;
      flex-shrink: 0;
      border-radius: 18px;
      background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4D33EF;
      box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.60) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.60) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.30) inset, -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
      color: #FFF;
      font-family: 'MiSans-Demibold';
      font-size: 17px;
      font-style: normal;
      font-weight: 900;
      line-height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      /* 188.235% */
    }
  }


  .Popup-top {
    border-radius: 12px;
    background: var(--grey_bg, #F6F6F6);

    .Popup-top-title {
      height: 49px;
      // border: 1px solid red;
      display: flex;


      .Popup-top-img {
        width: 52px;
        height: 54.458px;
        flex-shrink: 0;
        border-radius: 12px;
        border: 1px solid var(--e1e1e1, #E1E1E1);
        margin-left: 14px;
        margin-top: 20px;
        margin-right: 14px;
        object-fit: contain;
      }

      .Popup-top-right {
        margin-top: 20px;

        .Popup-top-right-name {
          color: var(--black, #1D1D1F);
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .Popup-top-right-two {
          width: 58px;
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .Popup-top-money {
      height: 42px;
      display: flex;
      align-items: center;
      margin-top: 40px;
      margin-left: 14px;
      margin-bottom: 5px;

      // border: 1px solid red;
      .Popup-top-money-tet {
        margin-top: 12px;
      }

      .Popup-top-money-inp {
        width: 100%;
        height: 42px;
        outline: none;
        border: none;
        margin-left: 10px;
        border-radius: 12px;
        background: var(--grey_bg, #F6F6F6);
        color: var(--black, #1D1D1F);
        font-family: 'MiSans-Demibold';
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
      }


      // .Popup-top-money-span {
      //     font-size: 10px;
      // }
    }

    .Popup-top-money-service {
      margin-left: 14px;
      margin-right: 14px;
      border-top: 1px solid #E1E1E1;
      display: flex;
      align-items: center;
      white-space: nowrap;
      padding-top: 10px;

      .Popup-top-money-service-span {
        color: var(--purple, #4D33EF);
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        white-space: nowrap;
        width: 200px;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &:last-child {
        border: none;
        padding-bottom: 15px;
        padding-top: 0;
      }
    }
  }



  .Popup-top-center {
    // border: 1px solid red;
    margin: 0 auto;

    .Popup-h4 {
      margin-top: 14px;
      margin-bottom: 8px;
      color: var(--333, #333);
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      margin-left: 10px;
      /* 16.8px */
    }

    .Popup-wallet-sd {
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 12px;
      background: var(--grey_bg, #F6F6F6);
      padding: 0 12px;

      .Popup-wallet-sd-left {
        display: flex;

        .Popup-wallet-sd-left-img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }

        .Popup-wallet-sd-left-text {
          color: var(--black, #1D1D1F);
          text-align: center;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .Popup-wallet-hf-right {
        width: 75px;
        // border: 1px solid red;
        display: flex;
        justify-content: space-between;
        color: var(--purple, #4D33EF);
        text-align: right;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 16.8px */
      }

      .Popup-wallet-sd-left-checkbox {
        position: relative;
      }

      .Checkbox:checked+label:after {
        content: "";
        position: absolute;
        left: 2px;
        top: 2px;
        width: 9px;
        height: 4px;
        border: 2px solid #424242;
        border-top-color: transparent;
        border-right-color: transparent;
        transform: rotate(-45deg);
        -ms-transform: rotate(-60deg);
        -moz-transform: rotate(-60deg);
        -webkit-transform: rotate(-60deg);
      }
    }

    .Popup-wallet-hf {
      height: 56px;
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 12px;
      background: var(--grey_bg, #F6F6F6);
      padding: 0 14px;

      .Popup-wallet-hf-left {
        display: flex;

        .Popup-wallet-hf-left-img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }

        .Popup-wallet-hf-left-text {
          color: var(--black, #1D1D1F);
          text-align: center;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .Popup-wallet-hf-right {
        width: 75px;
        // border: 1px solid red;
        display: flex;
        justify-content: space-between;
        color: var(--purple, #4D33EF);
        text-align: right;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 16.8px */
      }

    }
  }

  .Popup-top-foot {
    margin: 0 auto;

    .Popup-top-foot-h3 {
      margin-top: 14px;
      margin-bottom: 8px;
      color: var(--333, #333);
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      margin-left: 10px;
      /* 16.8px */
    }

    .Popup-top-foot-p {
      color: var(--tint-tertiary-light, #BFBFBF);
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.258px;
      margin-left: 10px;

    }
  }



  .Popup-top-btn-box {
    padding: 6px 0 0px;

    .Popup-top-btn {
      height: 61px;
      backdrop-filter: blur(2px);
      margin: 0 auto;
      border-radius: 18px;
      background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4D33EF;
      box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.60) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.60) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.30) inset, -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFF;
      text-align: center;
      font-family: 'MiSans-Demibold';
      font-size: 17px;
      font-style: normal;
      font-weight: 900;
      line-height: 32px;
      /* 188.235% */

      .Popup-top-btn {
        width: 348px;
        height: 61px;
        flex-shrink: 0;
        border-radius: 18px;
        background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4D33EF;
        box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.60) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.60) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.30) inset, -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
        text-align: center;
        line-height: 61px;
        color: #FFF;
        font-family: 'MiSans-Demibold';
        font-size: 17px;
        font-style: normal;
        font-weight: 900;
        margin-top: 26px;
        /* 188.235% */
      }
    }
  }

  .adm-popup-body {
    max-height: none;
  }

  .onChainInformation-record {
    width: 100%;

    .onChainInformation-record-top {
      // width: 347px;
      padding-left: 15px;
      padding-right: 15px;
      height: 30px;
      // border: 1px solid red;   
      margin: 0 auto;
      margin-top: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--black, #1D1D1F);
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      display: flex;
      align-items: center;
      /* 19.2px */

      h4 {
        font-weight: 700;
        font-size: 16px;
        color: var(--color);
        margin-bottom: 10px;
        margin: 0;
      }

      .onChainInformation-record-right {
        display: flex;
        align-items: center;
        // margin-left: 14px;
        width: 81px;
        height: 30px;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.06);
        color: var(--black, #1D1D1F);
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;

        /* 183.333% */
        .onChainInformation-record-right-text {
          margin-left: 14px;
        }
      }
    }

    .onChainInformation-record-over {

      // border: 1px solid red;
      .onChainInformation-record-box {
        padding-left: 20px;
        padding-right: 20px;
        height: 67px;
        // border: 1px solid red;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);

        .onChainInformation-record-box-left {
          color: var(--black, #1D1D1F);
          font-family: 'MiSans-Demibold';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;

          .onChainInformation-record-box-left-p {
            color: #999;
            font-family: PingFang SC;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            /* 14.4px */
          }
        }

        .onChainInformation-record-box-right {
          color: var(--success-success-4-hover, #56C08D);
          text-align: right;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          /* 16.8px */
        }

        .onChainInformation-record-box-right2 {
          color: var(--error-error-5-hover, #F6685D);
          text-align: right;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          /* 16.8px */
        }
      }
    }

  }
}