#subscribe {
  width: 100%;
  min-height: 100vh;

  .nftDetail-img {
    width: 100%;
    height: 382px;
    margin-top: 61px;
    object-fit: cover;
  }

  .nftDetail-box {}

  .nftDetail-select {
    background-color: #fff;

    &-box {
      display: flex;
      align-items: center;
      padding: 14px 10px 0;
      position: relative;

      &-item {
        color: #AAB8C2;
        padding-bottom: 10px;
        text-align: center;
        font-size: 18px;
      }

      .nftDetail-select-box-top {
        display: flex;
        // margin-left: 5px;

      }

      .nftDetail-select-box-item:nth-child(2) {
        margin-left: 32px;
      }

      .active {
        color: var(--color);
        position: relative;
        font-weight: bold;
      }

      .active::after {
        content: '';
        height: 3px;
        width: 36%;
        background: #4D33EF;
        position: absolute;
        bottom: 0;
        left: 18px;
      }

      .nftDetail-tutorial {
        // border: 1px solid red;
        width: 81px;
        height: 31px;
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.06);
        font-size: 12px;
        text-align: center;
        line-height: 31px;
        position: absolute;
        top: 16px;
        right: 14px;
      }

    }
  }

  .noneClass {
    display: none;
  }

  .nftDetail-cneter {
    height: 75px;
    border-radius: 12px;
    margin: 14px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: space-between;
    padding: 0 14px;

    .nftDetail-cneter-p {
      padding-top: 14px;
      color: #999;
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: 600;
    }

    .nftDetail-cneter-num {
      display: flex;
      padding-top: 14px;
      font-size: 16px;
      justify-content: center;
    }
  }

  .nftDetail-steps {
    position: relative;


    .adm-step-icon-container {
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      border-radius: 6px;
      background: #F5F5F5;
      color: #000000;
      font-size: 12px;
      margin-top: 15px;
      position: relative;
    }

    .adm-step .adm-step-indicator::after {
      background-color: #ccc;
      margin-top: 22px;
    }

    .adm-step-title {
      margin-left: 12px;
      color: var(--black, #1D1D1F);
      font-family: 'MiSans-Demibold';
      font-size: 16px;
      font-weight: 600;
      margin-top: 15px;
    }

    .adm-step-description {
      margin-left: 12px;
    }

    .nftDetail-steps-text {
      color: var(--black, #1D1D1F);
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: 500;
      width: 311px;
      height: 19px;
      // border: 1px solid red;
      position: absolute;
      top: 154px;
      left: 50px;
    }

    .nftDetail-steps-text2 {
      color: var(--black, #1D1D1F);
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: 500;
      width: 311px;
      height: 19px;
      position: absolute;
      top: 234px;
      left: 50px;
    }

    .nftDetail-steps-text3 {
      color: var(--black, #1D1D1F);
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: 500;
      width: 311px;
      height: 19px;
      position: absolute;
      top: 314px;
      left: 50px;
    }
  }

  .nftDetail-foot {
    padding: 14px;
    margin-top: 60px;
    border-radius: 12px;
    margin-left: 48px;
    margin-right: 14px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: space-between;

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      &-text1 {
        color: #999;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 14.4px */
      }

      &-text2 {
        color: #1D1D1F;
        font-family: 'MiSans-Demibold';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        margin-top: 14px;
        /* 19.2px */
      }
    }
  }

  .nftDetail-btn-box {
    padding: 0 14px;
    height: 120px;
    display: flex;
    justify-content: center;
    background-color: #fff;
    position: relative;
    z-index: 9999;
  }

  .nftDetail-btn {
    height: 54px;
    width: 100%;
    background-color: #000000;
    color: #F5F8FA;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    border-radius: 12px;
    background: #000;
    text-align: center;
    line-height: 54px;
    // margin-left: 14px;
    margin-top: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nftDetail-btn2 {
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    background-color: #000000;
    color: #F5F8FA;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    border-radius: 12px;
    line-height: 54px;
    // margin-left: 14px;
    margin-top: 24px;

    .nftDetail-btn2-text {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .nftDetail-btn-img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nftDetail-btn3 {
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    background-color: #000000;
    color: #F5F8FA;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    border-radius: 12px;
    background: #000;
    line-height: 54px;
    // margin-left: 14px;
    margin-top: 24px;

    .nftDetail-btn3-span {
      width: 70px;
      height: 19px;
      border-right: 2px solid #fff;
      margin-right: 10px;
      padding-right: 10px;
    }

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nftDetail-btn4 {
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    background-color: #F6F6F6;
    color: #1D1D1F;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    border-radius: 12px;
    line-height: 54px;
    // margin-left: 14px;
    margin-top: 24px;

    .nftDetail-btn-emoj {
      margin-right: 5px;
    }

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nftDetail-ProductDetails-top {
    height: 114px;
    border-top: 1px solid #AAB8C2;
    border-bottom: 1px solid #AAB8C2;
    margin: 14px;
    box-sizing: border-box;

    .nftDetail-ProductDetails-top-wyper {
      display: flex;
      padding-top: 16px;
      // margin-left: 10px;
    }

    .nftDetail-ProductDetails-top-h1 {
      color: #1D1D1F;
      font-family: PingFang SC;
      margin-top: 8px;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
    }

    .nftDetail-ProductDetails-top-box {
      display: flex;
      padding-bottom: 14px;

      .nftDetail-ProductDetails-top-spbox {
        display: flex;
        text-align: center;
        line-height: 20px;
        margin-top: 5px;
      }

      .nftDetail-ProductDetails-top-sp1 {
        width: 36px;
        height: 20px;
        font-size: 10px;
        border-radius: 2px 0px 0px 2px;
        background: var(--black, #1D1D1F);
        color: #fff;
        // margin-left: 10px;
      }

      .nftDetail-ProductDetails-top-sp2 {
        width: 44px;
        height: 20px;
        margin-right: 5px;
        border-radius: 0px 2px 2px 0px;
        background: var(--grey_bg, #F6F6F6);
        font-size: 10px;
      }
    }
  }

  .nftDetail-ProductDetails-center-text {
    width: 344px;
    height: 130px;
    margin: 0 auto;
    // border: 1px solid red;
    margin-top: 16px;
    padding-left: 10px;

    .nftDetail-ProductDetails-center-text-h3 {
      color: #1D1D1F;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }

    .nftDetail-ProductDetails-center-text-p {
      color: #666;
      text-align: justify;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-top: 10px;
    }
  }

  .nftDetail-ProductDetails-center-text2 {
    width: 344px;
    height: 130px;
    margin: 0 auto;
    padding-left: 10px;

    .nftDetail-ProductDetails-center-text-h32 {
      color: #1D1D1F;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }

    .nftDetail-ProductDetails-center-text-p2 {
      color: #666;
      text-align: justify;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-top: 10px;
    }
  }

  .nftDetail-ProductDetails-TransactionInstructions {
    padding-left: 10px;
    width: 344px;
    margin: 0 auto;

    // border: 1px solid red;
    .nftDetail-ProductDetails-TransactionInstructions-p {
      margin-top: 10px;
      color: #666;
      text-align: justify;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .nftDetail-notification {
    .nftDetail-notification-text {
      color: var(--black, #1D1D1F);
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      display: flex;
      align-items: center;
      /* 157.143% */
    }


    .nftDetail-notification-h3 {
      font-family: PingFang SC;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
    }

    .nftDetail-notification-p {
      color: #999;
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }

    .nftDetail-notification-inpbox {
      height: 52px;
      border-radius: 6px;
      margin-top: 14px;
      margin-bottom: 14px;
      background: var(--grey_bg, #F6F6F6);
      display: flex;

      .nftDetail-notification-inpbox-inp {
        border: none;
        appearance: none;
        outline: none;
        border-radius: 6px;
        background: var(--grey_bg, #F6F6F6);
        padding-left: 10px;
        width: 100%;
      }

      .nftDetail-notification-big {
        display: flex;
        width: 49px;
        height: 28px;
        padding: 0px 4px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid #000;
        margin-top: 10px;
        margin-right: 14px;
      }
    }

    .nftDetail-notification-che {
      // width: 347px;
      height: 22px;
      font-size: 12px;
      display: flex;
      color: #4D33EF;
      display: flex;

      .RealName-ctx2-p-span {
        color: #999;
      }

      .RealName-ctx2-p-a {
        color: #4D33EF;
      }

      .nftDetail-notification-checkbox {
        margin-right: 4px;
      }

      .nftDetail-notification-che-and {
        color: #999;
      }
    }

    .nftDetail-notification-btn {
      height: 52px;
      flex-shrink: 0;
      margin-top: 14px;
      color: #fff;
      text-align: center;
      line-height: 52px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      border-radius: 12px;
      background: #1D1D1F;
    }
  }

  .subscribe-codeVisible {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    img {
      margin-top: -12px;
    }

    &-text {
      color: var(--color);
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      margin-top: 4px;
    }

    &-text2 {
      color: #999;
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      margin-top: 9px;
      /* 14.4px */
    }

    &-content {
      max-height: 300px;
      overflow: auto;
      display: grid;
      grid-template-columns: 48% 48%;
      width: 100%;
      justify-content: space-between;
      margin-top: 10px;
      padding-bottom: 20px;

      &-item {
        display: flex;
        height: 45px;
        justify-content: space-around;
        align-items: center;
        border-radius: 4px;
        background: #fff;
        margin-top: 6px;

        &-text1 {
          color: #333;
          font-family: PingFang SC;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          /* 12px */
        }

        &-text2 {
          color: #333;
          font-family: 'MiSans-Demibold';
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          width: 78px;
          /* 19.2px */
        }
      }

      .no {

        span {
          color: #999;
        }
      }

      .yes {

        span {
          color: #4475F2;
        }
      }
    }
  }

  .adm-popup-body-position-bottom {
    bottom: 110px;
  }

  .adm-popup-body {
    background: rgba(255, 255, 255, 0.88);
  }

  .main {

    .CommodityDetail-img {
      margin-top: 20px;
      width: 100%;
      height: 382px;
    }

    .box1 {
      display: flex;
      align-items: center;
      padding: 0 15px;
      margin-top: 14px;
      border-top: 1px solid #AAB8C2;
      padding-top: 17px;

      &-img {
        width: 17px;
        height: 17px;
        margin-right: 5px;
      }

      &-text {
        font-weight: 400;
        font-size: 12px;
        color: var(--color);
      }
    }

    .box2 {
      display: flex;
      align-items: center;
      margin-top: 8px;
      padding: 0 15px;

      &-text {
        font-weight: 700;
        font-size: 28px;
        color: var(--color);
        margin-right: 8px;
      }
    }

    .box3 {
      display: flex;
      align-items: center;
      padding: 0 15px;
      margin-top: 8px;

      &-item {
        display: flex;
        align-items: center;
        margin-right: 8px;

        &-text1 {
          background: #000;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px 8px;
          font-weight: 700;
          font-size: 10px;
          color: #fff;
        }

        &-text2 {
          background: #F6F6F6;
          height: 100%;
          border-radius: 0px 4px 4px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px 8px;
          font-size: 10px;
          font-family: ''MiSans-Demibold'-Demibold';
          color: #000;
        }
      }
    }

    .box4 {
      display: flex;
      flex-direction: column;
      margin: 18px 0 0;
      border-top: 1px solid #AAB8C2;
      border-bottom: 1px solid #AAB8C2;
      padding: 14px 15px;

      &-title {
        font-weight: 700;
        font-size: 16px;
        color: var(--color);
        margin-bottom: 10px;
      }

      &-data {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        &-text1 {
          font-weight: 400;
          font-size: 14px;
          color: var(--color);
        }

        &-text2 {
          font-weight: 400;
          font-size: 12px;
          color: var(--color);
          width: 72%;
          overflow: hidden;
          margin-right: 5px;
          text-overflow: ellipsis;
        }

        &-img {
          width: 62.18px;

          img {
            border-radius: 0;
          }
        }
      }
    }

    .box7 {
      display: flex;
      flex-direction: column;
      padding: 10px 15px;

      .desc {
        display: flex;
        flex-direction: column;
        color: var(--gray);
        text-align: justify;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 21px */
        margin-top: 10px;

        img {
          width: 100%;
        }
      }
    }
  }

}