#Card {
  padding-top: 30px;
  padding-bottom: 160px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .Card-header-right {
      display: flex;
      align-items: center;
      position: relative;

      i {
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #D54941;
        right: 0;
        top: 0;
      }

      &-box {
        display: flex;
        padding: 6px 10px;
        align-items: center;
        gap: 4px;
        border-radius: 444px;
        border: 1px solid rgba(255, 255, 255, 0.12);
        background: #1C1E25;
        margin-left: 12px;
        width: 51px;

        span {
          color: #FFF;
          text-align: center;
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-left: 4px;
        }
      }
    }
  }

  .banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    flex-direction: column;
    height: 351px;
    background-image: url('../../../assets/imgs/cardbj.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 120%;

    img {}

    &-btn {
      height: 61px;
      width: 100%;
      border-radius: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 18px;
      background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4D33EF;
      box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.60) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.60) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.30) inset, -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
      font-size: 17px;
      font-weight: 900;
      color: #fff;
      position: relative;

      &-img {
        position: absolute;
        right: -7px;
        top: -57px;
      }
    }
  }

  .card-box {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 222px;
      margin-left: -184px;
      margin-top: -30px;
    }


  }
//首页卡片浮动动画
  @keyframes float {
    0% {
      transform: translate3d(0, 0, 0);
    }

    50% {
      transform: translate3d(0, 26px, 0);
    }

    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  .card-box img {
    animation-name: float;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 2.3s;
  }

  .card-box img:nth-child(1) {
    animation-delay: 0s;
    margin-left: 0;
    z-index: 6;
  }

  .card-box img:nth-child(2) {
    animation-delay: 0.2s;
    z-index: 5;
  }

  .card-box img:nth-child(3) {
    animation-delay: 0.4s;
    z-index: 4;
  }

  .card-box img:nth-child(4) {
    animation-delay: 0.6s;
    z-index: 3;
  }

  .box1 {
    margin-top: 20px;

    &-title {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      color: #fff;
    }

    &-content {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;

      &-left {
        flex: .48;
        height: 160px;
        padding: 12px;
        box-sizing: border-box;
        border-radius: 10px;
        background: linear-gradient(180deg, #201924 0%, #6B242C 100%);
        position: relative;
        overflow: hidden;

        &-text1 {
          color: rgba(255, 255, 255, 0.85);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
        }

        &-text2 {
          color: rgba(255, 255, 255, 0.66);
          font-family: PingFang SC;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 5px;
        }

        &-text3 {
          width: 99px;
          height: 27px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #67242D;
          font-size: 11px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          border-radius: 66px;
          background: rgba(255, 255, 255, 0.86);
          margin-top: 60px;
        }

        img {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }

      &-right {
        flex: .48;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-item {
          border-radius: 10px;
          height: 74px;
          display: flex;
          flex-direction: column;
          padding: 12px;
          box-sizing: border-box;
          position: relative;
          overflow: hidden;

          &-text1 {
            color: rgba(255, 255, 255, 0.85);
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          &-text2 {
            color: rgba(255, 255, 255, 0.66);
            font-family: PingFang SC;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 5px;
          }

          &:first-child {
            background: linear-gradient(180deg, #212736 0%, #2D3876 100%);
          }

          &:last-child {
            background: linear-gradient(180deg, #2B1C18 0%, #704235 100%);
          }

          img {
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
      }
    }
  }

  .box2 {
    margin-top: 20px;

    &-title {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      color: #fff;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 14px;

      &-item {
        padding: 12px;
        border-radius: 12px;
        background: #1B1B1B;
        box-sizing: border-box;
        width: 48%;
        margin-bottom: 14px;

        &-img {
          width: 100%;

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
            align-self: stretch;
            border-radius: 12px;
          }

          margin-bottom: 7px;
        }

        &-text1 {
          color: var(--White, #F5F8FA);
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          width: 121px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &-text2 {
          display: flex;
          justify-content: space-between;
          margin-top: 12px;

          &-left {
            color: var(--White, #F5F8FA);
            font-family: 'MiSans-Demibold';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;

            span {
              font-size: 10px;
            }
          }

          &-right {
            color: var(--txt, #AAB8C2);
            text-align: center;
            font-family: PingFang SC;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 12px */
          }
        }
      }
    }
  }
}