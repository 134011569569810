#TradeCountdown {
  width: 100%;
  height: 100vh;
  background-image: url('../../../assets/imgs/GameIntroduction-img.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;

  .TradeCountdown-box {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .TradeCountdown-box1 {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .TradeCountdown-box2 {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .none {
    display: none;
  }

  .TradeCountdown-center-box {
    height: 480px;
    position: relative;
    margin: 0 auto;
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;

    .TradeCountdown-center {
      height: 117px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .TradeCountdown-center-img {
        width: 68px;
        height: 68px;
      }

      .TradeCountdown-center-h3 {
        color: #262A2F;
        text-align: center;
        font-family: PingFang SC;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 34.604px;
        text-transform: capitalize;
        margin-top: 14px;
      }
    }
  }

  .TradeCountdown-text {
    margin: 0 20px;
    margin-top: 14px;
    color: #999;
    text-align: center;
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 155%;
  }

  .TradeCountdown-btn {
    height: 52px;
    border-radius: 7px;
    background: var(--black, #1D1D1F);
    color: #fff;
    text-align: center;
    line-height: 52px;
    margin: 0 20px;
    margin-top: 19px;
    color: #FFF;
    font-family: PingFang SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}