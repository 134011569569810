#FreeDeal {
  height: 100vh;
  padding-top: 60px;
  box-sizing: border-box;
  padding-bottom: 100px;
  overflow: auto;
  position: relative;

  .noneClass {
    display: none;
  }

  .Classblock {
    display: block;
  }

  .FreeDeal-bj {
    height: 382px;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .FreeDeal-title {
    padding-top: 15px;
    margin-left: 18px;
    margin-right: 18px;
    display: flex;
    align-items: center;

    &-right {
      width: 170px;
      height: 39px;
      // border: 1px solid blue;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 0 auto;
      border-radius: 12px;
      background: #F3F3F3;
      font-size: 14px;

      &-sell {
        padding: 0 10px;
        height: 32px;
        border-radius: 10px;
        overflow: hidden;
        color: #1D1D1F;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        width: 57px;
      }

      .switch {
        background: #fff;
      }
    }
  }

  .RankingList-top {
    width: 100%;
    height: 146px;
    margin-top: 14px;
    color: #fff;
    display: flex;
    overflow-x: auto;
    padding-right: 20px;
    box-sizing: border-box;

    .RankingList-bronze {
      // width: 293px;
      // height: 146px;
      border-radius: 12px;
      background: #191A1E;
      white-space: nowrap;
      margin-left: 20px;

      .RankingList-bronze-top {
        width: 252px;
        height: 34px;
        margin-top: 10px;
        padding-top: 12px;
        margin-left: 12px;
        display: flex;
        // border: 1px solid red;
        justify-content: space-between;

        .RankingList-bronze-top-left {
          display: flex;
          justify-content: space-between;
          color: #FFF;
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;

          /* 183.333% */
          .RankingList-bronze-top-left-img {
            width: 16px;
            height: 16px;
            margin-top: 2px;
            margin-right: 4px;
            border-radius: 50%;
          }
        }

        .RankingList-bronze-top-right {
          color: #FFF;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          font-family: 'MiSans-Demibold';
          font-size: 14px;
          font-style: normal;
          font-weight: 900;
          line-height: 22px;
          /* 157.143% */
          display: flex;
          justify-content: space-between;

          .RankingList-bronze-top-right-img {
            width: 18px;
            height: 18px;
            margin-top: 2px;
            padding-right: 4px;
          }

          .RankingList-bronze-top-right-num {
            padding-right: 12px;
          }
        }
      }

    }

    .RankingList-bronze-accumulate {
      font-size: 12px;
      margin-top: 30px;
      margin-left: 12px;
      margin-bottom: 12px;
      display: flex;
      align-items: baseline;

      .RankingList-bronze-accumulate-text2 {
        font-size: 14px;
      }
    }

    .RankingList-Progressbar {
      padding: 0 16px 0 12px;
    }


    .RankingList-bronze2 {
      // width: 293px;
      // height: 146px;
      border-radius: 12px;
      background: #4D33EF;
      white-space: nowrap;
      margin-left: 20px;

      .RankingList-bronze-top {
        width: 252px;
        height: 34px;
        margin-top: 10px;
        padding-top: 12px;
        margin-left: 12px;
        display: flex;
        // border: 1px solid red;
        justify-content: space-between;

        .RankingList-bronze-top-left {
          display: flex;
          justify-content: space-between;
          color: #FFF;
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;

          /* 183.333% */
          .RankingList-bronze-top-left-img {
            width: 16px;
            height: 16px;
            margin-top: 2px;
            margin-right: 4px;
            border-radius: 50%;
          }
        }

        .RankingList-bronze-top-right {
          color: #FFF;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          font-family: 'MiSans-Demibold';
          font-size: 14px;
          font-style: normal;
          font-weight: 900;
          line-height: 22px;
          /* 157.143% */
          display: flex;
          justify-content: space-between;

          .RankingList-bronze-top-right-img {
            width: 18px;
            height: 18px;
            margin-top: 2px;
            padding-right: 4px;
          }

          .RankingList-bronze-top-right-num {
            padding-right: 16px;
          }
        }
      }

    }
  }

  .RankingList-center {
    // border: 1px solid red;
    width: 100%;

    .RankingList-center-title {
      height: 58px;
      // border: 1px solid red;
      margin: 0 auto;
      margin-top: 20px;
      padding-left: 24px;

      .RankingList-center-title-h3 {
        color: #333;
        font-family: PingFang SC;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 14px;
        display: flex;
        align-items: center;

        img {
          margin-left: 10px;
        }

        /* 24px */
      }

      .RankingList-center-title-div {
        display: flex;

        .RankingList-center-title-box {
          height: 20px;
          display: flex;
          align-items: center;
          margin-right: 8px;

          .RankingList-center-title-box-left {
            width: 36px;
            border-radius: 4px 0px 0px 4px;
            background: var(--black, #1D1D1F);
            color: var(--fff, #FFF);
            text-align: center;
            font-family: PingFang SC;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
          }

          .RankingList-center-title-box-right {
            border-radius: 0px 4px 4px 0px;
            background: var(--grey_bg, #F6F6F6);
            color: var(--black, #1D1D1F);
            text-align: center;
            font-family: PingFang SC;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            padding: 0 8px;
            /* 160% */
          }
        }
      }

    }

    .RankingList-center-sort-box {
      padding-left: 24px;
      padding-right: 24px;

      // border: 1px solid blue;
      .RankingList-center-sort {
        height: 52px;
        border-top: 1px solid var(--e1e1e1, #E1E1E1);
        border-bottom: 1px solid var(--e1e1e1, #E1E1E1);
        margin: 0 auto;
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;



        .RankingList-center-sort-left {
          padding-left: 2px;
          width: 160px;
          height: 35px;
          display: flex;
          align-items: center;

          .RankingList-center-sort-left-price {
            color: #999;
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            /* 19.2px */
            margin-right: 15px;
          }

          .RankingList-center-sort-left-num {
            padding-right: 10px;
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 19.2px */
          }

          .typeText {
            color: #000;
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
          }

          .Discoloration {
            color: #000;
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
          }

          .text {
            color: #999;
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
          }
        }

        .RankingList-center-sort-right {
          // border: 1px solid blue;
          // margin-left: 10px;
          width: 140px;
          height: 31px;
          border-radius: 8px;
          // background: #F3F3F3;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #666;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: PingFang SC;
          font-size: 11px;
          font-style: normal;
          font-weight: 600;

          .RankingList-center-sort-right-box1 {
            width: 50%;
            height: 26px;
            line-height: 26px;
            margin-left: 2px;
          }

          .RankingList-center-sort-right-box2 {
            margin-right: 2px;
          }
        }

        .active2 {
          width: 66px;
          height: 26px;
          line-height: 26px;
          border-radius: 8px;
          background: var(--fff, #FFF);
          // background-color: #f3f3f3;
          color: var(--black, #1D1D1F);
          text-align: center;
          font-family: PingFang SC;
          font-size: 11px;
          font-style: normal;
          font-weight: 600;
        }

        .none {
          border-radius: none;
          background: none;
          color: #666;
        }
      }

    }




    .RankingList-center-foot {
      padding-left: 24px;
      padding-right: 24px;

      .RankingList-center-foot-box {
        height: 53px;
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        border-bottom: 0.5px solid var(--e1e1e1, #E1E1E1);

        .RankingList-center-foot-left {
          display: flex;

          //组件checkbox的style名
          .adm-checkbox .adm-checkbox-icon {
            margin-bottom: 14px;
            display: block;
          }
        }


        .RankingList-center-foot-left-top {
          line-height: 19px;
          color: #333;
          font-family: 'MiSans-Demibold';
          font-size: 16px;
          font-style: normal;
          font-weight: 600;

          .RankingList-center-foot-left-top-box {
            width: 100%;
            // border: 1px solid blue;
            display: flex;

            .RankingList-center-foot-left-top-div {
              padding: 0 4px;
              height: 16px;
              margin-left: 4px;
              font-size: 8px;
              // text-align: center;
              color: #4D33EF;
              text-align: center;
              font-family: PingFang SC;
              font-size: 8px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px;
              border-radius: 2px;
              background: rgba(72, 0, 224, 0.08);
            }

            .lock {
              background: linear-gradient(180deg, rgba(188, 56, 44, 0.16) 0%, rgba(255, 92, 0, 0.16) 100%);
              color: #BC382C;
            }

            .self_lock {
              color: #11D000;
              background: #11D00023;
            }

            img {
              margin-left: 5px;
            }

          }
        }

        .RankingList-center-foot-left-p {
          color: #000;
          font-family: 'MiSans-Demibold';
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          margin-top: 6px;

          /* 14.4px */
          .RankingList-center-foot-box-span {
            color: #999;
          }
        }

        .RankingList-center-foot-ritgh {
          display: flex;

          .RankingList-center-foot-ritgh-div {
            color: #333;
            font-family: 'MiSans-Demibold';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 50px;
            margin-right: 6px;

            .RankingList-center-foot-ritgh-span {
              color: #333;
              text-align: right;
              font-family: 'MiSans-Demibold';
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 50px;
              /* 12px */
            }
          }

          .RankingList-center-sort-right-img {
            width: 16px;
            height: 16px;
            margin-top: 16px;
          }
        }
      }

    }

  }

  .noClass {
    display: none;
  }

  .background {
    background-color: #f3f3f3;
  }

  .RankingList-center-foot2 {
    // width: 339px;
    padding-left: 24px;
    height: 270px;
    margin: 0 auto;
    overflow: auto;
    // border: 1px solid red;

    .foot2-box {
      height: 270px;

      .RankingList-center-foot2-box {
        // width: 339px;
        padding-right: 24px;
        height: 52px;
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // border: 1px solid blue;
        border-bottom: 0.5px solid var(--e1e1e1, #E1E1E1);

        .RankingList-center-foot2-box-left {
          width: 116px;
          height: 20px;
          // border: 1px solid red;
          display: flex;
          justify-content: space-between;

          .RankingList-center-foot2-box-left-h3 {
            color: #333;
            font-family: 'MiSans-Demibold';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 19.2px */
          }
        }

        .RankingList-center-foot2-ritgh {
          display: flex;
          justify-content: space-between;

          .RankingList-center-foot2-ritgh-div {
            color: #333;
            font-family: 'MiSans-Demibold';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;

            .RankingList-center-foot2-ritgh-span {
              color: #333;
              text-align: right;
              font-family: 'MiSans-Demibold';
              font-size: 10px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%;
              /* 12px */
            }
          }
        }
      }
    }

    .RankingList-foot2-box {
      position: fixed;
      width: 100%;
      height: 117px;
      // border: 1px solid red;
      background: rgba(255, 255, 255, 0.88);
      backdrop-filter: blur(2px);

      .RankingList-foot2-box-btn {
        width: 339px;
        height: 52px;
        border-radius: 12px;
        background: #000;
        color: #fff;
        display: flex;
        align-items: center;
        margin-top: 26px;
        color: #F5F8FA;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        // border: 1px solid red;


        /* 19.2px */
        .RankingList-foot2-box-btn-img {
          margin-left: 36%;
          margin-right: 8px;
        }
      }
    }
  }



  .RankingList-PrizePool {
    padding: 0 15px;
    position: relative;


    .RankingList-PrizePool-top {
      height: 68px;
      line-height: 68px;
      color: #333;
      font-family: PingFang SC;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      display: flex;
      justify-content: space-between;

      .RankingList-PrizePool-top-p {
        color: #999;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;

        .RankingList-PrizePool-top-span {
          color: var(--black, #1D1D1F);
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
        }
      }
    }

    .RankingList-PrizePool-center {

      .RankingList-PrizePool-box {
        height: 69px;
        width: 100%;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .RankingList-PrizePool-box-left {
          display: flex;
          align-items: center;

          >span {
            width: 21px;
            height: 21px;
            background: #ebebeb;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 11px;
            font-weight: 900;
            color: #999;
          }
        }

        .RankingList-PrizePool-box-img {
          width: 22px;
          height: 22px;
        }

        .RankingList-PrizePool-box-number {
          width: 23px;
          height: 17px;
          color: #999;
          text-align: center;
          font-family: 'MiSans-Demibold';
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
        }

        .RankingList-PrizePool-box-center {
          color: #666;
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          margin-left: 8px;
          /* 14.4px */

          .RankingList-PrizePool-box-phone {
            color: var(--black, #1D1D1F);
            font-family: 'MiSans-Demibold';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
          }

          .RankingList-PrizePool-box-phone2 {
            color: var(--purple, #4D33EF);
            font-family: 'MiSans-Demibold';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
          }
        }

        .RankingList-PrizePool-box-text {
          color: #333;
          text-align: right;
          font-family: 'MiSans-Demibold';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;

          .RankingList-PrizePool-box-num {
            color: #333;
            font-family: 'MiSans-Demibold';
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
          }
        }
      }
    }

  }

  .Popup-top {

    .Popup-top-title {
      display: flex;
      align-items: center;


      .Popup-top-img {
        width: 52px;
        height: 54.458px;
        flex-shrink: 0;
        border-radius: 12px;
        border: 1px solid var(--e1e1e1, #E1E1E1);
        margin-right: 8px;
      }

      .Popup-top-right {

        .Popup-top-right-name {
          color: var(--black, #1D1D1F);
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .Popup-top-right-two {
          width: 58px;
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .Popup-top-money {
      height: 42px;
      display: flex;
      align-items: center;
      margin-top: 40px;
      margin-left: 14px;
      margin-bottom: 5px;

      // border: 1px solid red;
      .Popup-top-money-tet {
        margin-top: 12px;
      }

      .Popup-top-money-inp {
        width: 100%;
        height: 42px;
        outline: none;
        border: none;
        margin-left: 10px;
        border-radius: 12px;
        background: var(--grey_bg, #F6F6F6);
        color: var(--black, #1D1D1F);
        font-family: 'MiSans-Demibold';
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
      }


      // .Popup-top-money-span {
      //     font-size: 10px;
      // }
    }

    .Popup-top-money-service {
      height: 56px;
      // border: 1px solid blue;
      margin-left: 14px;
      margin-right: 14px;
      line-height: 56px;
      border-top: 1px solid #E1E1E1;
      display: flex;
      align-items: center;

      .Popup-top-money-service-span {
        color: var(--purple, #4D33EF);
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        white-space: nowrap;
        width: 200px;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }



  .Popup-top-center {
    // border: 1px solid red;
    margin: 0 auto;

    .Popup-h4 {
      margin-top: 19px;
      margin-bottom: 8px;
      color: var(--333, #333);
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      margin-left: 10px;
      /* 16.8px */
    }

    .Popup-wallet-sd {
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 12px;
      background: var(--grey_bg, #F6F6F6);
      padding: 0 12px;

      .Popup-wallet-sd-left {
        display: flex;

        .Popup-wallet-sd-left-img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }

        .Popup-wallet-sd-left-text {
          color: var(--black, #1D1D1F);
          text-align: center;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .Popup-wallet-hf-right {
        width: 75px;
        // border: 1px solid red;
        display: flex;
        justify-content: space-between;
        color: var(--purple, #4D33EF);
        text-align: right;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 16.8px */
      }

      .Popup-wallet-sd-left-checkbox {
        position: relative;
      }

      .Checkbox:checked+label:after {
        content: "";
        position: absolute;
        left: 2px;
        top: 2px;
        width: 9px;
        height: 4px;
        border: 2px solid #424242;
        border-top-color: transparent;
        border-right-color: transparent;
        transform: rotate(-45deg);
        -ms-transform: rotate(-60deg);
        -moz-transform: rotate(-60deg);
        -webkit-transform: rotate(-60deg);
      }
    }

    .Popup-wallet-hf {
      height: 56px;
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 12px;
      background: var(--grey_bg, #F6F6F6);
      padding: 0 14px;

      .Popup-wallet-hf-left {
        display: flex;

        .Popup-wallet-hf-left-img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }

        .Popup-wallet-hf-left-text {
          color: var(--black, #1D1D1F);
          text-align: center;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .Popup-wallet-hf-right {
        width: 75px;
        // border: 1px solid red;
        display: flex;
        justify-content: space-between;
        color: var(--purple, #4D33EF);
        text-align: right;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 16.8px */
      }

    }
  }

  .Popup-top-foot {
    height: 45px;
    margin: 0 auto;

    .Popup-top-foot-h3 {
      margin-top: 14px;
      margin-bottom: 8px;
      color: var(--333, #333);
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      margin-left: 10px;
      /* 16.8px */
    }

    .Popup-top-foot-p {
      color: var(--tint-tertiary-light, #BFBFBF);
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.258px;
      margin-left: 10px;

    }
  }



  .Popup-top-btn-box {
    padding: 26px 0 0px;
    display: flex;
    align-items: end;
    justify-content: space-between;

    &-left {
      display: flex;
      flex-direction: column;

      &-text1 {
        color: var(--gray);
        font-family: 'MiSans-Demibold';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 14.4px */
      }

      &-text2 {
        color: var(--color);
        font-family: 'MiSans-Demibold';
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
      }
    }

    .Popup-top-btn {
      width: 150px;
      height: 61px;
      backdrop-filter: blur(2px);
      border-radius: 18px;
      background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4D33EF;
      box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.60) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.60) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.30) inset, -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFF;
      text-align: center;
      font-family: 'MiSans-Demibold';
      font-size: 17px;
      font-style: normal;
      font-weight: 900;
      line-height: 32px;
      /* 188.235% */

      .Popup-top-btn {
        width: 348px;
        height: 61px;
        flex-shrink: 0;
        border-radius: 18px;
        background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4D33EF;
        box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.60) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.60) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.30) inset, -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
        text-align: center;
        line-height: 61px;
        color: #FFF;
        font-family: 'MiSans-Demibold';
        font-size: 17px;
        font-style: normal;
        font-weight: 900;
        margin-top: 26px;
        /* 188.235% */
      }
    }
  }

  .Jackpot-footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 0.88);
    backdrop-filter: blur(2px);
    padding: 26px 18px 40px;
    box-sizing: border-box;

    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      color: #F5F8FA;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      border-radius: 12px;
      background: #000;
      height: 53px;
      width: 100%;
    }

    &-left {
      display: flex;
      width: 96px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      align-self: stretch;
      border-radius: 12px;
      border: 1px solid #000;
      color: #000;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      height: 51px;
      flex: 0.3;
      background-color: #fff;
      /* 19.2px */
    }

    &-right {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      align-self: stretch;
      color: #F5F8FA;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      border-radius: 12px;
      background: #000;
      height: 53px;
      flex: 0.65;
      /* 19.2px */
    }
  }
}