#inviteCode {
    padding: 0 18px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100vh;


    .text1 {
        font-weight: 700;
        font-size: 22px;
        color: var(--color);
        margin-top: 85px;
    }

    .code-animate {
        width: 620px;
        position: absolute;
        top: 120px;
    }

    .section {
        background: rgba(68, 117, 242, 0.1);
        border-radius: 8px;
        padding: 24px;
        font-weight: 400;
        font-size: 24px;
        color: #4475F2;
        display: flex;
        flex-direction: column;
        line-height: 44px;
        margin-top: 24px;
    }

    .codeInput {
        margin-top: 20px;
        height: 56px;
        width: 100%;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        padding: 0 14px;
        box-sizing: border-box;
        font-size: 15px;
        background: #F6F6F6;
        border: 2px solid rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(4px);
        color: var(--color);
        font-family: ''MiSans-Demibold'-Demibold';
        transition: all 0.4s ease-in-out;
    }

    .codeInput::placeholder {
        color: var(--gray);
    }

    .codeInput:focus {
        background-clip: padding-box,
            border-box;
        background-origin: padding-box,
            border-box;
        background-image: linear-gradient(to right, var(--theme-color), var(--theme-color)),
            linear-gradient(90deg, rgba(255, 99, 99, 0.8), var(--theme-color), rgba(155, 77, 255, 0.8));
        box-shadow: 0px 3px 22px 0px rgba(69, 80, 219, 0.5);
        transition: all 0.4s ease;
        outline: none;
    }

    .codeInput:hover {
        background-clip: padding-box,
            border-box;
        background-origin: padding-box,
            border-box;
        background-image: linear-gradient(to right, var(--theme-color), var(--theme-color)),
            linear-gradient(90deg, rgba(255, 99, 99, 0.8), var(--theme-color), rgba(155, 77, 255, 0.8));
        box-shadow: 0px 3px 22px 0px rgba(69, 80, 219, 0.5);
        transition: all 0.4s ease;
        outline: none;
    }

    .btn {
        margin-top: 20px;
        box-sizing: border-box;
        color: #AEB9CA;
        font-weight: bold;
        height: 56px;
        width: 100%;
        background: #1D1D1F;
        /* Note: backdrop-filter has minimal browser support */
        box-shadow: 0px 0px 32px 0px rgba(102, 94, 177, 0.37) inset;
        border-radius: 10px;
        border: 2px solid rgba(255, 255, 255, 0.14);
        font-size: 15px;
        align-items: center;
        display: flex;
        justify-content: center;
        // justify-content: center;
        padding: 12px 10px;
        transition: all 0.5s ease;
        // margin-top: 18px;
        cursor: pointer;
    }

    .btn-skip {
        font-size: 15px;
        color: var(--color);
        display: flex;
        align-items: center;
        gap: 8px;
        margin: 0 auto;
        margin-top: 14px;
        font-weight: bold;
        border-bottom: 1px solid #AEB9CA85;
        padding: 14px 4px;
        width: fit-content;
    }
}