#PriceSold {
  width: 100%;
  min-height: 100vh;
  background: #F3F3F3;
  padding-top: 62px;

  .PriceSold-zw {
    color: #F3F3F3;
  }

  .PriceSold-title {
    // border: 1px solid red;
    margin: 14px 14px 14px 14px;
    border-radius: 12px;
    background: var(--fff, #FFF);

    .PriceSold-title-box {
      padding: 22px 14px 22px 14px;

      .PriceSold-title-box-top {
        // border: 1px solid red;
        padding-bottom: 14px;
        border-bottom: 1px solid #E1E1E1;
        display: flex;

        .PriceSold-title-box-top-img {
          border-radius: 12px;
          margin-right: 14px;
          width: 64px;
          height: 64px;
          object-fit: cover;
        }

        .PriceSold-title-box-top-right-h3 {
          margin-top: 4px;
          color: var(--black, #1D1D1F);
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

        }

        .PriceSold-title-box-top-right-div {
          display: flex;
          margin-top: 8px;
          color: #666;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;

          /* 16.8px */
          .PriceSold-title-box-top-right-div-num {
            // border: 1px solid red;
            height: 14px;
            padding-right: 8px;
            border-right: 1.5px solid #666;
          }

          .PriceSold-title-box-top-right-div-price {
            margin-left: 8px;

            .PriceSold-title-box-top-right-price-span {
              color: #1D1D1F;
              font-family: 'MiSans-Demibold';
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%;
            }
          }
        }
      }

      .PriceSold-title-box-foot {
        display: flex;
        justify-content: space-between;
        // border: 1px solid red;
        padding-top: 14px;
        color: var(--black, #1D1D1F);
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        // margin-top: 14px;
        .PriceSold-title-box-foot-numbox {
          display: flex;
        }

      }
    }
  }

  .PriceSold-center {
    margin-left: 14px;
    margin-right: 14px;
    border-radius: 12px;
    background: var(--fff, #FFF);

    .PriceSold-center-box {
      padding: 22px 14px 22px 14px;

      .PriceSold-center-box-h3 {
        // border: 1px solid red;
        padding-bottom: 14px;
        border-bottom: 1px solid #E1E1E1;
      }

      .PriceSold-center-box-text {
        display: flex;
        justify-content: space-between;
        padding-top: 14px;

        .PriceSold-center-box-text-left {
          color: #666;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 175%;
          /* 24.5px */
        }

        .PriceSold-center-box-text-right {
          color: #666;
          text-align: right;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 175%;
          /* 24.5px */
        }
      }
    }
  }

  .PriceSold-center2 {
    margin-left: 14px;
    margin-right: 14px;
    margin-top: 14px;
    border-radius: 12px;
    background: var(--fff, #FFF);

    .PriceSold-center2-box2 {
      padding: 22px 14px 22px 14px;

      .PriceSold-center-box2-h3 {
        color: var(--333, #333);
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        padding-bottom: 10px;
        border-bottom: 1px solid #E1E1E1;
        // border: 1px solid red;
        /* 16.8px */
      }

      .PriceSold-center-box2-text {
        padding-top: 14px;
        color: #999;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        /* 183.333% */
        letter-spacing: 0.258px;
      }
    }
  }

  .PriceSold-btn {
    width: 100%;
    height: 108px;
    background: rgba(255, 255, 255, 0.88);
    backdrop-filter: blur(2px);
    position: fixed;
    bottom: 0;
    background-color: #fff;

    .PriceSold-btn-b {
      // border: 1px solid red;
      padding: 29px 14px 28px 14px;


      .PriceSold-btn-box {
        width: 347px;
        height: 52px;
        margin: 0 auto;
        text-align: center;
        line-height: 52px;
        border-radius: 12px;
        background: #000;
        color: #F5F8FA;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        /* 19.2px */
      }
    }
  }

  .MyAssets-Popup {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    &-title {
      color: #333;
      font-family: PingFang SC;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      /* 26.4px */
    }

    &-text {
      color: var(--gray);
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      margin-top: 8px;
      /* 24px */
    }

    &-box {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 32% 32% 32%;
      margin-top: 8px;


      &-item {
        display: flex;
        padding: 16px 0px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        color: var(--black, #1D1D1F);
        font-family: 'MiSans-Demibold';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        border-radius: 12px;
        background: var(--grey_bg, #F6F6F6);
        mix-blend-mode: darken;
        box-sizing: border-box;
        margin-top: 8px;
        position: relative;
        /* 16.8px */

        &-title {
          border-radius: 0px 4px;
          background: var(--purple, #4D33EF);
          color: var(--fff, #FFF);
          font-family: 'MiSans-Demibold';
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          display: flex;
          padding: 0 4px;
          height: 17px;
          justify-content: center;
          align-items: center;
          gap: 12px;
          position: absolute;
          right: 0;
          top: 0;
          /* 12px */
        }
      }
    }
  }
}