#PaymentPage {
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 85px 14px 0;
  box-sizing: border-box;

  .noneClass {
    display: none;
  }

  .PaymentPage-img {}


  .PaymentPage-h3 {
    color: var(--black, #1D1D1F);
    text-align: center;
    font-family: PingFang SC;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    margin-top: 12px;
    /* 28.8px */
  }

  .PaymentPage-center {
    // border: 1px solid red;
    width: 100%;
    margin-top: 18px;
    border-radius: 12px;
    background: var(--grey_bg, #F6F6F6);

    .PaymentPage-center-box {
      padding: 12px;

      .PaymentPage-center-box-top {
        // border: 1px solid red;
        display: flex;
        align-items: center;

        img {
          width: 70px;
          height: 70px;
          object-fit: cover;
          border-radius: 12px;
        }

        .PaymentPage-center-box-top-right {
          padding-top: 6px;
          // border: 1px solid blue;
          margin-left: 12px;

          .PaymentPage-center-box-top-right-h4 {
            margin-top: 4px;
            color: var(--black, #1D1D1F);
            font-family: PingFang SC;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            /* 19.2px */
          }

          .PaymentPage-center-box-top-right-footBox {
            // border: 1px solid red;
            margin-top: 24px;
            display: flex;
            align-items: center;

            .PaymentPage-center-box-top-right-footBox-div {
              display: flex;

              .PaymentPage-center-footBox-div-fa {
                width: 36px;
                height: 20px;
                border-radius: 4px 0px 0px 4px;
                background: var(--black, #1D1D1F);
                color: var(--fff, #FFF);
                text-align: center;
                font-family: PingFang SC;
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                /* 160% */
              }

              .PaymentPage-center-footBox-div-num {
                height: 20px;
                border-radius: 0px 4px 4px 0px;
                background: var(--fff, #FFF);
                color: var(--black, #1D1D1F);
                text-align: center;
                font-family: PingFang SC;
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                margin-right: 8px;
                padding: 0 5px;
                /* 160% */
              }
            }
          }
        }
      }

      .PaymentPage-center-box-centerBox {
        // border: 1px solid red;
        padding-top: 12px;
        padding-bottom: 8px;
        margin-top: 12px;
        border-top: 1px solid rgba(204, 204, 204, 0.5);
        border-bottom: 1px solid rgba(204, 204, 204, 0.5);

        .PaymentPage-center-box-centerBox-h4 {
          color: var(--black, #1D1D1F);
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          /* 19.2px */
        }

        .PaymentPage-center-box-centerBox-foot {
          // border: 1px solid red;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .PaymentPage-center-box-centerBox-left {
            // border: 1px solid red;
            margin-top: 4px;
            color: #999;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            /* 24.5px */
          }

          .PaymentPage-center-box-centerBox-right {
            text-align: right;
            color: #1D1D1F;
            text-align: right;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;

            /* 24.5px */
            .PaymentPage-center-box-centerBox-right-foot {
              display: flex;
            }
          }

          .PaymentPage-center-box-centerBox-foot-text {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;

            &-left {
              color: #000;
              font-family: PingFang SC;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
              /* 21px */
            }

            &-right {
              color: #333;
              font-family: MiSans;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%;
            }
          }
        }
      }

      .PaymentPage-center-box-foot {
        // border: 1px solid red;
        margin-top: 12px;

        .PaymentPage-center-box-foot-h4 {
          color: var(--black, #1D1D1F);
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          /* 19.2px */
        }

        .PaymentPage-center-box-foot-box {
          // border: 1px solid blue;
          display: flex;
          justify-content: space-between;
          margin-top: 4px;
          color: #999;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 175%;

          /* 24.5px */
          .PaymentPage-center-box-foot-box-right {
            text-align: right;
            color: #1D1D1F;
            text-align: right;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 175%;

            /* 24.5px */
            .PaymentPage-center-box-foot-box-right-span {
              margin-right: 4px;
            }

            .PaymentPage-center-box-foot-box-right-text {
              color: var(--purple, #4D33EF);
              font-family: PingFang SC;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 175%;
            }
          }
        }
      }
    }
  }

  .PaymentPage-btn-box {
    // border: 1px solid red;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 18px;
    box-sizing: border-box;

    .PaymentPage-btn-box-Cancel {
      width: 96px;
      height: 52px;
      border-radius: 12px;
      border: 1px solid #000;
      text-align: center;
      line-height: 52px;
      color: #000;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      /* 19.2px */
    }

    .PaymentPage-btn-box-continue {
      width: 65%;
      height: 54px;
      border-radius: 12px;
      background: #000;
      color: #F5F8FA;
      font-size: 16px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        margin-left: 5px;
        margin-top: 1px;
      }

      /* 19.2px */
    }
  }

  .PaymentPage-ok-btn {
    width: 100%;
    // border: 1px solid red;
    padding: 16px;
    border-radius: 12px;
    background: #000;
    color: #F5F8FA;
    font-family: PingFang SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    box-sizing: border-box;
    margin-top: 18px;
    // line-height: 52px;
    /* 19.2px */
  }
}