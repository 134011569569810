#WebView {
  min-height: 100vh;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  position: relative;

  .WebView-back {
    position: absolute;
    left: 15px;
    top: 15px;
    z-index: 1;
  }

  .container {
    padding-top: 62px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    border: none;
    box-sizing: border-box;

    /* Firefox */
    ::-webkit-scrollbar {
      width: 0;
    }
  }

}