.shopNum {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 24px 18px;
  box-sizing: border-box;
  background: #000;
  backdrop-filter: blur(12px);
  z-index: 4;
  border-radius: 22px 22px 0px 0px;
  color: #fff;

  &-top {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 22px;

    &-img {
      width: 58px;
      margin-right: 14px;
      border-radius: 12px;
    }

    &-content {
      &-text {
        color: var(--fff, #FFF);
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

      }

      &-p {
        display: flex;
        align-items: center;
        margin-top: 9px;

        &-img {
          margin-right: 4px;
          border-radius: 12px;
        }

        &-text {
          color: var(--e1e1e1, #E1E1E1);
          font-family: 'MiSans-Demibold';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          /* 14.4px */
        }
      }
    }
  }

  &-middle {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.12);
    width: 100%;
    display: flex;
    padding: 14px;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 22px;

    &-img {
      width: 46px;
      margin-right: 12px;
      border-radius: 8px;
    }

    &-content {
      display: flex;
      flex-direction: column;

      &-text1 {
        color: #FFF;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      &-text2 {
        color: rgba(255, 255, 255, 0.66);
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 4px;
      }
    }
  }

  &-box {
    display: flex;
    align-items: center;
    width: 100%;

    &-text {
      font-weight: 700;
      font-size: 16px;
      color: #F5F8FA;
      flex: 1;
    }

    &-handle {
      display: flex;
      width: 32px;
      height: 32px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid var(--purple, #4D33EF);
      background: rgba(68, 117, 242, 0.12);
      box-sizing: border-box;
    }

    &-value {
      font-family: ''MiSans-Demibold'-demibold';
      font-size: 16px;
      color: #FFFFFF;
      background-color: #000;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      text-align: center;
    }

    &-max {
      display: flex;
      padding: 9px 23px 9px 22px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid var(--purple, #4D33EF);
      background: rgba(68, 117, 242, 0.12);
      background: var(--title, linear-gradient(81deg, #B679D2 1.6%, #79AFD2 20.79%, #8FD89A 46.37%, #F0DD75 72.48%, #E8735C 102.33%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: center;
      font-family: 'MiSans-Demibold';
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      margin-left: 14px;
      /* 14.4px */
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    width: 100%;

    &-box {
      display: flex;
      flex-direction: column;

      &-context {
        &-text1 {
          font-size: 14px;
          color: #F5F8FA;
        }

        &-text2 {
          color: var(--White, #F5F8FA);
          font-family: 'MiSans-Demibold';
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
        }
      }
    }

    &-btn {
      display: flex;
      width: 161px;
      height: 50px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4D33EF;
      box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.60) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.60) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.30) inset, -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
      color: #FFF;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      /* 19.2px */

      img {
        margin-right: 6px;
      }
    }
  }
}