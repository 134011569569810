#Header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  padding: 20px 0;
  //     background-color: #11112101;
  // backdrop-filter: blur(6px);
  background-color: var(--theme-color);
  transform: translateZ(0);
  box-sizing: border-box;
  height: 62px;
  z-index: 11;


  .header-goback {
    position: absolute;
    left: 10px;
    transform: translateZ(0) !important;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 62px;
    z-index: 1;

    path {
      fill: var(--color);
    }

    .avatar {
      display: flex;
      margin-left: 4px;
      align-items: center;

      &-img {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        border: 2px solid #ffffff15;
        box-sizing: border-box;
        z-index: 2;
        animation: pulsing3 2s ease 0s 5 normal none running;
        object-fit: cover;
      }

      &-dot {
        width: 10px;
        height: 10px;
        background: #34c77b;
        border: 3px solid #040415;
        border-radius: 50%;
        position: relative;
        top: -14px;
        right: 11px;
        z-index: 3;
      }

      @keyframes pulsing3 {

        0% {
          box-shadow: #34c77b 0px 0px 0px 0px;
        }

        70% {
          box-shadow: #34c77b00 0px 0px 0px 16px;
        }

        100% {
          box-shadow: #34c77b00 0px 0px 0px 0px;
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        margin-left: -6px;

        &-title {
          font-size: 16px;
          font-weight: bold;
          width: 170px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-desc {
          font-size: 12px;
          color: #FFFFFF85;
          margin-top: 2px;
          width: 160px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .header-title {
    display: flex;

    &-img {
      width: 114px;
      height: 26px;
    }

    &-text {
      font-weight: bold;
      font-size: 16px;
      color: var(--color);
    }
  }

  .header-menu {
    font-weight: bold;
    font-size: 14px;
    color: var(--white);
    position: absolute;
    right: 20px;
  }
}