#HarvestAddress {
  min-height: 100vh;
  padding: 0 14px;

  .HarvestAddress-margin {
    padding-top: 70px;

    .HarvestAddress-box {
      height: 52px;
      align-items: flex-start;
      padding: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      background: #F6F6F6;
      margin-top: 14px;

      .HarvestAddress-top-left-img {
        width: 24px;
        height: 24px;
      }

      .HarvestAddress-top-right-mg {
        width: 20px;
        height: 20px;
        color: #333;
      }

      .HarvestAddress-text {
        width: 267px;
        color: #666;
        font-family: PingFang SC;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }
  }

  .HarvestAddress-btn {
    position: absolute;
    bottom: 10%;
    width: 95%;
    left: 0;
    right: 0;
    height: 52px;
    border-radius: 12px;
    background: var(--black, #1D1D1F);
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .HarvestAddress-btn-img {
      // margin-left: 110px;
      width: 24px;
      height: 24px;
    }
  }
}