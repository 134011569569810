#Pledge {
  width: 100%;
  min-height: 100vh;
  padding-top: 62px;

  // border: 1px solid red;
  .Pledge-title {
    color: #fff;
    background: #010206;
    // border: 1px solid red;
    padding-top: 5px;
    display: flex;
    justify-content: space-between;

    .Pledge-title-left {
      // border: 1px solid blue;
      margin-left: 12px;
      margin-top: 16px;

      .Pledge-title-left-p {
        color: rgba(255, 255, 255, 0.66);
        font-family: 'MiSans-Demibold';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;

        .Pledge-title-left-span {
          color: rgba(255, 255, 255, 0.66);
          font-family: 'MiSans-Demibold';
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
        }
      }

      .Pledge-title-left-foot {
        height: 22px;
        align-items: center;
        display: flex;
        margin-top: 10px;
        color: #fff;
        text-align: center;
        font-family: 'MiSans-Demibold';
        font-size: 16px;
        font-style: normal;
        font-weight: 900;
        line-height: 22px;

        .Pledge-title-left-foot-img1 {
          margin-left: 5px;
          margin-right: 4px;
        }

        .Pledge-title-left-foot-img2 {
          margin-left: 4px;
        }
      }
    }

    .Pledge-title-right {
      width: 102px;
      margin-top: 10px;
    }
  }

  .Pledge-nav {
    // border: 1px solid red;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .Pledge-nav-left {
      margin-top: 18px;
      color: var(--black, #1d1d1f);
      font-family: 'MiSans-Demibold';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;

      /* 157.143% */
      .Pledge-nav-left-num {
        display: flex;
        align-items: center;
        margin-top: 6px;

        .Pledge-nav-left-num-h3 {
          color: var(--black, #1d1d1f);
          font-family: 'MiSans-Demibold';
          font-size: 22px;
          font-style: normal;
          font-weight: 900;
          line-height: 22px;
          /* 100% */
        }

        .Pledge-nav-left-num-img {
          padding-top: 2px;
        }
      }
    }

    .Pledge-nav-right {
      display: flex;
      margin-top: 10px;
      // border: 1px solid blue;

      .Pledge-nav-right-btn1 {
        width: 92px;
        height: 36px;
        line-height: 36px;
        border-radius: 12px;
        background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%),
          radial-gradient(
            83.94% 83.94% at 26.39% 20.83%,
            rgba(255, 255, 255, 0.59) 0%,
            rgba(255, 255, 255, 0) 69.79%,
            rgba(255, 255, 255, 0) 100%
          ),
          #4d33ef;
        box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.6) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.3) inset,
          0px 1px 1px 0px rgba(255, 255, 255, 0.6) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.3) inset,
          -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
        color: #fff;
        text-align: center;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        /* 157.143% */
      }

      .Pledge-nav-right-btn2 {
        width: 92px;
        height: 34px;
        margin-left: 8px;
        border-radius: 12px;
        color: var(--black, #1d1d1f);
        text-align: center;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 34px;
        /* 157.143% */
        border: 1px solid #666;
        background: var(--grey_bg, #f6f6f6);
      }
    }
  }

  .Pledge-award {
    // border: 1px solid blue;
    border-top: 1px solid #aab8c2;
    border-bottom: 1px solid #aab8c2;
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 18px;
    padding-bottom: 18px;

    .Pledge-award-left {
      color: #000;
      text-align: right;
      font-family: 'MiSans-Demibold';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;

      /* 14.4px */
      .Pledge-award-left-top {
        display: flex;
        align-items: center;
        // border: 1px solid red;
        color: rgba(0, 0, 0, 0.66);
        text-align: right;
        font-family: 'MiSans-Demibold';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;

        .Pledge-award-left-top-img {
          margin-left: 4px;
          margin-right: 4px;
        }

        /* 14.4px */
        .Pledge-award-left-top-num {
          color: #000;
          text-align: right;
          font-family: 'MiSans-Demibold';
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          /* 14.4px */
        }
      }

      .Pledge-award-left-fot {
        display: flex;
        align-items: center;
        margin-top: 14px;

        .Pledge-award-left-fot-tadady {
          color: rgba(0, 0, 0, 0.66);
          text-align: right;
          font-family: 'MiSans-Demibold';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          margin-right: 4px;
          /* 14.4px */
        }

        .Pledge-award-left-fot-img {
          margin-right: 4px;
        }
      }
    }

    .Pledge-award-right {
      color: #000;
      text-align: right;
      font-family: 'MiSans-Demibold';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;

      /* 14.4px */
      .Pledge-award-right-top {
        display: flex;
        align-items: center;
        // border: 1px solid red;
        color: rgba(0, 0, 0, 0.66);
        text-align: right;
        font-family: 'MiSans-Demibold';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;

        .Pledge-award-right-top-img {
          margin-left: 4px;
          margin-right: 4px;
        }

        /* 14.4px */
        .Pledge-award-right-top-num {
          color: #000;
          text-align: right;
          font-family: 'MiSans-Demibold';
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          /* 14.4px */
        }
      }

      .Pledge-award-right-fot {
        display: flex;
        align-items: center;
        margin-top: 14px;

        .Pledge-award-right-fot-tadady {
          color: rgba(0, 0, 0, 0.66);
          text-align: right;
          font-family: 'MiSans-Demibold';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          margin-right: 4px;
          /* 14.4px */
        }

        .Pledge-award-right-fot-img {
          margin-right: 4px;
        }
      }
    }
  }

  .black {
    color: #000;
    font-family: PingFang SC;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 21.6px */
  }

  .Pledge-incomeBox {
    // border: 1px solid red;
    margin-left: 10px;
    margin-right: 10px;

    .Pledge-income {
      // border: 1px solid blue;
      padding-top: 18px;
      padding-bottom: 18px;
      display: flex;
      color: #999;
      font-family: PingFang SC;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .Pledge-income-left {
        margin-right: 32px;
      }
    }

    .Pledge-incomeBox-content {
      // border: 1px solid blue;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 18px;
      padding-bottom: 14px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .Pledge-incomeBox-content-left {
        color: var(--black, #1d1d1f);
        font-family: 'MiSans-Demibold';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;

        /* 16.8px */
        .Pledge-incomeBox-content-left-p {
          color: rgba(0, 0, 0, 0.66);
          text-align: right;
          font-family: 'MiSans-Demibold';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          margin-top: 10px;
          /* 14.4px */
        }
      }

      .Pledge-incomeBox-content-right {
        display: flex;
        align-items: center;
        color: var(--black, #1d1d1f);
        text-align: right;
        font-family: 'MiSans-Demibold';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;

        /* 19.2px */
        .Pledge-incomeBox-content-right-img {
          width: 20px;
          // margin-top: 2px;
          margin-left: 2px;
        }
      }
    }
  }

  .scrollView {
    width: 100%;
    height: 300px;
    overflow-y: scroll;
    position: relative;
  }

  .Pledge-text {
    // border: 1px solid red;
    margin-top: 18px;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 30px;
    // margin-bottom: 20px;

    .Pledge-text-h3 {
      color: var(--black, #1d1d1f);
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      margin-bottom: 14px;
      /* 19.2px */
    }

    .Pledge-text-box {
      color: var(--black, #1d1d1f);
      font-family: PingFang SC;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 175%;
      /* 22.75px */
    }
  }

  .Pledge-popup {
    .Pledge-popup-title {
      // border: 1px solid red;
      display: flex;
      align-items: center;

      .Pledge-popup-title-left {
        margin-right: 14px;
      }

      .Pledge-popup-title-right {
        color: var(--black, #1d1d1f);
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        .Pledge-popup-title-right-foot {
          display: flex;
          align-items: center;
          color: #666;
          font-family: 'MiSans-Demibold';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          margin-top: 8px;

          /* 14.4px */
          .Pledge-popup-title-right-img {
            margin-right: 4px;
          }
        }
      }
    }

    .Pledge-popup-center {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
      // border: 1px solid blue;

      .Pledge-popup-center-left {
        color: var(--black, #1d1d1f);
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .Pledge-popup-center-right {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .Pledge-popup-center-right-reduce {
          width: 32px;
          height: 32px;
          border-radius: 8px;
          border: 1px solid var(--purple, #4d33ef);
          background: rgba(77, 51, 239, 0.06);
          text-align: center;
          line-height: 32px;
          font-size: 16px;
        }

        .Pledge-popup-center-right-input {
          height: 19px;
          outline: none;
          border: none;
          color: #1d1d1f;
          text-align: center;
          font-family: 'MiSans-Demibold';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          padding: 0 18px;
          /* 19.2px */
        }

        .Pledge-popup-center-right-input::placeholder {
          color: #1d1d1f;
        }

        .Pledge-popup-center-right-add {
          width: 32px;
          height: 32px;
          border-radius: 8px;
          border: 1px solid var(--purple, #4d33ef);
          background: rgba(77, 51, 239, 0.06);
          text-align: center;
          line-height: 32px;
          font-size: 16px;
          margin-right: 14px;
        }

        .Pledge-popup-center-right-max {
          width: 76px;
          height: 32px;
          border-radius: 8px;
          border: 1px solid var(--purple, #4d33ef);
          background: rgba(77, 51, 239, 0.06);
          text-align: center;
          line-height: 32px;
          color: var(--purple, #4d33ef);
          text-align: center;
          font-family: 'MiSans-Demibold';
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          /* 14.4px */
        }
      }
    }

    .Pledge-popup-center2 {
      // border: 1px solid red;
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .Pledge-popup-center2-left {
        color: var(--black, #1d1d1f);
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .Pledge-popup-center2-right {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .Pledge-popup-center2-right-reduce {
          width: 32px;
          height: 32px;
          border-radius: 8px;
          border: 1px solid var(--purple, #4d33ef);
          background: rgba(77, 51, 239, 0.06);
          text-align: center;
          line-height: 32px;
          font-size: 16px;
        }

        .Pledge-popup-center2-right-input {
          height: 19px;
          outline: none;
          border: none;
          color: #1d1d1f;
          text-align: center;
          font-family: 'MiSans-Demibold';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          padding: 0 18px;
        }

        .Pledge-popup-center2-right-input::placeholder {
          color: var(--black, #1d1d1f);
          text-align: center;
          font-family: 'MiSans-Demibold';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          /* 19.2px */
        }

        .Pledge-popup-center2-right-add {
          width: 32px;
          height: 32px;
          border-radius: 8px;
          border: 1px solid var(--purple, #4d33ef);
          background: rgba(77, 51, 239, 0.06);
          text-align: center;
          line-height: 32px;
          font-size: 16px;
        }

        .Pledge-popup-center2-right-max {
          width: 76px;
          height: 32px;
          border-radius: 8px;
          border: 1px solid var(--purple, #4d33ef);
          background: rgba(77, 51, 239, 0.06);
          color: var(--purple, #4d33ef);
          text-align: center;
          font-family: 'MiSans-Demibold';
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
          margin-left: 14px;
          /* 14.4px */
        }
      }
    }

    .Pledge-popup-foot {
      // border: 1px solid red;
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .Pledge-popup-foot-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .Pledge-popup-secretCount {
          margin-right: 18px;
          .Pledge-popup-secretCount-title {
            font-size: 11px;
            font-weight: 400;
            line-height: 14px;
            color: #666;
            margin-bottom: 14px;
          }
          .Pledge-popup-secretCount-count {
            font-size: 14px;
            font-weight: 900;
            line-height: 22px;
          }
        }

        .Pledge-popup-luckCount {
          .Pledge-popup-luckCount-title {
            font-size: 11px;
            font-weight: 400;
            line-height: 14px;
            color: #666;
            margin-bottom: 14px;
          }
          .Pledge-popup-luckCount-count {
            font-size: 14px;
            font-weight: 900;
            line-height: 22px;
          }
        }

        .Pledge-popup-foot-left-div {
          color: #666;
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          /* 14.4px */
        }

        .Pledge-popup-foot-left-foot {
          display: flex;
          align-items: center;
          margin-top: 8px;
          color: var(--black, #1d1d1f);
          text-align: center;
          font-family: 'MiSans-Demibold';
          font-size: 16px;
          font-style: normal;
          font-weight: 900;
          line-height: 22px;

          /* 137.5% */
          .Pledge-popup-foot-left-foot-img1 {
            margin-left: 4px;
            margin-right: 4px;
          }

          .Pledge-popup-foot-left-foot-img2 {
            margin-left: 4px;
          }
        }
      }

      .Pledge-popup-foot-right {
        width: 161px;
        height: 50px;
        border-radius: 12px;
        background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%),
          radial-gradient(
            83.94% 83.94% at 26.39% 20.83%,
            rgba(255, 255, 255, 0.59) 0%,
            rgba(255, 255, 255, 0) 69.79%,
            rgba(255, 255, 255, 0) 100%
          ),
          #4d33ef;
        box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.6) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.3) inset,
          0px 1px 1px 0px rgba(255, 255, 255, 0.6) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.3) inset,
          -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
        color: var(--fff, #fff);
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 50px;
        text-align: center;
        /* 19.2px */
      }
    }
  }

  .Pledge-CenterPopup-box {
    // border: 1px solid red;
    padding-top: 32px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;

    .Pledge-CenterPopup-box-h3 {
      text-align: center;
    }

    .Pledge-CenterPopup-box-p {
      color: var(--text-icon-font-gy-260, rgba(0, 0, 0, 0.6));
      text-align: center;
      margin-top: 8px;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 150% */
    }

    .Pledge-CenterPopup-box-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
      // margin-bottom: 24px;
      // border: 1px solid red;

      .Pledge-CenterPopup-box-btn-left {
        width: 126px;
        height: 52px;
        border-radius: 12px;
        border: 1px solid #000;
        color: var(--black, #1d1d1f);
        text-align: center;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 52px;
        /* 150% */
      }

      .Pledge-CenterPopup-box-btn-right {
        width: 126px;
        height: 52px;
        border-radius: 12px;
        border: 1px solid #000;
        background: var(--black, #1d1d1f);
        text-align: center;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 52px;
        color: var(--text-icon-font-wh-1100, #fff);
        margin-left: 12px;
        /* 150% */
      }
    }
  }
}
