@import url('../../.././../globals.scss');

#SynthesisDetails {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 110px;

  .SynthesisDetails-img {
    width: 100%;
    height: 375px;
    margin-top: 62px;
    object-fit: contain;
  }

  .SynthesisDetails-nav {
    margin-left: 14px;
    margin-right: 14px;
    margin-top: 14px;
    // border: 1px solid red;
    display: flex;
    align-items: center;
    color: var(--black, #1d1d1f);
    font-family: PingFang SC;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;

    &-div {
      margin-left: 8px;
      color: #fff6f4;
      text-align: center;
      font-family: Ubuntu;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }
  }

  .SynthesisDetails-ProgressBarbox {
    margin-left: 14px;
    margin-right: 14px;
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
    // border: 1px solid red;
    color: #999;
    font-family: 'MiSans-Demibold';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;

    .SynthesisDetails-ProgressBar {
      flex: 0.96;
    }
  }

  .SynthesisDetails-centerBox1 {
    // border: 1px solid red;
    margin-left: 14px;
    margin-right: 14px;
    margin-top: 14px;

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--black, #1d1d1f);
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;

      /* 19.2px */
      &-p {
        color: var(--black, #1d1d1f);
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        /* 14.4px */
      }
    }

    &-list {
      // border: 1px solid red;
      margin-top: 14px;
      border-radius: 12px;
      padding: 12px;
      display: flex;
      align-items: center;
      background: var(--grey_bg, #f6f6f6);

      &-mainImage {
        width: 60px;
        height: 60px;
        border-radius: 12px;
        object-fit: cover;
      }

      &-right {
        margin-left: 14px;
        // border: 1px solid red;

        &-h4 {
          color: var(--black, #1d1d1f);
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          /* 21px */
        }

        &-p {
          color: #1d1d1f;
          margin-top: 12px;
          font-family: 'MiSans-Demibold';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          /* 14.4px */
        }
      }
    }
  }

  .SynthesisDetails-centerBox2 {
    // border: 1px solid red;
    margin-left: 14px;
    margin-right: 14px;
    margin-top: 14px;

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--black, #1d1d1f);
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;

      /* 19.2px */
      &-p {
        color: var(--black, #1d1d1f);
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        /* 14.4px */
      }
    }

    &-list {
      // border: 1px solid red;
      margin-top: 14px;
      border-radius: 12px;
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: center;
      background: var(--grey_bg, #f6f6f6);

      &-mainImage {
        width: 60px;
        height: 60px;
        border-radius: 12px;
        object-fit: cover;
      }

      &-left {
        display: flex;
        align-items: center;
      }

      &-right {
        margin-left: 14px;

        &-h4 {
          color: var(--black, #1d1d1f);
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          /* 21px */
        }

        &-p {
          color: #1d1d1f;
          margin-top: 12px;
          font-family: 'MiSans-Demibold';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          /* 14.4px */
        }
      }
    }

    .SynthesisDetails-centerBox2-list-right-OneBox {
      display: flex;
      justify-content: space-between;

      .SynthesisDetails-centerBox2-list-right-c {
        background: var(--ur, linear-gradient(224deg, #ffe557 2.58%, #f10808 49.75%, #ffe557 98.45%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: 'MiSans-Demibold';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-top: 12px;
        margin-right: 3px;
      }

      .SynthesisDetails-centerBox2-list-right-one {
        background: var(--ur, linear-gradient(224deg, #ffe557 2.58%, #f10808 49.75%, #ffe557 98.45%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: 'MiSans-Demibold';
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
      }
    }
  }

  .SynthesisDetails-textBox {
    // border: 1px solid red;
    margin-top: 14px;
    margin-left: 14px;
    margin-right: 14px;

    &-h3 {
      color: var(--black, #1d1d1f);
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      /* 19.2px */
    }

    &-p {
      color: #999;
      margin-top: 14px;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      /* 16.8px */
    }
  }

  .SynthesisDetails-btnBox {
    width: 100%;
    height: 108px;
    background: rgba(255, 255, 255, 0.66);
    backdrop-filter: blur(2px);
    position: fixed;
    bottom: 0;

    &-btn {
      // border: 1px solid red;
      margin: 0 auto;
      margin-top: 28px;
      margin-left: 14px;
      margin-right: 14px;
      padding: 16px;
      border-radius: 12px;
      background: #000;
      color: #f5f8fa;
      text-align: center;
      line-height: 51px;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      /* 19.2px */
    }
  }

  @mixin a {
    width: 36px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    font-weight: 700;
    font-size: 11px;
    color: #FFF6F4;
  }

  #S {
    @include a;
    background: radial-gradient(42.61% 55.51% at 60.46% 100%, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(58.56% 126.24% at 31.37% 0%, rgba(255, 255, 255, 0.46) 0%, rgba(255, 255, 255, 0) 77.6%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(74.14% 74.14% at 102.47% 39.73%, #8FE666 0%, rgba(143, 229, 102, 0) 77.6%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(88.4% 88.4% at 86.12% 6.46%, #72E98A 0%, rgba(114, 233, 138, 0) 56.56%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(73.57% 73.57% at 0% 67.49%, #0D7EFF 0%, rgba(86, 102, 239, 0) 69.33%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(124.52% 124.52% at -3.99% 35.36%, #00D1FF 0%, rgba(0, 209, 255, 0) 69.33%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , #D0E0F3;
    box-shadow: inset 0px 0px 18px rgba(255, 255, 255, 0.82);
    text-shadow: 0px 2px 4px #0060C2;
  }

  #SR {
    @include a;
    background: radial-gradient(71.61% 74.14% at 98.97% 39.73%, #B566E6 0%, rgba(181, 102, 230, 0) 77.6%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(85.38% 88.4% at 83.18% 6.46%, #FF1C89 0%, rgba(229, 102, 163, 0) 56.56%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(71.06% 73.57% at 0% 67.49%, #5666EF 0%, rgba(86, 102, 239, 0) 69.33%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(120.27% 124.52% at -3.86% 35.36%, #0066FF 0%, rgba(0, 78, 255, 0) 69.33%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , #8A42FF;
    box-shadow: inset 0px 0px 18px rgba(255, 255, 255, 0.82);
    text-shadow: 0px 2px 4px rgba(0, 38, 134, 0.73);
  }

  #SSR {
    @include a;
    background: linear-gradient(253.23deg, rgba(255, 229, 87, 0.2) 6.01%, rgba(255, 216, 0, 0.2) 87.5%), #FF9900;
    box-shadow: inset 0px 0px 18px rgba(255, 255, 255, 0.82);
    text-shadow: 0px 2px 4px #C58200;
  }

  #UR {
    @include a;
    background: #F44B28;
    box-shadow: inset 0px 4px 15px rgba(162, 46, 46, 0.8);
    text-shadow: 0px 2px 3px #9E1A07;
  }
}