#OrderPay {
    height: 100vh;
    padding: 0px 14px;
    box-sizing: borderPay-box;

    .OrderPay-box1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 70px;
        padding-bottom: 10px;

        &-img {
            width:
                46.67px;
            height:
                46.67px;
            margin-bottom: 10px;
        }

        &-text {
            text-align: center;
            font-family: PingFang SC;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 26.4px */
            color: var(--color);
        }

        &-text1 {
            color: rgba(102, 102, 102, 0.60);
            text-align: center;
            font-family: PingFang SC;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            margin-top: 8px;
            /* 14.4px */
        }
    }

    .OrderPay-box3 {
        display: flex;
        padding: 12px;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        background: #F6F6F6;
        margin-top: 14px;

        &-left {
            display: flex;
            align-items: center;

            &-img {
                margin-right: 8px;
            }

            &-text {
                color: #666;
                font-family: PingFang SC;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                width: 80%;
                /* 21px */
            }
        }

        &-right {}
    }

    .OrderPay-box2 {
        padding: 12px;
        border-radius: 10px;
        background: var(--grey_bg, #F6F6F6);
        margin-top: 18px;

        &-top {
            display: flex;
            position: relative;
            padding-bottom: 12px;

            .OrderPay-box2-top-img {
                width: 90px;
                height: 90px;
                margin-right: 12px;
                border-radius: 12px;
            }

            &-container {
                width: 100%;

                &-title {
                    font-weight: 700;
                    font-size: 16px;
                    color: var(--color);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;

                    &-text {
                        color: #333;
                        font-family: 'MiSans-Demibold';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 120%;

                        span {
                            font-size: 10px;
                        }
                    }
                }

                &-write {
                    display: flex;
                    align-items: center;

                    &-via {
                        width: 14px;
                        height: 14px;
                        margin-right: 4px;
                    }

                    &-text {
                        font-weight: 400;
                        font-size: 10px;
                        color: var(--color);
                    }
                }

                &-loaction {
                    display: flex;
                    align-items: center;
                    margin-top: 12px;
                    margin-left: 5/2px;
                    justify-content: end;

                    &-img {
                        width: 9.6px;
                        height: 12.8px;
                        margin-right: 5px;
                    }

                    &-text {
                        font-weight: 400;
                        font-size: 25/2px;
                        color: var(--gray);
                        width: 350/2px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            &::after {
                content: '';
                height: 1px;
                background-color: rgba(0, 0, 0, 0.12);
                position: absolute;
                bottom: 0;
                width: 100%;
                transform: scaleY(50%);
            }
        }

        &-section {
            padding-bottom: 12px;
            position: relative;
            margin-top: 12px;

            &-title {
                font-weight: 700;
                font-size: 16px;
                color: var(--color);
            }

            &-bottom {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &-text1 {
                    font-weight: 400;
                    font-size: 14px;
                    color: var(--color);
                }

                &-text2 {
                    font-weight: 600;
                    font-size: 24px;
                    color: var(--color);
                }
            }

            &::after {
                content: '';
                height: 1px;
                background-color: rgba(0, 0, 0, 0.12);
                position: absolute;
                bottom: 0;
                width: 100%;
                transform: scaleY(50%);
            }
        }

        &-data {
            margin-top: 12px;

            &-title {
                font-weight: 700;
                font-size: 16px;
                color: var(--color);
            }

            &-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: 400;
                font-size: 14px;
                color: var(--gray);
                line-height: 175%;
            }
        }
    }

    .OrderPay-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 18px;

        .OrderPay-btn1 {
            height: 49px;
            padding: 0 16px;
            font-weight: 700;
            font-size: 16px;
            color: #4475F2;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            border: 1px solid #000;
            color: #000;
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            /* 19.2px */
        }

        .OrderPay-btn {
            height: 51px;
            background: #4475F2;
            borderPay-radius: 4px;
            font-weight: 700;
            font-size: 16px;
            color: #F5F8FA;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: .94;
            border-radius: 12px;
            background: #000;
            color: #F5F8FA;
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 19.2px */
        }
    }
}