#CollectCards {
  width: 100%;
  min-height: 100vh;

  .CollectCards-title {
    margin-top: 72px;
    margin-left: 18px;
    margin-right: 18px;
    border-radius: 12px;
    background: #000;
    color: #fff;
    // border: 1px solid red;

    .CollectCards-title-box {
      padding: 27px 0px 27px 16px;
      background: url('../../assets/imgs/CollectCards-img.png') no-repeat right;
      background-size: 35%;

      .CollectCards-title-box-top {
        display: flex;

        .CollectCards-title-box-left-h3 {
          width: 187px;
          margin-bottom: 34px;
        }

        .CollectCards-title-box-left-div {
          color: rgba(255, 255, 255, 0.85);
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .CollectCards-ProgressBar {
        margin-top: 16px;
      }
    }
  }

  .CollectCards-nav {
    margin-left: 18px;
    margin-right: 18px;
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
    color: #666;
    font-family: PingFang SC;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 14.4px */

    .CollectCards-nav-left {
      color: var(--black, #1D1D1F);
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      /* 19.2px */
    }
  }

  .CollectCards-center {
    margin-left: 18px;
    margin-right: 18px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    // border: 1px solid red;

    .CollectCards-center-box {
      display: flex;
      align-items: center;
      border-radius: 12px;
      border: 0.5px solid rgba(0, 0, 0, 0.06);
      background: rgba(0, 0, 0, 0.06);
      padding: 12px;
      width: 41%;
      margin-top: 14px;
      color: #000;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      /* 21px */

      .CollectCards-center-box-imgbox {
        width: 54px;
        height: 54px;
        border-radius: 12px;
        background: #FFF;
        display: flex;
        align-items: center;
        margin-right: 14px;

        .CollectCards-center-box-img {
          margin: 0 auto;
        }
      }
    }
  }

  .CollectCards-btnBox {
    width: 100%;
    position: fixed;
    bottom: 0;
    background: rgba(255, 255, 255, 0.88);
    backdrop-filter: blur(2px);

    .CollectCards-btn {
      height: 54px;
      border-radius: 12px;
      background: #000;
      margin: 26px 15px 37px 13px;
      color: #F5F8FA;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 54px;
      text-align: center;
      /* 19.2px */
    }
  }
}