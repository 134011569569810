#GiveNft {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 80px 14px 0;

  .GiveNft-top {
    border-radius: 12px;
    background: var(--grey_bg, #F6F6F6);
    padding: 12px;
    display: flex;
    align-items: center;

    &-left {
      border-radius: 12px;
      object-fit: cover;
      width: 90px;
      height: 90px;
      margin-right: 12px;
    }

    &-right {
      display: flex;
      flex-direction: column;

      &-top {
        color: #333;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
      }

      &-center {
        display: flex;
        align-items: center;
        color: #999;
        font-family: 'MiSans-Demibold';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        margin-top: 8px;
        /* 14.4px */

        &-text {
          margin-left: 4px;
        }
      }
    }
  }

  .box3 {
    display: flex;
    align-items: center;
    margin-top: 8px;

    &-item {
      display: flex;
      align-items: center;
      margin-right: 8px;

      &-text1 {
        background: #000;
        border-radius: 4px 0px 0px 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        font-weight: 700;
        font-size: 10px;
        color: #fff;
      }

      &-text2 {
        background: #fff;
        height: 100%;
        border-radius: 0px 4px 4px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        font-size: 10px;
        font-family: ''MiSans-Demibold'-Demibold';
        color: #000;
      }
    }
  }

  .GiveNft-center {


    .GiveNft-center-illustrate {
      height: 64px;
      margin: 0 auto;
      margin-top: 22px;
      border-radius: 12px;
      background: rgba(77, 51, 239, 0.08);
      padding: 12px;
      color: var(--purple, #4D33EF);
      font-family: PingFang SC;
      font-size: 13px;
      font-weight: 600;
      line-height: 22px;
      white-space: nowrap;
    }

    .GiveNft-center-box {}

    .GiveNft-center-id {
      height: 52px;
      line-height: 52px;
      margin-top: 14px;
      border-radius: 12px;
      background: var(--grey_bg, #F6F6F6);
      color: #999;
      font-family: PingFang SC;
      font-size: 14px;
      // border: 1px solid red;

      .GiveNft-center-id-p {
        margin-left: 15px;
        width: 318px;
        height: 48px;
        background: var(--grey_bg, #F6F6F6);
        border: none;
        outline: none;
        border-radius: 12px;
        // border: 1px solid blueviolet;
      }
    }

    .GiveNft-center-num {
      height: 52px;
      line-height: 52px;
      margin-top: 14px;
      border-radius: 12px;
      background: var(--grey_bg, #F6F6F6);
      color: #999;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .GiveNft-center-num-p {
        margin-left: 15px;
        border: none;
        background: var(--grey_bg, #F6F6F6);
        outline: none;
        width: 100%;
      }

      .GiveNft-center-num-big {
        width: 49px;
        height: 28px;
        border-radius: 8px;
        text-align: center;
        line-height: 26px;
        color: #000;
        border: 1px solid #000;
        margin-right: 20px;
      }
    }

    .GiveNft-center-notice {
      width: 347px;
      height: 22px;
      display: flex;
      margin-top: 14px;
      margin-bottom: 14px;
      color: var(--purple, #4D33EF);
      font-family: PingFang SC;
      font-size: 12px;
      line-height: 22px;
      font-weight: 500;

      //组件Checkbox的style名字
      // .adm-checkbox-icon svg{
      //     width: 16px;
      //     height: 16px;
      //     background-color: #999;
      // }

      .GiveNft-center-notice-p {
        color: #4D33EF;
        margin-left: 4px;

        .GiveNft-center-notice-p-span {
          color: #999;
        }

        .GiveNft-center-notice-p-a {
          color: #4D33EF;
        }
      }
    }

    .GiveNft-center-notice input {
      width: 15px;
      height: 15px;
      margin-top: 2px;
      margin-right: 4px;
      background-color: #999;
    }

    .GiveNft-center-notice span {
      color: #999;
    }

    .GiveNft-center-notice-btn {
      width: 100%;
      height: 52px;
      border-radius: 12px;
      background: var(--black, #1D1D1F);
      color: #FFF;
      font-size: 16px;
      // margin-left: 18px;
      border: none;
    }
  }

}