#EditAddress {
  min-height: 100vh;
  padding-top: 70px;
  box-sizing: border-box;

  .adm-text-area-element {
    font-size: 14px;
  }

  .EditAddress-top-img {
    position: absolute;
    top: 20px;
    left: 350px;
  }

  .EditAddress-center {
    height: 56px;
    margin: 0 auto;
    padding: 0 16px;

    .EditAddress-center-box {
      width: 100%;
      height: 56px;
      border-bottom: 0.5px solid var(--gray-gray-3, #E7E7E7);
      display: flex;
      align-items: center;

      .EditAddress-center-box-location {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      .EditAddress-center-box-people {
        width: 80px;
        height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        // border: 1px solid red;
        color: var(--text-icon-font-gy-190, rgba(0, 0, 0, 0.90));
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        padding-left: 6px;
        padding-right: 30px;
      }

      .EditAddress-center-box-input {
        width: 100%;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        border: none;
        outline: none
      }

      input::placeholder {
        color: var(--text-icon-font-gy-340, rgba(0, 0, 0, 0.40));
      }
    }

    .EditAddress-center-area {
      display: flex;
      align-items: center;
      width: 100%;
      height: 56px;
      border-bottom: 0.5px solid var(--gray-gray-3, #E7E7E7);

      .EditAddress-center-box-people {
        width: 80px;
        margin-left: 6px;
      }

      .EditAddress-center-box-input {
        width: 198px;
        height: 56px;
        padding-left: 31px;
        margin-right: 34px;
        border: none;
        outline: none;
      }

      input::placeholder {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: var(--text-icon-font-gy-340, rgba(0, 0, 0, 0.40));
      }
    }

    .EditAddress-center-details {
      display: flex;
      align-items: center;
      width: 100%;
      height: 76px;
      border-bottom: 0.5px solid var(--gray-gray-3, #E7E7E7);

      .EditAddress-center-details-div {
        margin-left: 6px;
        width: 80px;
        height: 22px;
      }

      .EditAddress-center-details-p {
        width: 100%;
        height: 44px;
        margin-left: 28px;
        color: var(--text-icon-font-gy-190, rgba(0, 0, 0, 0.90));
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }

    .EditAddress-center-default {
      display: flex;
      align-items: center;
      width: 100%;
      height: 56px;
      border-bottom: 0.5px solid var(--gray-gray-3, #E7E7E7);
      justify-content: space-between;

      .EditAddress-center-default-div {
        margin-left: 6px;
        width: 350px;
        height: 22px;
      }
    }
  }


}