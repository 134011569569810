.LuckyDraw {
  width: 100vw;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .LuckyWheel {
    position: relative;
    z-index: 2;
  }
  .LuckyWheelStart {
    width: 50vw;
    height: 50vw;
    position: absolute;
    top: 25vw;
    left: 25vw;
    opacity: 0;
  }
  .LuckyDrawBottom {
    width: 76vw;
    margin-top: -190px;
  }
  .LuckyDraw-background {
    width: 76vw;
    height: 63vw;
  }
  .LuckyDraw-stock {
    margin-top: -80px;
    color: #7c000d;
    text-align: center;
    font-family: 'MiSans-Demibold';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .LuckyDraw-stockCount {
    color: #f6473c;
    font-family: 'MiSans-Demibold';
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
  }
  .LuckyDraw-desc {
    color: #c89996;
    text-align: center;
    font-family: PingFang SC;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 45px;
    min-height: 30px;
  }
  .LuckyDraw-Btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    .LuckyDraw-Btns-ten {
      width: 96px;
      height: 50px;
      border-radius: 12px;
      border: 1px solid var(--redpack, #f6463a);
      background: linear-gradient(180deg, rgba(246, 70, 58, 0.12) 0%, rgba(254, 122, 104, 0.12) 100%);
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 50px;
      text-align: center;
      color: #ea715f;
      margin-right: 14px;
    }
    .LuckyDraw-Btns-all {
      width: 182px;
      height: 50px;
      border-radius: 12px;
      background: var(--redpack, linear-gradient(180deg, #f6463a 0%, #fe7a68 100%));
      color: #f5f8fa;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 50px;
      text-align: center;
    }
  }
  .LuckyDraw-Links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    .LuckyDraw-Btns-rule {
      color: #fff;
      text-align: center;
      font-family: 'MiSans-Demibold';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 29px;
      text-decoration: underline;
      margin-right: 14px;
    }
    .LuckyDraw-Btns-record {
      color: #fff;
      text-align: center;
      font-family: 'MiSans-Demibold';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 29px;
      text-decoration: underline;
    }
  }
  .LuckyDraw-close {
    position: absolute;
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.LuckyDrawResultModal {
  width: 330px;
  height: 357px;
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background: #18181b;
  //   box-shadow: 0px 0px 0px 0.938px rgba(0, 0, 0, 0.5), 0px 16px 32px -16px rgba(0, 0, 0, 0.5);
  //   backdrop-filter: blur(64px);
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  //   background: transparent;
  &-title {
    width: 298px;
    color: #fff;
    font-family: 'MiSans-Demibold';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
  }
  &-image {
    width: 144px;
    height: 144px;
    margin-bottom: 14px;
  }
  &-name {
    color: #f5f8fa;
    text-align: center;
    font-family: PingFang SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 25px;
  }
  &-btn {
    width: 277px;
    height: 61px;
    border-radius: 18px;
    background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%),
      radial-gradient(
        83.94% 83.94% at 26.39% 20.83%,
        rgba(255, 255, 255, 0.59) 0%,
        rgba(255, 255, 255, 0) 69.79%,
        rgba(255, 255, 255, 0) 100%
      ),
      #4d33ef;
    box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.6) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.3) inset,
      0px 1px 1px 0px rgba(255, 255, 255, 0.6) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.3) inset,
      -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;

    color: #fff;
    text-align: center;
    font-family: 'MiSans-Demibold';
    font-size: 17px;
    font-style: normal;
    font-weight: 900;
    line-height: 61px;
  }
}
