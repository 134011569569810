#PartitionRecord {
  height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
  padding-top: 10px;

  .PartitionRecord-box {
    width: 100%;

    // border: 1px solid red;
    .partitionRecord-center-title {
      // width: 339px;
      padding-left: 8px;
      padding-right: 8px;
      margin: 0 auto;
      // border: 1px solid red;
      margin-top: 82px;
      display: flex;
      justify-content: space-between;
      color: var(--black, #1D1D1F);
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;

      /* 19.2px */
      .partitionRecord-center-title-p {
        color: #666;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
      }
    }
  }



  .partitionRecord-center {

    .partitionRecord-center-box {
      height: 77px;
      padding-left: 10px;
      padding-right: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.14);

      .partitionRecord-center-left {
        color: var(--black, #1D1D1F);
        text-align: justify;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;

        /* 16.8px */
        .partitionRecord-center-left-time {
          color: #666;
          margin-top: 5px;
          text-align: justify;
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          /* 14.4px */
        }
      }

      .partitionRecord-center-right {
        // width: 71px;
        // border: 1px solid red;
        display: flex;
        justify-content: space-between;
        color: #333;
        text-align: justify;
        font-family: 'MiSans-Demibold';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        .RankingList-bronze-top-right-img {
          width: 16px;
          height: 16px;
          margin-top: 2px;
        }
      }
    }
  }
}