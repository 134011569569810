#TradeOk {
    width: 100%;
    min-height: 100vh;

    .disappear {
        display: none;
        border: none;
    }

    .TradeOk-img {
        margin-top: 72px;
        // border: 1px solid red;
        padding-left: 43%;
    }

    .TradeOk-title-h3 {
        margin-top: 14px;
        color: #000;
        text-align: center;
        font-family: PingFang SC;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 26.4px */
    }

    .TradeOk-time {
        padding-left: 14px;
        padding-right: 14px;
        margin-top: 14px;

        .TradeOk-time-box {
            display: flex;
            border-radius: 10px;
            background: #F6F6F6;
            // border: 1px solid red;
            padding: 12px;

            .TradeOk-time-box-p {
                color: #666;
                font-family: PingFang SC;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                margin-left: 8px;
                /* 21px */
            }
        }
    }

    .TradeOk-center {
        // border: 1px solid red;
        margin-left: 14px;
        margin-right: 14px;
        margin-top: 14px;
        border-radius: 10px;
        background: var(--grey_bg, #F6F6F6);

        .TradeOk-center-box {
            padding-left: 12px;
            padding-right: 12px;
            padding-top: 12px;

            .TradeOk-center-box-top {
                display: flex;
                justify-content: space-between;

                .TradeOk-center-box-top-left {
                    display: flex;

                    .TradeOk-center-box-top-left-h3 {
                        color: var(--black, #1D1D1F);
                        font-family: PingFang SC;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 120%;
                        /* 21.6px */
                        margin-left: 12px;
                    }
                }

                .TradeOk-center-box-top-right {
                    text-align: right;

                    .TradeOk-center-box-top-right-h4 {
                        color: #333;
                        font-family: 'MiSans-Demibold';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 120%;

                        .TradeOk-center-box-top-right-h4-span {
                            color: #333;
                            text-align: center;
                            font-family: 'MiSans-Demibold';
                            font-size: 10px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 120%;
                            /* 12px */
                        }
                    }

                    .TradeOk-center-box-top-right-div {
                        color: #666;
                        margin-top: 12px;
                        font-family: PingFang SC;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        // border: 1px solid red;
                        /* 16.8px */
                    }
                }
            }

            .TradeOk-center-box-cent {
                // border: 1px solid red;
                display: flex;
                justify-content: space-between;
                align-items: end;
                padding-top: 12px;
                padding-bottom: 12px;
                margin-top: 12px;
                // border-bottom: 0.5px solid var(--e1e1e1, #E1E1E1);
                border-top: 0.5px solid var(--e1e1e1, #E1E1E1);


                .TradeOk-center-box-cent-left {
                    // border-top: 0.5px solid var(--e1e1e1, #E1E1E1);
                    .TradeOk-center-box-cent-left-h4 {
                        color: #333;
                        font-family: PingFang SC;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                        /* 19.2px */
                    }

                    .TradeOk-center-box-cent-left-p {
                        color: #000;
                        font-family: PingFang SC;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 150%;
                        margin-top: 10px;
                        /* 21px */
                    }
                }

                .TradeOk-center-box-cent-right {
                    color: #333;
                    font-family: 'MiSans-Demibold';
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 120%;
                    text-align: right;

                    .TradeOk-center-box-cent-right-span {
                        color: #333;
                        text-align: center;
                        font-family: 'MiSans-Demibold';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 120%;
                        margin-right: 6px;
                        /* 16.8px */
                    }
                }
            }

            .TradeOk-foot {
                .TradeOk-foot-h3 {
                    padding-top: 12px;
                    color: #333;
                    font-family: PingFang SC;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    border-top: 0.5px solid var(--e1e1e1, #E1E1E1);
                    /* 19.2px */
                }

                .TradeOk-foot-box {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 6px;
                    padding-bottom: 12px;

                    .TradeOk-foot-left {
                        color: #666;
                        font-family: PingFang SC;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 175%;
                        /* 24.5px */
                    }

                    .TradeOk-foot-right {
                        color: #666;
                        text-align: right;
                        font-family: PingFang SC;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 175%;

                        /* 24.5px */
                        .TradeOk-foot-right-num {
                            color: #000;
                            font-family: PingFang SC;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 175%;
                        }
                    }
                }
            }

        }

    }

    .TradeOk-btn {
        // border: 1px solid red;
        margin-left: 14px;
        margin-right: 14px;
        margin-top: 14px;

        .TradeOk-btn-box {
            // border: 1px solid blue;
            padding: 16px;
            text-align: center;
            border-radius: 12px;
            background: #000;
            color: #F5F8FA;
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 19.2px */
        }
    }

    
    .TradeOk-btn2 {
        // border: 1px solid red;
        margin-left: 14px;
        margin-right: 14px;
        margin-top: 32px;


        .TradeOk-btn-box {
            // border: 1px solid blue;
            padding: 16px;
            text-align: center;
            border-radius: 12px;
            background: #000;
            color: #F5F8FA;
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 19.2px */
        }
    }

}