#CornerstoneCard {
  width: 100%;
  min-height: 100vh;

  .CornerstoneCard-center {
    margin-top: 62px;
    margin-left: 18px;
    margin-right: 18px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    // border: 1px solid red;
    .CornerstoneCard-center-box {
      width: 40%;
      margin-top: 14px;
      border-radius: 12px;
      border: 0.5px solid rgba(0, 0, 0, 0.06);
      background: rgba(0, 0, 0, 0.06);
      display: flex;
      align-items: center;
      padding: 12px;
      .CornerstoneCard-center-box-img{
        // border: 1px solid red;
        padding-left: 8px;
        padding-right: 8px;
      }

      .CornerstoneCard-center-box-text {
        color: #000;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        margin-left: 14px;
        /* 21px */
      }
    }
  }
}