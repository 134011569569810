#Login {
    padding: 0 20px;
    min-height: 100vh;


    #Header {
        background: none;
    }

    #gradient-canvas {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        --gradient-color-1: #252B5C;
        --gradient-color-2: #000000;
        --gradient-color-3: #000000;
        --gradient-color-4: #000000;
    }

    .login-ctx1 {
        padding-top: 85px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-left {
            display: flex;
            flex-direction: column;

            &-text1 {
                font-weight: 600;
                font-size: 22px;
                line-height: 31px;
                color: var(--color);
            }

            &-text2 {
                font-weight: 400;
                font-size: 12px;
                line-height: 22px;
                color: var(--gray);
            }
        }

        &-img {
            width: 88px;
        }
    }

    input,
    textarea {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        border: 1px solid #ccc;
        background-color: #fff;
        padding: 5px;
    }

    .login-input {
        margin-top: 20px;
        height: 56px;
        width: 100%;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        padding: 0 14px;
        box-sizing: border-box;
        font-size: 16px;
        background: #F6F6F6;
        border: 2px solid rgba(255, 255, 255, 0.1);
        box-shadow: inset 0px 0px 4px rgba(255, 255, 255, 0.10);
        backdrop-filter: blur(4px);
        color: #000;
        font-family: ''MiSans-Demibold'-Demibold';
        transition: all 0.4s ease-in-out;
    }

    .login-input:focus {
        background-clip: padding-box,
            border-box;
        background-origin: padding-box,
            border-box;
        background-image: linear-gradient(to right, var(--theme-color), var(--theme-color)),
            linear-gradient(90deg, rgba(255, 99, 99, 0.8), var(--theme-color), rgba(155, 77, 255, 0.8));
        box-shadow: 0px 3px 22px 0px rgba(69, 80, 219, 0.5);
        transition: all 0.4s ease;
        outline: none;
    }

    // .login-input:hover {
    //     background-clip: padding-box,
    //         border-box;
    //     background-origin: padding-box,
    //         border-box;
    //     background-image: linear-gradient(to right, var(--theme-color), var(--theme-color)),
    //         linear-gradient(90deg, rgba(255, 99, 99, 0.8), var(--theme-color), rgba(155, 77, 255, 0.8));
    //     box-shadow: 0px 3px 22px 0px rgba(69, 80, 219, 0.5);
    //     transition: all 0.4s ease;
    //     outline: none;
    // }

    .login-ctx2 {
        display: flex;
        align-items: center;
        padding: 24px 0;

        &-check {
            margin-right: 5px;

            .adm-checkbox-icon {
                width: 14px;
                height: 14px;
                border: none;
                border-radius: 3px;
                font-size: 18px;
                background: #DADADA;
                color: #fff;
                border: 1px solid rgba(70, 64, 64, 0.068);
            }
        }

        &-p {
            font-weight: 400;
            font-size: 12px;
            color: var(--gray);
        }
    }

    .login-ctx3 {
        box-sizing: border-box;
        color: #fff;
        font-weight: bold;
        height: 56px;
        width: 100%;
        background: #1D1D1F;
        backdrop-filter: blur(12px);

        border-radius: 10px;
        margin-bottom: 14px;
        border: 2px solid rgba(255, 255, 255, 0.14);
        font-size: 15px;
        align-items: center;
        display: flex;
        justify-content: center;
        // justify-content: center;
        padding: 12px 10px;
        transition: all 0.5s ease;
        // margin-top: 18px;
        cursor: pointer;
    }

    .login-ctx3:hover {
        transition: all 0.4s ease-in-out;
        box-shadow: none;
    }

    .gradient-background {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        overflow: hidden;
    }

    .bg {
        background: linear-gradient(-45deg, #040415, #0f1022, #050505, #040415);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;
        height: 100vh;
        width: 100vw;
        margin: 0 auto;
        left: 0;
        right: 0;
        position: absolute;
        z-index: -2;
    }

    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }

        50% {
            background-position: 100% 50%;
        }

        100% {
            background-position: 0% 50%;
        }
    }


}