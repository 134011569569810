#synthesis {
  width: 100%;
  min-height: 100vh;
  padding-top: 62px;
  padding-bottom: 60px;
  box-sizing: border-box;

  .HomePage-List {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .HomePage-box {
      margin-top: 16px;
      width: 92%;
      height: 384px;
      position: relative;
      color: #fff;

      .HomePage-box-grade {
        position: absolute;
        left: 10px;
        top: 10px;
      }

      .HomePage-box-background {
        width: 100%;
        height: 384px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        border-radius: 14px;
        border: 1px solid white;
        object-fit: cover;
      }

      .HomePage-box-text {
        margin-left: 14px;
        margin-top: 63%;
        color: #fff;
        font-family: 'MiSans-Demibold';
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: 120%;
        /* 24px */
      }

      .HomePage-box-p {
        margin-top: 8px;
        margin-left: 14px;
        color: var(--White, #f5f8fa);
        font-family: 'MiSans-Demibold';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        /* 14.4px */
      }

      .HomePage-box-text2 {
        width: 120px;
        height: 41px;
        border-radius: 12px;
        background: rgba(255, 255, 255, 0.17);
        backdrop-filter: blur(2px);
        color: #fff;
        text-align: center;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 41px;
        margin-top: 28px;
        margin-left: 14px;
        margin-bottom: 18px;
        /* 16.8px */
      }
    }
  }

  @mixin a {
    width: 36px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    font-weight: 700;
    font-size: 11px;
    color: #FFF6F4;
  }

  #S {
    @include a;
    background: radial-gradient(42.61% 55.51% at 60.46% 100%, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(58.56% 126.24% at 31.37% 0%, rgba(255, 255, 255, 0.46) 0%, rgba(255, 255, 255, 0) 77.6%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(74.14% 74.14% at 102.47% 39.73%, #8FE666 0%, rgba(143, 229, 102, 0) 77.6%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(88.4% 88.4% at 86.12% 6.46%, #72E98A 0%, rgba(114, 233, 138, 0) 56.56%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(73.57% 73.57% at 0% 67.49%, #0D7EFF 0%, rgba(86, 102, 239, 0) 69.33%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(124.52% 124.52% at -3.99% 35.36%, #00D1FF 0%, rgba(0, 209, 255, 0) 69.33%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , #D0E0F3;
    box-shadow: inset 0px 0px 18px rgba(255, 255, 255, 0.82);
    text-shadow: 0px 2px 4px #0060C2;
  }

  #SR {
    @include a;
    background: radial-gradient(71.61% 74.14% at 98.97% 39.73%, #B566E6 0%, rgba(181, 102, 230, 0) 77.6%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(85.38% 88.4% at 83.18% 6.46%, #FF1C89 0%, rgba(229, 102, 163, 0) 56.56%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(71.06% 73.57% at 0% 67.49%, #5666EF 0%, rgba(86, 102, 239, 0) 69.33%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(120.27% 124.52% at -3.86% 35.36%, #0066FF 0%, rgba(0, 78, 255, 0) 69.33%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , #8A42FF;
    box-shadow: inset 0px 0px 18px rgba(255, 255, 255, 0.82);
    text-shadow: 0px 2px 4px rgba(0, 38, 134, 0.73);
  }

  #SSR {
    @include a;
    background: linear-gradient(253.23deg, rgba(255, 229, 87, 0.2) 6.01%, rgba(255, 216, 0, 0.2) 87.5%), #FF9900;
    box-shadow: inset 0px 0px 18px rgba(255, 255, 255, 0.82);
    text-shadow: 0px 2px 4px #C58200;
  }

  #UR {
    @include a;
    background: #F44B28;
    box-shadow: inset 0px 4px 15px rgba(162, 46, 46, 0.8);
    text-shadow: 0px 2px 3px #9E1A07;
  }
}