#PointsMall {
  width: 100%;
  height: 100vh;
  overflow: auto;

  .PointsMall-title {
    // border: 1px solid red;
    height: 164px;
    background: url('../../../assets/imgs/PointsMall-title.jpg') no-repeat;
    background-size: 100%;

    &-nav {
      // border: 1px solid blue;
      margin-left: 16px;
      margin-right: 16px;
      height: 44px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #FFF;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      /* 157.143% */

      &-name {
        color: #FFF;
        text-align: center;
        font-family: PingFang SC;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        margin-left: 10%;
        /* 129.412% */
      }
    }

    &-numBox {
      // border: 1px solid red;
      margin-left: 16px;
      margin-right: 16px;
      margin-top: 26px;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      background: linear-gradient(258deg, rgba(255, 255, 255, 0.29) 2.27%, rgba(255, 255, 255, 0.00) 35.4%), linear-gradient(104deg, rgba(255, 255, 255, 0.23) 3.44%, rgba(255, 255, 255, 0.00) 36.41%), rgba(255, 255, 255, 0.06);
      box-shadow: 0px 4px 12px 0px rgba(192, 40, 27, 0.14), 0px 2px 4px 0px rgba(255, 255, 255, 0.25) inset;
      backdrop-filter: blur(12px);

      &-box {
        padding: 22px 46px 20px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-small {
          &-h3 {
            color: #FFF;
            font-family: 'MiSans-Demibold';
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            /* 136.364% */
          }

          &-p {
            color: rgba(255, 255, 255, 0.84);
            font-family: PingFang SC;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 6px;
          }

        }
      }
    }
  }

  .PointsMall-navBox {
    margin-top: 8px;
    margin-left: 35px;
    margin-right: 35px;
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // border: 1px solid red;
    &-box {
      color: #999;
      margin-top: 10px;
      padding-bottom: 10px;
      font-size: 14px;
      // border: 1px solid red;
    }

  }

  .action {
    border-bottom: 2px solid #1D1D1F;
    margin-top: 10px;
    padding-bottom: 10px;
    border-radius: 2px;
    color: var(--black, #1D1D1F);
    text-align: center;
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 157.143% */
  }

  .PointsMall-quantityBox {
    // border: 1px solid red;
    margin-top: 14px;
    margin-left: 14px;
    display: flex;
    align-items: center;
    overflow-x: auto;
    white-space: nowrap;

    &-quantity {
      margin-left: 8px;
      padding: 2px 12px 2px 12px;
      border-radius: 999px;
      background: var(--grey_bg, #F6F6F6);
      color: #999;
      text-align: center;
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      /* 183.333% */
    }

    .black {
      border-radius: 999px;
      background: var(--black, #1D1D1F);
      color: var(--fff, #FFF);
      text-align: center;
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      /* 183.333% */
    }
  }

  .PointsMall-center {
    margin-left: 16px;
    margin-right: 16px;
    // border: 1px solid red;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &-box {
      // border: 1px solid blue;
      margin-top: 16px;
      // width: 167px;
      width: 48%;
      height: 215px;
      border-radius: 12px;
      background: var(--grey_bg, #F6F6F6);

      &-card {
        padding: 12px;
        // border: 1px solid red;

        &-top {
          height: 132px;
          background-size: 100%;
          border-radius: 8px;
          border: 1px solid #E1E1E1;
          position: relative;
          overflow: hidden;

          &-text {
            padding: 0 8px;
            height: 20px;
            // padding: 4px;
            border-radius: 4px;
            background: #F6F6F6;
            margin-top: 6px;
            margin-left: 8px;
            color: #999;
            text-align: center;
            font-family: PingFang SC;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0.9;
            /* 12px */
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        &-h3 {
          color: #1D1D1F;
          margin-top: 7px;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          /* 21px */
        }

        &-bottom {
          display: flex;
          justify-content: space-between;
          align-items: end;
          margin-top: 12px;
          color: #999;
          text-align: center;
          font-family: PingFang SC;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;

          /* 12px */
          &-h3 {
            color: #1D1D1F;
            text-align: center;
            font-family: 'MiSans-Demibold';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 16.8px */
          }
        }
      }
    }
  }


  .PointsMall-CenterPopup {
    width: 100%;
    padding: 0 0 20px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.9);
    border: 2px solid rgba(255, 255, 255, 0.20);
    // background: rgba(255, 255, 255, 0.08);
    box-shadow: 0px 0px 0px 0.938px rgba(0, 0, 0, 0.50), 0px 16px 32px -16px rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(64px);

    &-box {
      display: flex;
      align-items: center;

      &-h3 {
        margin-left: 10px;
        padding-top: 10px;
        color: #FFF;
      }

      &-img {
        margin-top: 10px;
        margin-left: 8px;
      }
    }

    &-center {
      // border: 1px solid red;
      margin-left: 80px;
      margin-right: 80px;
      margin-top: 20px;
      color: var(--White, #F5F8FA);
      text-align: center;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18.75px;
      letter-spacing: 0.08px;

      img{
        height: 144px;
        object-fit: contain;
      }

      &-h3 {
        margin-top: 14px;
      }
    }

    &-btnBox {
      // border: 1px solid red;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 22px;
      padding: 16px 70px 13px 70px;
      border-radius: 18px;
      background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4D33EF;
      box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.60) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.60) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.30) inset, -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
      color: #FFF;
      font-style: normal;
      font-weight: 900;
      margin: 25px 10px 0;
    }

    .shopNum-box {
      display: flex;
      align-items: center;
      padding: 20px 20px 0px;

      &-text {
        font-weight: 700;
        font-size: 14px;
        color: #F5F8FA;
        flex: 1;
      }

      &-handle {
        display: flex;
        width: 32px;
        height: 32px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid var(--purple, #4D33EF);
        background: rgba(68, 117, 242, 0.12);
        box-sizing: border-box;
      }

      &-value {
        font-family: ''MiSans-Demibold'-demibold';
        font-size: 16px;
        color: #FFFFFF;
        background-color: rgba(0, 0, 0, -1);
        width: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        text-align: center;
      }

      &-max {
        display: flex;
        padding: 9px 23px 9px 22px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid var(--purple, #4D33EF);
        background: rgba(68, 117, 242, 0.12);
        background: var(--title, linear-gradient(81deg, #B679D2 1.6%, #79AFD2 20.79%, #8FD89A 46.37%, #F0DD75 72.48%, #E8735C 102.33%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-family: 'MiSans-Demibold';
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin-left: 14px;
        /* 14.4px */
      }
    }
  }

  .Pledge-CenterPopup-box {
    // border: 1px solid red;
    padding-top: 32px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;

    .Pledge-CenterPopup-box-h3 {
      text-align: center;
    }

    .Pledge-CenterPopup-box-p {
      color: var(--text-icon-font-gy-260, rgba(0, 0, 0, 0.60));
      text-align: center;
      margin-top: 8px;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 150% */
    }

    .Pledge-CenterPopup-box-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
      // margin-bottom: 24px;
      // border: 1px solid red;

      .Pledge-CenterPopup-box-btn-left {
        width: 126px;
        height: 52px;
        border-radius: 12px;
        border: 1px solid #000;
        color: var(--black, #1D1D1F);
        text-align: center;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 52px;
        /* 150% */
      }

      .Pledge-CenterPopup-box-btn-right {
        width: 126px;
        height: 52px;
        border-radius: 12px;
        border: 1px solid #000;
        background: var(--black, #1D1D1F);
        text-align: center;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 52px;
        color: var(--text-icon-font-wh-1100, #FFF);
        margin-left: 12px;
        /* 150% */
      }
    }
  }
}