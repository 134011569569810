#ExchangeRecord {
  width: 100%;
  height: 100vh;
  padding-top: 72px;
  box-sizing: border-box;
  overflow: auto;

  .ExchangeRecord-textBox {
    margin: 0px 14px 0px 14px;
    border-radius: 12px;
    background: rgba(77, 51, 239, 0.06);

    &-text {
      padding: 12px;
      color: var(--purple, #4D33EF);
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      /* 183.333% */
    }
  }

  .ExchangeRecord-center {
    margin-left: 14px;
    margin-right: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border: 1px solid red; 
    padding-top: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid rgba(170, 184, 194, 0.14);

    &-left {
      color: #1D1D1F;
      font-family: 'MiSans-Demibold';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      /* 16.8px */
    }

    &-right {
      text-align: right;

      &-h3 {
        color: #1D1D1F;
        font-family: 'MiSans-Demibold';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 16.8px */
      }

      &-p {
        color: #999;
        margin-top: 7px;
        text-align: right;
        font-family: 'MiSans-Demibold';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 14.4px */
      }
    }
  }
}