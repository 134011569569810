#SellAndSold {
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 30px;

  .SellAndSold-title {
    padding-top: 15px;
    margin-left: 18px;
    margin-right: 18px;
    display: flex;
    align-items: center;

    &-right {
      width: 265px;
      height: 39px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 0 auto;
      border-radius: 12px;
      background: #F3F3F3;
      padding: 0 5px;

      &-sell {
        width: 64px;
        width: 45%;
        height: 30px;
        color: #666;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        // border: 1px solid red;
      }

      &-sold {
        // width: 64px;
        width: 45%;
        height: 30px;
        color: #666;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        // border: 1px solid red;
      }

      .switch {
        // width: 84px;
        width: 45%;
        height: 32px;
        border-radius: 10px;
        background: var(--fff, #FFF);
        overflow: hidden;
        color: var(--black, #1D1D1F);
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
      }
    }
  }

  .SellAndSold-box1 {
    margin: 14px;
    // border: 1px solid red;
    border-radius: 12px;
    background: #F6F6F6;

    &-top {
      padding-top: 12px;
      padding-bottom: 12px;
      margin-left: 12px;
      margin-right: 12px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(170, 184, 194, 0.22);

      &-left {
        display: flex;

        >img {
          width: 52px;
          height: 52px;
          border-radius: 12px;
        }

        span {
          font-size: 12px;
          color: #333;
        }

        &-h3 {
          color: var(--333, #333);
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          margin-left: 12px;

          /* 19.2px */
        }

        &-two {
          color: var(--333, #333);
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          margin-left: 12px;
          margin-bottom: 20px;
          /* 19.2px */
        }
      }

      &-right {
        color: #333;
        font-family: 'MiSans-Demibold';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        text-align: right;

        &-num {
          color: #666;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          margin-top: 4px;
          /* 16.8px */
        }

        &-span {
          color: #333;
          text-align: center;
          font-family: 'MiSans-Demibold';
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          /* 12px */
        }
      }
    }

    &-bottom {
      padding-top: 12px;
      padding-bottom: 12px;
      margin-left: 12px;
      margin-right: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border: 1px solid red;


      &-left {
        color: #333;
        font-family: 'MiSans-Demibold';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;

        &-span {
          color: #333;
          text-align: center;
          font-family: 'MiSans-Demibold';
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          /* 12px */
        }
      }

      &-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // border: 1px solid red;

        &-price {
          width: 56px;
          height: 29px;
          border-radius: 6px;
          background: var(--black, #1D1D1F);
          display: flex;
          align-items: center;
          color: var(--fff, #FFF);
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

          &-img {
            margin-left: 8px;
            margin-right: 2px;
          }
        }

        &-end {
          width: 56px;
          height: 29px;
          border-radius: 6px;
          border: 1px solid rgba(255, 33, 0, 0.19);
          background: rgba(255, 21, 0, 0.04);
          display: flex;
          align-items: center;
          margin-left: 12px;

          &-img {
            margin-left: 8px;
            margin-right: 2px;
          }

          &-text {
            background: linear-gradient(180deg, #BC382C 0%, #FF5C00 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }

    &-bottom2 {
      padding-top: 12px;
      padding-bottom: 12px;
      margin-left: 12px;
      margin-right: 12px;
      // border: 1px solid red;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-left {
        color: #666;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 175%;
        /* 24.5px */
      }

      &-right {
        color: #666;
        font-family: 'MiSans-Demibold';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%;
        text-align: right;

        &-num {
          text-align: right;
          font-family: 'MiSans-Demibold';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 175%;
          /* 24.5px */
        }
      }
    }
  }

  .noClass {
    display: none;
  }

  .SellAndSold-btn {
    position: fixed;
    right: 20px;
    bottom: 60px;
  }



  .Popup-top {
    border-radius: 12px;
    background: var(--grey_bg, #F6F6F6);

    .Popup-top-title {
      height: 49px;
      // border: 1px solid red;
      display: flex;


      .Popup-top-img {
        width: 52px;
        height: 54.458px;
        flex-shrink: 0;
        border-radius: 12px;
        border: 1px solid var(--e1e1e1, #E1E1E1);
        margin-left: 14px;
        margin-top: 20px;
        margin-right: 14px;
      }

      .Popup-top-right {
        margin-top: 20px;

        .Popup-top-right-name {
          color: var(--black, #1D1D1F);
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .Popup-top-right-two {
          width: 58px;
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .Popup-top-money {
      height: 42px;
      display: flex;
      align-items: center;
      margin-top: 40px;
      margin-left: 14px;
      margin-bottom: 5px;

      // border: 1px solid red;
      .Popup-top-money-tet {
        margin-top: 12px;
      }

      .Popup-top-money-inp {
        width: 100%;
        height: 42px;
        outline: none;
        border: none;
        margin-left: 10px;
        border-radius: 12px;
        background: var(--grey_bg, #F6F6F6);
        color: var(--black, #1D1D1F);
        font-family: 'MiSans-Demibold';
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
      }


      // .Popup-top-money-span {
      //     font-size: 10px;
      // }
    }

    .Popup-top-money-service {
      margin-left: 14px;
      margin-right: 14px;
      border-top: 1px solid #E1E1E1;
      display: flex;
      align-items: center;
      white-space: nowrap;
      padding-top: 10px;

      .Popup-top-money-service-span {
        color: #4D33EF;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        white-space: nowrap;
        width: 200px;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &:last-child {
        border: none;
        padding-bottom: 15px;
        padding-top: 0;
      }
    }
  }



  .Popup-top-center {
    // border: 1px solid red;
    margin: 0 auto;

    .Popup-h4 {
      margin-top: 14px;
      margin-bottom: 8px;
      color: var(--333, #333);
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      margin-left: 10px;
      /* 16.8px */
    }

    .Popup-wallet-sd {
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 12px;
      background: var(--grey_bg, #F6F6F6);
      padding: 0 12px;

      .Popup-wallet-sd-left {
        display: flex;

        .Popup-wallet-sd-left-img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }

        .Popup-wallet-sd-left-text {
          color: var(--black, #1D1D1F);
          text-align: center;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .Popup-wallet-hf-right {
        width: 75px;
        // border: 1px solid red;
        display: flex;
        justify-content: space-between;
        color: var(--purple, #4D33EF);
        text-align: right;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 16.8px */
      }

      .Popup-wallet-sd-left-checkbox {
        position: relative;
      }

      .Checkbox:checked+label:after {
        content: "";
        position: absolute;
        left: 2px;
        top: 2px;
        width: 9px;
        height: 4px;
        border: 2px solid #424242;
        border-top-color: transparent;
        border-right-color: transparent;
        transform: rotate(-45deg);
        -ms-transform: rotate(-60deg);
        -moz-transform: rotate(-60deg);
        -webkit-transform: rotate(-60deg);
      }
    }

    .Popup-wallet-hf {
      height: 56px;
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 12px;
      background: var(--grey_bg, #F6F6F6);
      padding: 0 14px;

      .Popup-wallet-hf-left {
        display: flex;

        .Popup-wallet-hf-left-img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }

        .Popup-wallet-hf-left-text {
          color: var(--black, #1D1D1F);
          text-align: center;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .Popup-wallet-hf-right {
        width: 75px;
        // border: 1px solid red;
        display: flex;
        justify-content: space-between;
        color: var(--purple, #4D33EF);
        text-align: right;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 16.8px */
      }

    }
  }

  .Popup-top-foot {
    margin: 0 auto;

    .Popup-top-foot-h3 {
      margin-top: 14px;
      margin-bottom: 8px;
      color: var(--333, #333);
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      margin-left: 10px;
      /* 16.8px */
    }

    .Popup-top-foot-p {
      color: var(--tint-tertiary-light, #BFBFBF);
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.258px;
      margin-left: 10px;

    }
  }



  .Popup-top-btn-box {
    padding: 26px 0 0px;

    .Popup-top-btn {
      height: 61px;
      backdrop-filter: blur(2px);
      margin: 0 auto;
      border-radius: 18px;
      background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4D33EF;
      box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.60) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.60) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.30) inset, -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFF;
      text-align: center;
      font-family: 'MiSans-Demibold';
      font-size: 17px;
      font-style: normal;
      font-weight: 900;
      line-height: 32px;
      /* 188.235% */

      .Popup-top-btn {
        width: 348px;
        height: 61px;
        flex-shrink: 0;
        border-radius: 18px;
        background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4D33EF;
        box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.60) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.60) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.30) inset, -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
        text-align: center;
        line-height: 61px;
        color: #FFF;
        font-family: 'MiSans-Demibold';
        font-size: 17px;
        font-style: normal;
        font-weight: 900;
        margin-top: 26px;
        /* 188.235% */
      }
    }
  }

  .GiveNft-top-right-center {
    display: flex;
    align-items: flex-start;
    ;
    color: #999;
    font-family: 'MiSans-Demibold';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    margin-top: 4px;
    /* 14.4px */

    &-text {
      margin-left: 4px;
    }
  }

  .transfer_state {
    padding: 2px 8px;
    border-radius: 4px;
    border: 1px solid var(--purple, #4D33EF);
    background: rgba(68, 117, 242, 0.04);
    color: var(--purple, #4D33EF);
    font-family: PingFang SC;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    height: 20px;
    align-self: flex-end;
  }
}