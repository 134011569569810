#Typer {
  padding: 12px;
  margin: 0 auto;
  transition: all 0.3s linear;
  height: 100vh;
  box-sizing: border-box;

  .dialog {
    background: linear-gradient(0deg, #000 0%, rgba(255, 255, 255, 0) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .typing {
    font-size: 22px;
    margin: 0 auto;
    line-height: 44px;
    font-weight: 900;
    white-space: pre-wrap;
    text-align: justify;
    bottom: 0;
  }

  .typed-cursor {
    font-size: 24px;
    border-right: 10px solid #000;
  }

  .container {
    --animate-delay: 10s;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-top: 10px;
    background: rgba(4, 4, 21, 0.66);
    backdrop-filter: blur(12px);
    padding: 10px 14px 24px;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;

    &-top {
      display: flex;
      align-items: baseline;

      .text {
        font-weight: 400;
        font-size: 12px;
        color: #AAB8C2;
        display: flex;
        margin-bottom: 1.5px;
        margin-left: 8px;
      }
    }



    .btn {
      height: 100px;
      background: #4475F2;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: 16px;
      color: #F5F8FA;
      margin-top: 14px;
      font-weight: bold;
    }
  }

  .Typer-footer {
    padding: 24px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 20px 14px;
    background: #F6F6F6;
    backdrop-filter: blur(6px);
    left: 0;
    box-sizing: border-box;
    --animate-delay: 10s;

    &-top {
      display: flex;
      align-items: center;

      &-check {
        margin-right: 5px;

        .adm-checkbox-icon {
          width: 14px;
          height: 14px;
          border: none;
          border-radius: 3px;
          font-size: 18px;
          background: #DADADA;
          color: #fff;
          border: 1px solid rgba(70, 64, 64, 0.068);
        }
      }

      &-p {
        font-weight: 400;
        font-size: 12px;
        color: var(--gray);
      }
    }

    &-btn {
      height: 52px;
      flex-shrink: 0;
      border-radius: 7px;
      background: #1D1D1F;
      color: #FFF;
      text-align: center;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 14px;
    }
  }
}