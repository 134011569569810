#CollectionSynthesis {
  width: 100%;
  min-height: 100vh;
  padding-top: 76px;
  box-sizing: border-box;
  padding-bottom: 160px;

  .CollectionSynthesis-title {
    margin-left: 14px;
    margin-right: 14px;
    border-radius: 12px;
    background: rgba(77, 51, 239, 0.06);

    &-p {
      padding: 12px;
      color: var(--purple, #4d33ef);
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      /* 183.333% */
    }
  }

  .CollectionSynthesis-h3 {
    margin-top: 20px;
    margin-left: 14px;
    margin-right: 14px;
    color: var(--black, #1d1d1f);
    font-family: PingFang SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 19.2px */
  }

  .CollectionSynthesis-center {
    margin-top: 14px;
    margin-left: 14px;
    display: flex;
    align-items: center;
    &-mainImage {
      width: 78px;
      height: 78px;
      border-radius: 8px;
      object-fit: cover;
    }

    &-left {
      margin-left: 12px;

      &-top {
        display: flex;
        color: var(--black, #1d1d1f);
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;

        /* 19.2px */
        &-span {
          color: #fff6f4;
          text-align: center;
          font-family: Ubuntu;
          font-size: 11px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          margin-left: 8px;
          /* 145.455% */
        }
      }

      &-bottom {
        // border: 1px solid red;
        margin-top: 12px;
        height: 20px;
        display: flex;

        &-left {
          width: 36px;
          height: 20px;
          border-radius: 4px 0px 0px 4px;
          background: var(--black, #1d1d1f);
          color: var(--grey_bg, #f6f6f6);
          text-align: center;
          font-family: PingFang SC;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          /* 160% */
        }

        &-right {
          padding: 0 8px;
          height: 20px;
          border-radius: 0px 4px 4px 0px;
          background: var(--grey_bg, #f6f6f6);
          color: var(--black, #1d1d1f);
          text-align: center;
          font-family: PingFang SC;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          /* 160% */
        }
      }
    }
  }

  .CollectionSynthesis-text {
    margin-left: 14px;
    margin-right: 14px;
    margin-top: 20px;
    color: var(--black, #1d1d1f);
    font-family: PingFang SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 19.2px */
  }

  .CollectionSynthesis-center2 {
    margin-left: 14px;
    margin-top: 14px;
    display: flex;
    align-items: center;

    &-mainImage {
      width: 78px;
      height: 78px;
      border-radius: 8px;
      object-fit: cover;
    }

    &-right {
      // border: 1px solid red;
      margin-left: 12px;

      &-top {
        display: flex;
        color: var(--black, #1d1d1f);
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;

        /* 19.2px */
        &-SSR {
          width: 36px;
          height: 20px;
          border-radius: 2px;
          color: #fff6f4;
          text-align: center;
          font-family: Ubuntu;
          font-size: 11px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          margin-left: 8px;
          /* 145.455% */
        }
      }

      &-bottom {
        display: flex;
        margin-top: 12px;

        &-left {
          display: flex;
          align-items: center;
          width: 72px;
          height: 24px;
          border-radius: 6px;
          background: linear-gradient(180deg, rgba(188, 56, 44, 0.12) 0%, rgba(255, 92, 0, 0.12) 100%);
          font-family: PingFang SC;
          font-size: 11px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          margin-right: 12px;

          /* 181.818% */
          &-img {
            margin-left: 6px;
            margin-right: 2px;
          }

          &-text {
            background: var(--UR, linear-gradient(180deg, #bc382c 0%, #ff5c00 100%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        &-right {
          display: flex;
          align-items: center;
          width: 72px;
          height: 24px;
          border-radius: 6px;
          background: rgba(77, 51, 239, 0.08);

          &-img {
            margin-left: 6px;
            margin-right: 2px;
          }

          &-text {
            color: var(--purple, #4d33ef);
            font-family: PingFang SC;
            font-size: 11px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            /* 181.818% */
          }
        }
      }
    }
  }

  .CollectionSynthesis-footer {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 18px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    background-color: #fff;
  }

  .CollectionSynthesis-center-notice {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
    &-checkbox {
      margin-left: 6px;
      border: none;
    }
    /* 设置未选中样式 */
    input[type='checkbox'] {
      position: relative;
      width: 13px;
      height: 13px;
      line-height: 13px;
      border: 1px solid #949494;
      border-radius: 4px;
      -webkit-appearance: none;
    }

    /* 设置选中样式 */
    input[type='checkbox']:checked {
      background-color: #e1e1e1;
      border-radius: 4px;
      border: none;
    }

    input[type='checkbox']:checked::after {
      content: '✓';
      position: absolute;
      top: 0;
      left: 1px;
      width: 13px;
      height: 13px;
      color: #fff;
      text-align: center;
    }

    &-p {
      margin-left: 4px;
      color: #999;
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;

      .CollectionSynthesis-center-notice-p-a {
        color: var(--purple, #4d33ef);
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }
    }
  }

  .CollectionSynthesis-btn {
    width: 100%;
    height: 52px;
    border-radius: 12px;
    background: #000;
    color: #f5f8fa;
    font-family: PingFang SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    line-height: 52px;
    /* 19.2px */
  }

  //确认合成弹窗
  .CollectionSynthesis-CenterPopup {
    height: 238px;

    &-h3 {
      padding-top: 32px;
      // border: 1px solid red;
      color: var(--text-icon-font-gy-190, rgba(0, 0, 0, 0.9));
      text-align: center;
      font-family: PingFang SC;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      /* 144.444% */
    }

    &-p {
      width: 262px;
      height: 72px;
      padding-top: 8px;
      // border: 1px solid red;
      margin: 0 auto;
      color: #999;
      text-align: center;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 150% */
    }

    &-btnBox {
      // border: 1px solid red;
      &-btn {
        margin: 0 auto;
        margin-top: 24px;
        width: 263px;
        height: 52px;
        border-radius: 12px;
        border: 1px solid #000;
        background: var(--black, #1d1d1f);
        color: var(--text-icon-font-wh-1100, #fff);
        text-align: center;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 52px;
        /* 150% */
      }
    }
  }

  //更换编号弹窗
  .CollectionSynthesis-Popup {
    // border: 1px solid red;
    display: grid;
    justify-content: space-between;
    grid-template-columns: 32% 32% 32%;

    &-box2 {
      width: 28.5vw;
      height: 50px;
      box-sizing: border-box;
      border-radius: 12px;
      background: var(--grey_bg, #f6f6f6);
      position: relative;
      text-align: center;
      line-height: 50px;
      margin-top: 8px;
      margin-right: 8px;
      padding: 0 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &-text {
        width: 44px;
        height: 17px;
        border-radius: 0px 4px;
        background: #333;
        color: #fff;
        font-family: 'MiSans-Demibold';
        font-size: 10px;
        font-style: normal;
        text-align: center;
        font-weight: 400;
        line-height: 17px;
        position: absolute;
        right: 0;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  //替换藏品弹窗
  .CollectionSynthesis-boot {
    &-box {
      padding: 22px 14px 22px 14px;
      margin-bottom: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 12px;
      background: var(--grey_bg, #f6f6f6);

      &-left {
        display: flex;

        &-mainImage {
          width: 52px;
          height: 52px;
          border-radius: 12px;
          object-fit: cover;
        }
        // border: 1px solid red;
        &-text {
          margin-left: 14px;
          color: #999;
          font-family: 'MiSans-Demibold';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

          &-h3 {
            color: var(--black, #1d1d1f);
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 8px;
          }
        }
      }

      &-right {
        width: 70px;
        height: 29px;
        display: flex;
        align-items: center;
        border-radius: 8px;
        background: var(--black, #1d1d1f);
        color: var(--fff, #fff);
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        &-img {
          margin-left: 14px;
          margin-right: 4px;
        }
      }
    }
  }
}
