#CastingStatus {
  width: 100%;
  height: 100vh;
  padding-top: 72px;
  box-sizing: border-box;

  .noClass {
    display: none;
  }

  .CastingStatus-title {

    &-img {
      margin-left: 38%;
    }

    &-h3 {
      margin-top: 12px;
      color: var(--black, #1d1d1f);
      text-align: center;
      font-family: PingFang SC;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      /* 28.8px */
    }
  }

  .CastingStatus-title2 {
    margin-top: 102px;
    // border: 1px solid red;

    &-img {
      margin-left: 43%;
    }

    &-h3 {
      margin-top: 12px;
      color: var(--black, #1d1d1f);
      text-align: center;
      font-family: PingFang SC;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      /* 28.8px */
    }
  }

  .CastingStatus-center {
    // border: 1px solid red;
    margin-top: 18px;
    margin-left: 14px;
    margin-right: 14px;
    border-radius: 12px;
    background: var(--grey_bg, #f6f6f6);

    &-box {
      padding: 12px;

      &-top {
        // border: 1px solid red;
        display: flex;
        padding-bottom: 12px;
        border-bottom: 1px solid #e1e1e1;

        &-img {
          width: 70px;
          height: 70px;
          border-radius: 12px;
        }

        &-right {
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          &-title {
            color: var(--black, #1d1d1f);
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 19.2px */
          }

          &-bottom {
            display: flex;
            margin-top: 18px;

            &-box {
              display: flex;
              width: 80px;
              margin-right: 8px;

              &-left {
                width: 36px;
                height: 20px;
                border-radius: 4px 0px 0px 4px;
                background: var(--black, #1d1d1f);
                color: var(--fff, #fff);
                text-align: center;
                font-family: PingFang SC;
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                /* 160% */
              }

              &-right {
                width: 44px;
                height: 20px;
                border-radius: 0px 2px 2px 0px;
                background: var(--fff, #fff);
                color: var(--black, #1d1d1f);
                text-align: center;
                font-family: PingFang SC;
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                /* 160% */
              }
            }
          }
        }
      }

      &-foot {
        // border: 1px solid blueviolet;
        margin-top: 12px;

        &-h4 {
          color: var(--black, #1d1d1f);
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          /* 19.2px */
        }

        &-text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 6px;
          color: #999;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 175%;
          /* 24.5px */

          &-right {
            text-align: right;

            &-num {
              color: var(--purple, #4d33ef);
              font-family: PingFang SC;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 175%;
            }
          }
        }
      }
    }
  }

  .CastingStatus-btnBox {
    margin-left: 14px;
    margin-right: 14px;
    border-radius: 12px;
    background: #000;
    padding: 16px;
    color: #f5f8fa;
    font-family: PingFang SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-align: center;
    margin-top: 18px;
    /* 19.2px */
  }
}
