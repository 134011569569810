#IncreaseByTransferring {
  width: 100%;
  height: 100vh;
  padding-top: 62px;
  box-sizing: border-box;

  .noneclass {
    display: none;
  }

  .IncreaseByTransferring-box {
    width: 347px;
    // border: 1px solid red;
    margin: 0 auto;

    .IncreaseByTransferring-top {
      width: 347px;
      height: 127px;

      .IncreaseByTransferring-top-img {
        width: 98px;
        height: 86px;
        margin: 0 auto;
        background: url('../../../assets/imgs/IncreaseByTransferring-ok.png');
        background-size: 98px 86px;
        background-repeat: no-repeat;
        background-position: center;
      }

      .IncreaseByTransferring-top-img2 {
        width: 98px;
        height: 86px;
        margin: 0 auto;
        background: url('../../../assets/imgs/IncreaseByTransferring-no.png');
        background-size: 98px 86px;
        background-repeat: no-repeat;
        background-position: center;
      }

      .IncreaseByTransferring-top-h3 {
        width: 270px;
        height: 29px;
        color: #1D1D1F;
        text-align: center;
        font-family: PingFang SC;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        margin: 0 auto;
        margin-top: 10px;
      }
    }

    .IncreaseByTransferring-center {
      // margin-left: 14px;
      width: 347px;
      margin-top: 18px;
      border-radius: 14px;
      background: var(--grey_bg, #F6F6F6);
      padding-bottom: 10px;

      .IncreaseByTransferring-center-top {
        width: 319px;
        height: 78px;
        margin: 0 auto;
        margin-top: 14px;
        padding-top: 12px;
        display: flex;

        .IncreaseByTransferring-center-top-imgbox {

          .IncreaseByTransferring-top-left-logo-img {
            width: 78px;
            height: 78px;
            border-radius: 12px;
            object-fit: cover;
            background: #fff;
          }
        }

        .IncreaseByTransferring-center-top-h {
          display: flex;
          flex-direction: column;
          font-size: 18px;
          color: var(--black, #1D1D1F);
          font-family: PingFang SC;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          width: 230px;
          height: 22px;
          line-height: 22px;
          margin-left: 12px;

          &-top {
            display: flex;
          }

          .GiveNft-top-right-center {
            display: flex;
            align-items: center;
            color: #999;
            font-family: 'MiSans-Demibold';
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            margin-top: 8px;
            /* 14.4px */

            &-text {
              margin-left: 4px;
            }
          }

          .box3 {
            display: flex;
            align-items: center;
            margin-top: 8px;

            &-item {
              display: flex;
              align-items: center;
              margin-right: 8px;

              &-text1 {
                background: #000;
                border-radius: 4px 0px 0px 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 4px 8px;
                font-weight: 700;
                font-size: 10px;
                color: #fff;
                height: 16px;
              }

              &-text2 {
                background: #fff;
                height: 100%;
                border-radius: 0px 4px 4px 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 4px 8px;
                font-size: 10px;
                font-family: ''MiSans-Demibold'-Demibold';
                color: #000;
                height: 16px;
              }
            }
          }

          .IncreaseByTransferring-center-top-h-num {
            font-size: 14px;
            height: 17px;
            width: 29px;

            .IncreaseByTransferring-zero {
              text-align: right;
              margin-right: 4px;
            }

            .IncreaseByTransferring-twenty-four {
              color: #1D1D1F;
            }

            .noneIncreaseByTransferring-twenty-four {
              color: #ccc;
            }

          }
        }
      }

      .IncreaseByTransferring-center-id {
        width: 319px;
        height: 56px;
        border-bottom: 1px solid #ccc;
        margin: 0 auto;
        margin-top: 14px;
        line-height: 23px;
        display: flex;
        justify-content: space-between;
        color: #999;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 500;

        .IncreaseByTransferring-center-id-num {
          color: var(--black, #1D1D1F);
          font-family: 'MiSans-Demibold';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
        }

        .IncreaseByTransferring-center-id-right-text {
          text-align: right;
        }
      }

      .IncreaseByTransferring-center-id-center {
        margin-top: 10px;
      }

      .IncreaseByTransferring-center-serviceCharge {
        width: 319px;
        height: 56px;
        margin: 0 auto;
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        color: #1D1D1F;
        border-bottom: 1px solid #ccc;

        .IncreaseByTransferring-center-serviceCharge-f {
          line-height: 24px;
        }

        .IncreaseByTransferring-center-serviceCharge-y {
          margin-top: 28px;
        }
      }

      .IncreaseByTransferring-center-additional {
        color: var(--black, #1D1D1F);
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        margin-top: 14px;
        margin-left: 14px;
      }

      .IncreaseByTransferring-center-record {
        width: 319px;
        display: flex;
        justify-content: space-between;
        margin-top: 4px;
        margin-left: 14px;
        color: #999;
        line-height: 24px;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;

        .IncreaseByTransferring-center-record-right {
          text-align: right;

          .IncreaseByTransferring-center-record-right-text {
            color: #4D33EF;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 175%;
          }
        }
      }
    }

    .IncreaseByTransferring-btn {
      width: 347px;
      height: 52px;
      flex-shrink: 0;
      border-radius: 12px;
      background: var(--black, #1D1D1F);
      color: #FFF;
      font-size: 16px;
      text-align: center;
      line-height: 52px;
      font-weight: 600;
      margin-top: 18px;
    }
  }

  @mixin a {
    width: 36px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    font-weight: 700;
    font-size: 11px;
    color: #FFF6F4;
  }

  #S {
    @include a;
    background: radial-gradient(42.61% 55.51% at 60.46% 100%, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(58.56% 126.24% at 31.37% 0%, rgba(255, 255, 255, 0.46) 0%, rgba(255, 255, 255, 0) 77.6%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(74.14% 74.14% at 102.47% 39.73%, #8FE666 0%, rgba(143, 229, 102, 0) 77.6%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(88.4% 88.4% at 86.12% 6.46%, #72E98A 0%, rgba(114, 233, 138, 0) 56.56%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(73.57% 73.57% at 0% 67.49%, #0D7EFF 0%, rgba(86, 102, 239, 0) 69.33%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(124.52% 124.52% at -3.99% 35.36%, #00D1FF 0%, rgba(0, 209, 255, 0) 69.33%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , #D0E0F3;
    box-shadow: inset 0px 0px 18px rgba(255, 255, 255, 0.82);
    text-shadow: 0px 2px 4px #0060C2;
  }

  #SR {
    @include a;
    background: radial-gradient(71.61% 74.14% at 98.97% 39.73%, #B566E6 0%, rgba(181, 102, 230, 0) 77.6%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(85.38% 88.4% at 83.18% 6.46%, #FF1C89 0%, rgba(229, 102, 163, 0) 56.56%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(71.06% 73.57% at 0% 67.49%, #5666EF 0%, rgba(86, 102, 239, 0) 69.33%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , radial-gradient(120.27% 124.52% at -3.86% 35.36%, #0066FF 0%, rgba(0, 78, 255, 0) 69.33%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      , #8A42FF;
    box-shadow: inset 0px 0px 18px rgba(255, 255, 255, 0.82);
    text-shadow: 0px 2px 4px rgba(0, 38, 134, 0.73);
  }

  #SSR {
    @include a;
    background: linear-gradient(253.23deg, rgba(255, 229, 87, 0.2) 6.01%, rgba(255, 216, 0, 0.2) 87.5%), #FF9900;
    box-shadow: inset 0px 0px 18px rgba(255, 255, 255, 0.82);
    text-shadow: 0px 2px 4px #C58200;
  }

  #UR {
    @include a;
    background: #F44B28;
    box-shadow: inset 0px 4px 15px rgba(162, 46, 46, 0.8);
    text-shadow: 0px 2px 3px #9E1A07;
  }

}