#AllNumbers {
    width: 100%;
    min-height: 100vh;
    padding-top: 76px;
    box-sizing: border-box;

    .AllNumbers-box {
        margin-left: 14px;
        margin-right: 14px;
        // border: 1px solid red;

        .AllNumbers-box-center {
            margin-top: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 0.5px solid var(--e1e1e1, #E1E1E1);

            .AllNumbers-box-center-left {
                color: #333;
                font-family: 'MiSans-Demibold';
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%;

                .AllNumbers-box-center-left-p {
                    color: #000;
                    margin-top: 6px;
                    font-family: 'MiSans-Demibold';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 120%;

                    /* 14.4px */
                    .AllNumbers-box-center-left-p-span {
                        color: #999;
                        font-family: 'MiSans-Demibold';
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 120%;
                    }
                }
            }

            .AllNumbers-box-center-right {
                color: #333;
                font-family: 'MiSans-Demibold';
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%;

                .AllNumbers-box-center-right-span {
                    color: #333;
                    text-align: right;
                    font-family: 'MiSans-Demibold';
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 120%;
                    margin-right: 4px;
                    /* 12px */
                }
            }
        }
    }
}