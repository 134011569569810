#WantToBuy {
  width: 100%;
  min-height: 100vh;
  background-color: #f3f3f3;
  padding-top: 62px;


  .WantToBuy-box {
    width: 100%;
    border: 1px solid #f3f3f3;

    .WantToBuy-box-title {
      // width: 100%;
      margin-top: 14px;
      margin-left: 14px;
      margin-right: 14px;
      // border: 1px solid blue;
      border-radius: 12px;
      background: var(--fff, #FFF);

      .WantToBuy-box-title-top {
        display: flex;
        align-items: center;
        padding-left: 14px;
        padding-top: 22px;

        img {
          border-radius: 4px;
          object-fit: cover;
        }

        .WantToBuy-box-title-top-right {
          padding-left: 14px;

          .WantToBuy-box-title-top-right-h4 {
            color: var(--black, #1D1D1F);
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          .title-top-right-div {
            color: #666;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            display: flex;
            align-items: center;
            margin-top: 10px;
            // border: 1px solid red;

            /* 16.8px */
            .title-top-right-div-span {
              color: var(--black, #1D1D1F);
              font-family: 'MiSans-Demibold';
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%;
            }

            .title-top-right-div-img {
              padding-left: 4px;
            }
          }
        }
      }

      .WantToBuy-box-title-center {
        width: 100%;
        height: 42px;
        // border: 1px solid red;
        padding-left: 14px;
        margin-top: 14px;
        color: var(--black, #1D1D1F);
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        // border: 1px solid red;

        .WantToBuy-box-title-center-input {
          width: 90%;
          height: 42px;
          border: none;
          outline: none;
          color: var(--black, #1D1D1F);
          font-family: 'MiSans-Demibold';
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-left: 12px;
        }

      }

      .WantToBuy-box-title-text {
        // width: 319px;
        width: 90%;
        margin-left: 14px;
        // padding-left: 14px;
        // border: 1px solid blue;
        height: 45px;
        line-height: 45px;
        color: var(--333, #333);
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        border-top: 1px solid #E1E1E1;
        border-bottom: 1px solid #E1E1E1;

        .WantToBuy-box-title-text-span {
          color: var(--purple, #4D33EF);
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .WantToBuy-box-title-foot {
        // border: 1px solid red;
        padding-bottom: 22px;
        padding-left: 14px;
        height: 32px;
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--black, #1D1D1F);
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        .WantToBuy-box-title-foot-right {
          margin-right: 26px;
          display: flex;

          .WantToBuy-box-title-foot-right-btn {
            width: 32px;
            height: 32px;
            border-radius: 8px;
            border: 1px solid #000;
            background: #FFF;
            font-size: 16px;
            text-align: center;
            line-height: 32px;
            // margin-right: 18px;
          }

          .WantToBuy-box-title-foot-right-input {
            width: 36px;
            border: none;
            outline: none;
            color: var(--black, #1D1D1F);
            text-align: center;
            font-family: 'MiSans-Demibold';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            /* 19.2px */
            margin-left: 8px;
            margin-right: 8px;
          }

          .WantToBuy-box-title-foot-right-btn2 {
            width: 32px;
            height: 32px;
            border-radius: 8px;
            border: 1px solid #000;
            background: #FFF;
            font-size: 16px;
            text-align: center;
            line-height: 32px;
            // margin-right: 48px;
          }

        }
      }

    }

    .WantToBuy-collection {
      height: 195px;
      // width: 100%;
      margin-left: 14px;
      margin-right: 14px;
      margin-top: 14px;
      background-color: #FFF;
      border-radius: 12px;
      background: var(--fff, #FFF);

      .WantToBuy-collection-h4 {
        padding-top: 22px;
        padding-left: 14px;
        color: var(--333, #333);
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 16.8px */
      }

      .WantToBuy-collection-wallet-sd-box {
        height: 56px;
        padding-left: 14px;
        padding-right: 14px;
        border-radius: 4px;
        margin-top: 14px;

        .WantToBuy-collection-wallet-sd {
          height: 56px;
          padding-left: 14px;
          padding-right: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 12px;
          background: var(--grey_bg, #F6F6F6);
          // border: 1px solid red;

          .WantToBuy-collection-wallet-sd-left {
            display: flex;
            align-items: center;

            .WantToBuy-collection-wallet-sd-left-text {
              color: var(--black, #1D1D1F);
              text-align: center;
              font-family: PingFang SC;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin-left: 8px;
            }
          }

          .WantToBuy-collection-wallet-sd-left-checkbox {
            position: relative;
            background-color: #000;

            .circle-checkbox {
              display: inline-block;
              position: relative;
              cursor: pointer;
            }

            .circle-checkbox__input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              z-index: 1;
              border-radius: 50%;
              width: 24px;
              height: 24px;
            }

            .circle-checkbox__custom {
              position: absolute;
              bottom: -10px;
              left: -20px;
              height: 24px;
              width: 24px;
              background-color: #F6F6F6;
              border-radius: 50%;
              transition: background-color 200ms;
              box-shadow: inset 0 0 0 2px #dcdcdc;
            }

            /* 当checkbox选中时，改变自定义圆形的样式 */
            .circle-checkbox__input:checked~.circle-checkbox__custom {
              background-color: #F6F6F6;
              box-shadow: inset 0 0 0 2px #AAB8C2;
            }

            /* 增加一个选中时的对号 */
            .circle-checkbox__input:checked~.circle-checkbox__custom:after {
              content: '';
              position: absolute;
              left: 8px;
              top: 0px;
              width: 8px;
              height: 16px;
              border: solid #000;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }
          }


        }
      }

      .WantToBuy-collection-wallet-hf-box {
        height: 56px;
        padding-left: 14px;
        padding-right: 14px;
        border-radius: 4px;
        margin-top: 14px;

        .WantToBuy-collection-wallet-hf {
          height: 56px;
          padding-left: 14px;
          padding-right: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 12px;
          background: var(--grey_bg, #F6F6F6);
          // border: 1px solid red;

          .WantToBuy-collection-wallet-hf-left {
            display: flex;
            align-items: center;

            .WantToBuy-collection-wallet-hf-left-text {
              color: var(--black, #1D1D1F);
              text-align: center;
              font-family: PingFang SC;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin-left: 8px;
            }
          }

          .WantToBuy-collection-wallet-hf-right {
            display: flex;
            color: var(--purple, #4D33EF);
            text-align: right;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 16.8px */
          }
        }
      }


    }

    .WantToBuy-foot {
      // width: 100%
      background-color: #fff;
      margin-left: 14px;
      margin-right: 14px;
      margin-top: 14px;
      border-radius: 12px;
      background: var(--fff, #FFF);

      .WantToBuy-foot-box {
        padding: 22px 14px 22px 14px;

        .WantToBuy-foot-box-h3 {
          height: 30px;
          line-height: 30px;
          // border: 1px solid red;
          padding-bottom: 10px;
          border-bottom: 1px solid #E1E1E1;
        }

        .WantToBuy-foot-box-div {
          margin-top: 14px;
          color: #999;
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          /* 183.333% */
          letter-spacing: 0.258px;
        }
      }
    }

    .WantToBuy-btn {
      width: 100%;
      height: 108px;
      position: fixed;
      bottom: 0;
      z-index: 999;
      // border: 1px solid red;
      background: rgba(255, 255, 255, 0.88);
      backdrop-filter: blur(2px);

      .WantToBuy-btn-box {
        // border: 1px solid red;
        padding-left: 14px;
        padding-top: 28px;
        padding-right: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;


        .WantToBuy-btn-box-left {
          width: 95px;
          height: 47px;
          // border: 1px solid red;
          color: #999;
          font-family: 'MiSans-Demibold';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;

          /* 14.4px */
          .WantToBuy-btn-box-left-div {
            color: var(--black, #1D1D1F);
            font-family: 'MiSans-Demibold';
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            margin-top: 4px;
          }

          .WantToBuy-btn-box-left-div-span {
            color: var(--black, #1D1D1F);
            text-align: center;
            font-family: 'MiSans-Demibold';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            margin-right: 10px;
            /* 16.8px */
          }
        }

        .WantToBuy-btn-box-right {
          width: 180px;
          height: 52px;
          border-radius: 12px;
          background: #000;
          text-align: center;
          line-height: 52px;
          color: #F5F8FA;
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          /* 19.2px */
        }
      }
    }

  }

  .Popup-top-center {
    background-color: #fff;
    margin: 14px;
    border-radius: 12px;
    padding: 14px;

    .Popup-h4 {
      margin-bottom: 8px;
      color: var(--333, #333);
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      margin-left: 10px;
      /* 16.8px */
    }

    .Popup-wallet-sd {
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 12px;
      background: var(--grey_bg, #F6F6F6);
      padding: 0 12px;

      .Popup-wallet-sd-left {
        display: flex;

        .Popup-wallet-sd-left-img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }

        .Popup-wallet-sd-left-text {
          color: var(--black, #1D1D1F);
          text-align: center;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .Popup-wallet-hf-right {
        width: 75px;
        // border: 1px solid red;
        display: flex;
        justify-content: space-between;
        color: var(--purple, #4D33EF);
        text-align: right;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 16.8px */
      }

      .Popup-wallet-sd-left-checkbox {
        position: relative;
      }

      .Checkbox:checked+label:after {
        content: "";
        position: absolute;
        left: 2px;
        top: 2px;
        width: 9px;
        height: 4px;
        border: 2px solid #424242;
        border-top-color: transparent;
        border-right-color: transparent;
        transform: rotate(-45deg);
        -ms-transform: rotate(-60deg);
        -moz-transform: rotate(-60deg);
        -webkit-transform: rotate(-60deg);
      }
    }

    .Popup-wallet-hf {
      height: 56px;
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 12px;
      background: var(--grey_bg, #F6F6F6);
      padding: 0 14px;

      .Popup-wallet-hf-left {
        display: flex;

        .Popup-wallet-hf-left-img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }

        .Popup-wallet-hf-left-text {
          color: var(--black, #1D1D1F);
          text-align: center;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .Popup-wallet-hf-right {
        width: 75px;
        // border: 1px solid red;
        display: flex;
        justify-content: space-between;
        color: var(--purple, #4D33EF);
        text-align: right;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 16.8px */
      }

    }
  }
}