#MyGem {
  width: 100%;
  height: 100vh;
  padding-top: 61px;
  box-sizing: border-box;
  overflow: auto;

  .adm-nav-bar-title {
    background-color: black;
    color: #fff;
    font-size: 17px;
  }

  .MyGem-center {
    width: 100%;
    height: 171px;
    background-color: #010206;
    display: flex;
    justify-content: space-around;
  }

  .MyGem-left {
    background-color: black;
    width: 197px;
    // border: 1px solid red;
  }

  .MyGem-my {
    width: 84px;
    height: 20px;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    margin-left: 15px;
    font-size: 14px;
    font-family: 'MiSans-Demibold';
    color: #fff;

  }

  .MyGem-my img {
    width: 22px;
    height: 22px;
  }

  .MyGem-time {
    margin-top: 12px;
    margin-left: 15px;
    font-weight: 900;
    font-family: 'MiSans-Demibold';
    font-size: 22px;
    font-style: normal;
    color: #fff;
  }

  .MyGem-btn {
    margin-top: 24px;
    width: 192px;
    height: 34px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    font-size: 14px;
  }

  .MyGem-subscribe {
    width: 92px;
    height: 34px;
    line-height: 34px;
    border-radius: 12px;
    margin-left: 15px;
    background: radial-gradient(76.16% 76.16% at 31.97% 19.67%, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #FF602E;
    color: #fff;
  }

  .MyGem-gift {
    width: 90px;
    height: 32px;
    line-height: 34px;
    margin-left: 8px;
    border-radius: 12px;
    border: 2px solid rgba(255, 255, 255, 0.08);
    background: #1C1E25;
    color: #fff;
  }

  .MyGem-img {
    margin-top: 14px;
    width: 45%;
    height: 150px;
    background: url('../../../assets/imgs/MyGem-bg.png') no-repeat;
    background-size: 100%;
  }

  .MyGem-foot {
    display: flex;
    height: 66px;
    margin: 0 auto;
    padding: 0 14px;

    .IncreaseByTransferring-top {
      margin-top: 62px;
      margin-left: 14px;
      width: 347px;
      height: 127px;

      .IncreaseByTransferring-top-img {
        width: 98px;
        height: 86px;
        margin: 0 auto;
        background: url('../../../assets/imgs/IncreaseByTransferring-ok.png');
        background-size: 98px 86px;
        background-repeat: no-repeat;
        background-position: center;
      }

      .IncreaseByTransferring-top-img2 {
        width: 98px;
        height: 86px;
        margin: 0 auto;
        background: url('../../../assets/imgs/IncreaseByTransferring-no.png');
        background-size: 98px 86px;
        background-repeat: no-repeat;
        background-position: center;
      }

      .IncreaseByTransferring-top-h3 {
        width: 270px;
        height: 29px;
        color: #1D1D1F;
        text-align: center;
        font-family: PingFang SC;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        margin: 0 auto;
        margin-top: 10px;
      }
    }

    .IncreaseByTransferring-center {
      margin-left: 14px;
      width: 347px;
      height: 380px;
      margin-top: 18px;
      border-radius: 14px;
      background: var(--grey_bg, #F6F6F6);

      .IncreaseByTransferring-center-top {
        width: 319px;
        height: 78px;
        margin: 0 auto;
        margin-top: 14px;
        padding-top: 12px;
        display: flex;

        .IncreaseByTransferring-center-top-imgbox {
          width: 78px;
          height: 78px;
          border-radius: 12px;
          background: #FFF;

          .IncreaseByTransferring-top-left-logo-img {
            width: 40px;
            height: 40px;
            margin: 20px;
          }
        }

        .IncreaseByTransferring-center-top-h {
          display: flex;
          font-size: 18px;
          color: var(--black, #1D1D1F);
          font-family: PingFang SC;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          width: 230px;
          height: 22px;
          line-height: 22px;
          margin-left: 12px;

          .IncreaseByTransferring-center-top-h-num {
            font-size: 14px;
            height: 17px;
            width: 29px;
            margin-left: 127px;

            .IncreaseByTransferring-zero {
              text-align: right;
              margin-right: 4px;
            }

            .IncreaseByTransferring-twenty-four {
              color: #1D1D1F;
            }

            .noneIncreaseByTransferring-twenty-four {
              color: #ccc;
            }

          }
        }
      }

      .IncreaseByTransferring-center-id {
        width: 319px;
        height: 56px;
        border-bottom: 1px solid #ccc;
        margin: 0 auto;
        margin-top: 14px;
        line-height: 23px;
        display: flex;
        justify-content: space-between;
        color: #999;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 500;

        .IncreaseByTransferring-center-id-num {
          color: var(--black, #1D1D1F);
          font-family: 'MiSans-Demibold';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
        }

        .IncreaseByTransferring-center-id-right-text {
          text-align: right;
        }
      }

      .IncreaseByTransferring-center-id-center {
        margin-top: 10px;
      }

      .IncreaseByTransferring-center-serviceCharge {
        width: 319px;
        height: 56px;
        margin: 0 auto;
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        color: #1D1D1F;
        border-bottom: 1px solid #ccc;

        .IncreaseByTransferring-center-serviceCharge-f {
          line-height: 24px;
        }

        .IncreaseByTransferring-center-serviceCharge-y {
          margin-top: 28px;
        }
      }

      .IncreaseByTransferring-center-additional {
        color: var(--black, #1D1D1F);
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        margin-top: 14px;
        margin-left: 14px;
      }

      .IncreaseByTransferring-center-record {
        width: 319px;
        display: flex;
        justify-content: space-between;
        margin-top: 4px;
        margin-left: 14px;
        color: #999;
        line-height: 24px;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;

        .IncreaseByTransferring-center-record-right {
          text-align: right;

          .IncreaseByTransferring-center-record-right-text {
            color: #4D33EF;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 175%;
          }
        }
      }
    }

    .IncreaseByTransferring-btn {
      width: 347px;
      height: 52px;
      flex-shrink: 0;
      border-radius: 12px;
      background: var(--black, #1D1D1F);
      color: #FFF;
      font-size: 16px;
      text-align: center;
      line-height: 52px;
      font-weight: 600;
      margin-left: 14px;
      margin-top: 18px;
    }


    .MyGem-foot-top {
      width: 100%;
      height: 66px;
      line-height: 66px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      margin: 0 auto;
    }

    .MyGem-font {
      width: 36px;
      height: 22px;
      font-size: 18px;
      color: #999;
      margin-right: 32px;
    }
  }


  .MyGem-foot-obtain {
    height: 41px;
    margin: 18px 14px 0;
    padding-bottom: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: space-between;

    .MyGem-foot-obtain-left {
      height: 41px;
    }

    .MyGem-foot-obtain-left p {
      height: 14px;
      margin-top: 10px;
      font-size: 12px;
      color: var(--black, #1D1D1F);
      ;
    }

    .MyGem-foot-obtain-right {
      // width: 52px;
      height: 19px;
      line-height: 41px;
      font-weight: 600;
      font-size: 16px;
      padding-bottom: 14px;
    }

    .MyGem-foot-obtain-right span {
      margin-right: 5px;
    }
  }


}