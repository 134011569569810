#MyAssets {
  min-height: 100vh;
  padding: 12px;
  padding-top: 90px;
  box-sizing: border-box;
  position: relative;

  .MyAssets-box1 {
    display: flex;
    align-items: center;
    position: fixed;
    width: 95%;
    top: 53px;
    left: 0;
    right: 0;
    background-color: #fff;
    justify-content: space-between;
    margin: auto;
    padding: 20px 0 10px;
    height: 20px;
    z-index: 1;

    &-text {
      color: #333;
      font-family: 'MiSans-Demibold';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      margin-right: 25px;
    }
  }

  .MyAssets-box2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 8px;

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;
      width: 45%;
      border-radius: 12px;
      background: var(--grey_bg, #F6F6F6);
      box-sizing: border-box;
      padding: 12px;
      margin-top: 16px;
      position: relative;

      &-grade {
        position: absolute;
        top: 21px;
        left: 12px;
      }

      &-img {
        border-radius: 12px;
        object-fit: contain;
        height: 146px;
      }

      &-text {
        color: var(--black, #1D1D1F);
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        margin-top: 7px;
        /* 21px */
      }

      &-bittom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 12.5px;

        &-text1 {
          display: flex;
          align-items: center;
          color: var(--black, #1D1D1F);
          text-align: center;
          font-family: 'MiSans-Demibold';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;

          img {
            margin-right: 4px;
          }
        }

        &-text2 {
          color: var(--gray);
          text-align: center;
          font-family: PingFang SC;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          /* 12px */
        }
      }
    }
  }

  .MyAssets-Popup {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    &-title {
      color: #333;
      font-family: PingFang SC;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      /* 26.4px */
    }

    &-text {
      color: var(--gray);
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      margin-top: 8px;
      /* 24px */
    }

    &-box {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 32% 32% 32%;
      margin-top: 8px;


      &-item {
        display: flex;
        padding: 16px 0px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        color: var(--black, #1D1D1F);
        font-family: 'MiSans-Demibold';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        border-radius: 12px;
        background: var(--grey_bg, #F6F6F6);
        mix-blend-mode: darken;
        box-sizing: border-box;
        margin-top: 8px;
        position: relative;
        /* 16.8px */

        &-title {
          border-radius: 0px 4px;
          background: var(--purple, #4D33EF);
          color: var(--fff, #FFF);
          font-family: 'MiSans-Demibold';
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          display: flex;
          padding: 0 4px;
          height: 17px;
          justify-content: center;
          align-items: center;
          gap: 12px;
          position: absolute;
          right: 0;
          top: 0;
          /* 12px */
        }
      }
    }
  }
}