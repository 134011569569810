#AlchemyInstitute {
  height: 100vh;
  padding-top: 62px;
  box-sizing: border-box;
  overflow-y: auto;

  .AlchemyInstitute-top {
    background: #000;
    color: #FFF;
    height: 198px;
    border: 1px solid #000;

    .AlchemyInstitute-top-left {
      width: 90%;
      height: 78px;
      padding-top: 20px;
      padding-left: 13px;
      margin-top: 20px;
      // border: 1px solid blue;
      background: url(../../../assets/imgs/AlchemyInstitute-Bank.png) no-repeat right;
      background-size: 130px;

      .AlchemyInstitute-top-left-p {
        width: 146px;
        height: 22px;
        font-family: 'MiSans-Demibold';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }

      .AlchemyInstitute-top-left-h3 {
        width: 132px;
        height: 22px;
        // color: #FFF;
        margin-top: 12px;
        font-family: 'MiSans-Demibold';
        font-size: 22px;
        font-style: normal;
        font-weight: 900;
        line-height: 22px;
      }
    }

    .AlchemyInstitute-top-gold {
      height: 65px;
      display: flex;
      padding: 16px 16px 0;
      border-radius: 12px;
      background: #191A1E;
      justify-content: space-between;
      margin: 0 15px;

      .AlchemyInstitute-top-gold-left {
        width: 178px;
        height: 48px;
        // border: 1px solid blue;

        .AlchemyInstitute-top-gold-left-p {
          color: #999;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .AlchemyInstitute-top-gold-left-img {
          display: flex;
          margin-top: 8px;

          .AlchemyInstitute-top-gold-left-img-h3 {
            color: #FFF;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: 'MiSans-Demibold';
            font-size: 18px;
            font-style: normal;
            font-weight: 900;
            line-height: 22px;
            margin-left: 8px;
          }
        }
      }

      .AlchemyInstitute-top-gold-refining {
        width: 97px;
        height: 34px;
        text-align: center;
        line-height: 34px;
        color: #191A1E;
        text-align: center;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        border-radius: 12px;
        margin-top: 8px;
        margin-left: 45px;
        background: radial-gradient(76.16% 76.16% at 31.97% 19.67%, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #F6C100;
        // box-shadow: 4px 38px 62px 0px rgba(0, 0, 0, 0.50), -3px -4px 7px 0px rgba(255, 255, 255, 0.15) inset;
      }
    }
  }

  .AlchemyInstitute-center {
    margin-top: 24px;
    padding: 0 15px;
    display: flex;
    flex-direction: column;

    .AlchemyInstitute-center-title {
      height: 20px;
      padding-bottom: 14px;
      display: flex;
      justify-content: space-between;

      .AlchemyInstitute-center-title-h3 {
        color: var(--black, #1D1D1F);
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
      }

      .AlchemyInstitute-center-title-p {
        color: #666;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
    }

    .AlchemyInstitute-center-all {
      position: relative;

      .AlchemyInstitute-center-box {
        height: 75px;
        // border: 1px solid red;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);
        display: flex;
        justify-content: space-between;

        .AlchemyInstitute-center-box-left {
          margin-top: 20px;

          .AlchemyInstitute-center-box-left-h4 {
            color: #1D1D1F;
            text-align: right;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
          }

          .AlchemyInstitute-center-box-left-p {
            color: #666;
            text-align: right;
            font-family: PingFang SC;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            margin-top: 4px;
          }
        }

        .AlchemyInstitute-center-box-center {
          width: 60px;
          height: 20px;
          display: flex;
          margin-top: 25px;
          margin-right: 12px;

          .AlchemyInstitute-center-box-center-div {
            color: #333;
            text-align: center;
            font-family: 'MiSans-Demibold';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-left: 4px;
          }
        }

        .AlchemyInstitute-center-box-right {
          margin-top: 25px;
          color: #333;
          text-align: right;
          font-family: 'MiSans-Demibold';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }

  }
}