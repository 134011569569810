#NoticeDetails {
  width: 100%;
  min-height: 100vh;
  padding-top: 90px;

  .NoticeDetails-title {
    margin-top: 82px;
    margin-left: 16px;
    margin-right: 16px;
    // border: 1px solid red;
    border-radius: 12px;
    background: var(--grey_bg, #F6F6F6);

    .NoticeDetails-title-box {
      padding: 14px;

      .NoticeDetails-title-box-h3 {
        color: #333;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        /* 21px */
      }

      .NoticeDetails-title-box-p {
        color: #999;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 169%;
        margin-top: 8px;
        margin-bottom: 8px;
        /* 20.28px */
      }

      .NoticeDetails-title-box-text {
        color: #333;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 175%;
        /* 24.5px */
      }

      .NoticeDetails-title-text2 {
        margin-top: 25px;
        color: #333;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 175%;
        /* 24.5px */
      }
    }
  }

  .NoticeDetails-association-h3 {
    // border: 1px solid red;
    margin-top: 14px;
    padding-left: 16px;
    color: var(--black, #1D1D1F);
    font-family: PingFang SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 19.2px */
  }

  .NoticeDetails-association {
    margin-left: 16px;
    margin-right: 21px;
    // border: 1px solid red;
    border-radius: 12px;
    background: var(--grey_bg, #F6F6F6);

    .NoticeDetails-association-box {
      // border: 1px solid red;
      padding: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 14px;

      .NoticeDetails-association-box-left {
        display: flex;
        align-items: center;
        color: var(--black, #1D1D1F);
        text-align: center;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        .NoticeDetails-association-box-left-img {
          border-radius: 14px;
          margin-right: 12px;
        }
      }

      .NoticeDetails-association-box-right {
        display: flex;
        align-items: center;
        color: #999;
        text-align: center;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}