li {
  list-style: none;
}

#Mine {
  background-color: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  min-height: 100vh;
  padding-bottom: 100px;
  box-sizing: border-box;

  main {
    margin-top: 14px;

    ul {
      padding: 3px 23px;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 22px 0;
        position: relative;

        div {
          display: flex;
          align-items: center;
          gap: 6px;

          span {
            font-size: 14px;
          }

          div {
            display: flex;
            align-items: center;

            i {
              width: 6px;
              height: 6px;
              background: #FF5C00;
              border-radius: 50%;
            }

            span {
              font-size: 12px;
              color: #999;
            }
          }
        }

        p {
          display: flex;
          align-items: center;
          gap: 5px;

          span {
            font-size: 12px;
            color: #999;
          }
        }
      }

      li::after {
        content: '';
        height: 1px;
        width: 100%;
        position: absolute;
        bottom: 0;
        transform: scaleY(50%);
        background-color: #f3f3f3;
      }
    }
  }

  .userBox {}

  .marginBottom {
    margin-bottom: 9px;
  }

  .banner {
    display: flex;
    justify-content: space-between;
    margin: 0 25*100vw/750 10px;

    img {
      width: 341*100vw/750;
      height: 165*100vw/750;
    }
  }

  .userCard {
    display: flex;
    flex-direction: column;
    // background: rgba(255, 255, 255, 1);
    // box-shadow: 0px 4*100vw/750 30*100vw/750 0px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    margin: 0 25*100vw/750;
    margin-left: 20px;
    margin-right: 20px;

    .header {
      display: flex;
      justify-content: space-between;
      padding: 28px 0 0px 1px;

      .userInfo {
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        // justify-content: space-between;
        width: 100%;

        .userImg {
          display: inline-block;
          height: 86px;
          width: 86px;
          border-radius: 50%;
          border: .5px solid #f6f6f6;
          box-sizing: border-box;
          object-fit: cover;
        }

        .userInfo-right {
          box-sizing: border-box;
          margin-left: 14px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          // align-items: flex-start;
          .userName {
            font-size: 38*100vw/750;
            color: #333333;
            font-weight: bold;
            font-family: 'Misans-Demibold';
          }

          .userInfo {
            margin-top: 10px;
            color: #999999;
            display: flex;
            align-items: center;

            span {
              display: flex;
              align-items: center;
            }

            i {
              margin-left: 4px;
              font-size: 16px;
            }
          }

        }
      }

      .arrows {
        width: 12*100vw/750;
        // background: url('../../assets/arrow_small_black.png');
        background-size: 100%;
        margin-top: 6.66667vw;
        margin-right: 6*100vw/750;
        background-repeat: no-repeat;
      }
    }

    .myMeony {
      margin: 20px 14px 20px;
      padding: 0 8px;

      .userEquity {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 0;
        justify-content: space-between;

        li {
          list-style: none;
          text-align: center;

          .userText {
            font-size: 12px;
            margin-top: 4px;
            color: #999999;
          }

          .userStatus {
            font-size: 17px;
            font-family: 'Misans-Demibold';
            color: rgba(0, 0, 0, 1);
          }
        }

      }
    }

    .userCard-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-top: 14px;
      padding: 14px;
      background-color: #f6f6f6;
      border-radius: 12px;

      // border: 1px solid #FFF;

      // background: linear-gradient(318deg, rgba(0, 0, 0, 0.10) 0%, rgba(255, 255, 255, 0.20) 105.18%), #f3f3f3;

      // background-blend-mode: soft-light, normal;
      // box-shadow: 5px 5px 10px 0px #A6ABBD32 inset, -5px -5px 10px 0px #FAFBFF42 inset;

      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-top {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 55px;
          // background: rgba(0, 0, 0, 0.06);
          background: #ffffff;
          border: 1px solid #00000006;
          height: 52px;
          width: 52px;
        }

        &-text {
          color: var(--gray);
          font-family: PingFang SC;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
          margin-top: 4px;
          /* 138.462% */
        }
      }
    }

    .Mine-invite {
      height: 100px;
      position: relative;
      width: 100%;
      border-radius: 8px;
      padding: 0 20px;
      overflow: hidden;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      background-image: url('../../../assets/imgs/invit.gif');
      background-size: 100% 100%;

      img {
        position: relative;
        z-index: 1;
      }

      &-text {
        position: relative;
        z-index: 1;
        display: flex;
        padding: 5px 10px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: #FFF;
        color: var(--dark-bg, #000);
        text-align: center;
        font-family: 'MiSans-Demibold';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        /* 133.333% */
        letter-spacing: 0.1px;
      }

      &-video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

        /* 隐藏播放条 */
        &::-webkit-media-controls {
          display: none !important;
        }

        &::-webkit-media-controls-enclosure {
          display: none !important;
        }

        &::-webkit-media-controls-panel {
          display: none !important;
        }

        &::-webkit-media-controls-timeline {
          display: none !important;
        }
      }
    }
  }

  .main {
    padding: 0 15px;
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
    margin: 0 25*100vw/750;


    p {
      font-size: 30*100vw/750;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      line-height: 29*100vw/750;
      padding-top: 13px;
    }

    ul {
      margin-top: 22px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;

      li {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 10px;

        i {
          display: block;

          img {
            height: 22px;
          }
        }

        div {
          font-size: 24*100vw/750;
          font-weight: 500;
          color: rgba(0, 0, 0, 1);
          line-height: 70*100vw/750;
        }
      }
    }
  }

  .Mine-wallet {
    border-radius: 22px 22px 0px 0px;
    background: var(--fff, #FFF);
    width: 100%;
    padding: 10px 0 15px;
    box-sizing: border-box;

    &::before {
      content: '';
      width: 34px;
      height: 4px;
      position: absolute;
      top: 11px;
      left: 0;
      right: 0;
      margin: auto;
      background-color: #DBDBDB;
      border-radius: 16px;
    }


    &-item {
      display: flex;
      padding: 15px;
      align-items: center;
      align-self: stretch;
      border-radius: 12px;
      justify-content: space-between;
      background: var(--grey_bg, #F6F6F6);
      margin-top: 10px;

      &-left {
        box-sizing: border-box;
        display: flex;
        align-items: center;

        &-img {
          width: 45px;
          height: 45px;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
        }

        &-content {
          display: flex;
          flex-direction: column;
          margin-left: 10px;

          &-text1 {
            color: var(--color);
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            /* 125% */
            letter-spacing: 0.22px;
          }

          &-text2 {
            color: #999;
            font-family: PingFang SC;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            /* 133.333% */
            letter-spacing: 0.22px;
            margin-top: 4px;
          }
        }
      }

      &-btn {
        display: flex;
        width: 69px;
        height: 28px;
        padding: 0px 12px;
        justify-content: center;
        align-items: center;
        color: var(--fff, #FFF);
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        border-radius: 8px;
        background: var(--black, #1D1D1F);
        box-sizing: border-box;
        /* 14.4px */
      }
    }
  }
}

.wrapper_inviter {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    position: relative;
    width: 265px;
    height: 268px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: white;
    border-radius: 10px;

    .btn {
      width: 197px;
      height: 45px;
      background: linear-gradient(-90deg, rgba(243, 230, 96, 1), rgba(255, 219, 38, 1));
      box-shadow: 0px 3px 14px 0px rgba(245, 228, 85, 0.31);
      border-radius: 27.5px;
      text-align: center;
      line-height: 45px;
      font-weight: 500;
    }

    span {
      font-size: 15px;
      color: #333333;
      font-weight: 500;
    }

    .input {
      color: #999999;
      font-weight: 500;
      font-size: 15px;
      border-bottom: .5px solid #E1E1E1;
      padding: 10px 15px 12px 15px;
      text-align: center;
    }
  }

}