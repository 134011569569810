#record {
  height: 100vh;
  overflow-y: auto;
  padding: 60px 14px;
  box-sizing: border-box;

  .record-nav {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 20px 0;

    &-text {
      color: #999;
      font-family: PingFang SC;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin-right: 32px;
      /* 21.6px */
    }
  }

  .record-list {
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.10);
      padding: 14px 0;

      &-left {
        display: flex;
        flex-direction: column;
        align-items: baseline;

        &-top {
          color: #1D1D1F;
          font-family: 'MiSans-Demibold';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          display: flex;
          align-items: center;

          /* 16.8px */
          span {
            margin-left: 4px;
            padding: 2px 8px;
            color: #FFF;
            text-align: right;
            font-family: Poppins;
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            /* 12px */
            border-radius: 6px 0px;
            background: var(--redpack, linear-gradient(180deg, #F6463A 0%, #FE7A68 100%));
          }
        }

        &-bottom {
          color: rgba(0, 0, 0, 0.66);
          text-align: right;
          font-family: 'MiSans-Demibold';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          margin-top: 10px;
          /* 14.4px */
        }
      }

      &-right {
        display: flex;
        flex-direction: column;
        align-items: end;

        &-top {
          text-align: right;
          font-family: 'MiSans-Demibold';
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          /* 19.2px */
          display: flex;
          align-items: center;
          color: #F6463A;

          &-text {
            margin-right: 6px;
          }

          &-lose {
            color: #1D1D1F;
          }
        }

        &-bottom {
          color: rgba(0, 0, 0, 0.66);
          text-align: right;
          font-family: 'MiSans-Demibold';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          margin-top: 6px;
          /* 14.4px */
        }
      }
    }
  }
}