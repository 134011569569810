#BusinessCopyright {
  padding: 11px 18px 0;
  background-color: #000;
  min-height: 100vh;
  box-sizing: border-box;

  .BusinessCopyright-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #FFF;

    &-img {
      position: absolute;
      left: -5px;
    }

    &-text {
      color: #FFF;
      text-align: center;
      font-family: PingFang SC;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      /* 129.412% */
    }
  }

  .BusinessCopyright-box1 {
    position: relative;
    padding-bottom: 95px;
    // border: 1px solid blue;

    &-text1 {
      color: #FFF;
      font-family: 'MiSans-Demibold';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
      display: flex;
      align-items: center;
      margin-top: 37px;
      z-index: 1;
      position: relative;

      img {
        margin-left: 8px;
      }
    }

    &-text2 {
      color: #FFF;
      font-family: 'MiSans-Demibold';
      font-size: 26px;
      font-style: normal;
      font-weight: 900;
      line-height: 22px;
      margin-top: 12px;
      display: block;
      z-index: 1;
      position: relative;
      /* 84.615% */
    }

    &-text3 {
      width: 200px;
      color: rgba(255, 255, 255, 0.65);
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      display: block;
      margin-top: 22px;
      margin-bottom: 8px;
      z-index: 1;
      position: relative;
      // border: 1px solid red;
    }

    &-text4 {
      display: flex;
      padding: 12px 18px;
      align-items: center;
      border-radius: 12px;
      border: 1px solid rgba(255, 255, 255, 0.55);
      color: var(--fff, #FFF);
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      position: absolute;
      box-sizing: border-box;
      margin-top: 22px;
      z-index: 1;
      /* 16.8px */
    }

    &-video {
      position: absolute;
      top: -37px;
      right: -161px;
      width: 100%;

      /* 隐藏播放条 */
      &::-webkit-media-controls {
        display: none !important;
      }

      &::-webkit-media-controls-enclosure {
        display: none !important;
      }

      &::-webkit-media-controls-panel {
        display: none !important;
      }

      &::-webkit-media-controls-timeline {
        display: none !important;
      }
    }

    .adm-progress-bar-rounded .adm-progress-bar-trail,
    .adm-progress-bar-rounded .adm-progress-bar-fill {
      z-index: 1;
    }

    .BusinessCopyright-box1-video {
      width: 90%;
      height: 250px;
      // border: 1px solid red;
      z-index: 1;
    }
  }

  .BusinessCopyright-progressBar-box {
    width: 98%;
    height: 80px;
    margin-top: 95px;
    padding-left: 10px;
    border-radius: 12px;
    background-color: #191A1E;
    background: linear-gradient(180deg, #2D2233 0%, #583C77 100%);
    color: #fff;

    .BusinessCopyright-progressBar-box-text {
      width: 200px;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      display: block;
      padding-top: 12px;
      margin-bottom: 8px;
      z-index: 222;
    }
  }

  .BusinessCopyright-box2 {
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 360px;
    right: 0;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 22px 22px 0px 0px;
    bottom: 0px;
    height: 531px;
    // border: 1px solid red;



    .adm-progress-bar-trail {
      margin-bottom: 20px;
    }


    &-text1 {
      color: #FFF;
      font-family: 'MiSans-Demibold';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
      display: flex;
      align-items: center;
      margin-top: 37px;
      z-index: 1;
      position: relative;

      img {
        margin-left: 8px;
      }
    }

    &-text2 {
      color: #FFF;
      font-family: 'MiSans-Demibold';
      font-size: 26px;
      font-style: normal;
      font-weight: 900;
      line-height: 22px;
      margin-top: 12px;
      display: block;
      z-index: 1;
      position: relative;
      /* 84.615% */
    }

    &-text3 {
      width: 178px;
      color: rgba(255, 255, 255, 0.65);
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      display: block;
      margin-top: 22px;
      margin-bottom: 8px;
      z-index: 1;
      position: relative;
      // border: 1px solid red;
    }

    &-text4 {
      display: flex;
      padding: 12px 18px;
      align-items: center;
      border-radius: 12px;
      border: 1px solid rgba(255, 255, 255, 0.55);
      color: var(--fff, #FFF);
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      position: absolute;
      box-sizing: border-box;
      margin-top: 22px;
      z-index: 1;
      /* 16.8px */
    }

    &-video {
      position: absolute;
      top: -37px;
      right: -161px;
      width: 100%;

      /* 隐藏播放条 */
      &::-webkit-media-controls {
        display: none !important;
      }

      &::-webkit-media-controls-enclosure {
        display: none !important;
      }

      &::-webkit-media-controls-panel {
        display: none !important;
      }

      &::-webkit-media-controls-timeline {
        display: none !important;
      }
    }

    .adm-progress-bar-rounded .adm-progress-bar-trail,
    .adm-progress-bar-rounded .adm-progress-bar-fill {
      z-index: 1;
    }

    .BusinessCopyright-box1-video {
      width: 330px;
      height: 250px;
    }

    &-title {
      color: var(--black, #1D1D1F);
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      /* 19.2px */
    }

    &-p {
      color: #666;
      text-align: justify;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 155%;
      margin-top: 14px;
      /* 21.7px */
    }

    .BusinessCopyright-box2 {
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 360px;
      right: 0;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 22px 22px 0px 0px;
      bottom: 0px;



      .adm-progress-bar-trail {
        margin-bottom: 20px;
      }


      &-title {
        color: var(--black, #1D1D1F);
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 19.2px */
      }

      &-p {
        color: #666;
        text-align: justify;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 155%;
        margin-top: 14px;
        /* 21.7px */
      }

      &-img {
        // border: 1px solid red;
        margin-top: 18px;
        margin-bottom: 60px;
      }
    }
  }
}