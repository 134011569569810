#Mining {
  color: #fff;
  padding-bottom: 160px;

  .Mining-box1 {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    background-image: url('../../../assets/imgs/arrow-right-s-line.gif');
    background-repeat: no-repeat;
    background-size: 240px auto;
    background-position: 128% 0%;

    &-title {
      z-index: 1;
      font-family: 'MiSans-Demibold';
      font-size: 24px;
      font-style: normal;
      font-weight: 900;
      line-height: 22px;
      margin-top: 41px;
      /* 91.667% */
    }

    &-text {
      z-index: 1;
      margin-top: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 14px;
      border: 2px solid rgba(255, 255, 255, 0.08);
      background: #1C1E25;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      width: 92px;
      height: 34px;
      box-sizing: border-box;
      /* 157.143% */
    }

    &-btn {
      z-index: 1;
      margin-top: 37px;
      border-radius: 18px;
      background: radial-gradient(76.16% 76.16% at 31.97% 19.67%, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #FF1B60;
      box-shadow: 0px 17px 23px 0px rgba(10, 10, 10, 0.25), 3px 4px 8px 0px rgba(255, 255, 255, 0.70) inset, -10px -12px 37px 0px rgba(0, 0, 0, 0.25) inset, 0px 2px 2px 0px rgba(255, 255, 255, 0.60) inset, 6px 9px 20px 0px rgba(255, 255, 255, 0.30) inset, 0px -3px 8px 0px rgba(255, 255, 255, 0.25) inset;
      height: 61px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &-top {
        display: flex;
        align-items: center;

        &-img {}

        &-text {
          text-align: center;
          font-family: 'MiSans-Demibold';
          font-size: 17px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
        }
      }

      &-text {
        color: rgba(255, 255, 255, 0.66);
        text-align: center;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: -6px;
      }
    }

    &-video {
      position: absolute;
      top: -27px;
      right: -55px;
      width: 80%;

      /* 隐藏播放条 */
      &::-webkit-media-controls {
        display: none !important;
      }

      &::-webkit-media-controls-enclosure {
        display: none !important;
      }

      &::-webkit-media-controls-panel {
        display: none !important;
      }

      &::-webkit-media-controls-timeline {
        display: none !important;
      }
    }
  }

  .Mining-box2 {
    margin-top: 12px;

    &-title {
      color: #fff;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &-content {
      margin-top: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-item {
        height: 74px;
        border-radius: 10px;
        background: linear-gradient(180deg, #2D2233 0%, #583C77 100%);
        display: flex;
        flex-direction: column;
        width: 31%;
        padding-top: 12px;
        padding-left: 12px;
        box-sizing: border-box;
        position: relative;

        &-text1 {
          color: rgba(255, 255, 255, 0.85);
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        &-text2 {
          color: rgba(255, 255, 255, 0.66);
          font-family: PingFang SC;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        &-img {
          width: 38px;
          position: absolute;
          bottom: 0;
          right: 0;
        }

        &:nth-child(2) {
          background: linear-gradient(180deg, #2D2233 0%, #704235 100%);
        }

        &:last-child {
          background: linear-gradient(180deg, #2D2233 0%, #6B242C 100%);
        }
      }
    }
  }

  .Mining-box3 {
    margin-top: 14px;

    &-top {
      display: flex;
      justify-content: space-between;
      border-radius: 16px 16px 0px 0px;
      overflow: hidden;
      padding-bottom: 30px;
      background: #2a2a2a;

      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFF;
        font-family: 'MiSans-Demibold';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        height: 60px;
        flex: 1;
        padding-bottom: 10px;
      }
    }

    &-content {
      background: #2A2F36;
      border-radius: 16px;
      margin-top: -40px;
      padding: 10px;

      &-box {
        height: 100%;
        background: #2a2a2a;
        border-radius: 16px;
        padding: 10px;
        box-sizing: border-box;

        &-top {
          height: 164px;
          background-image: url('../../../assets/imgs/coin7\ 1.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          border-radius: 16px;
          overflow: hidden;
          position: relative;

          &-gif {
            position: absolute;
            height: 60px;

            &:nth-last-child(1) {
              bottom: 10%;
              left: 7%;
            }

            &:nth-last-child(2) {
              right: 25%;
              top: 21%;
              transform: scaleX(-1);
            }

            &:nth-last-child(3) {
              right: 2%;
              bottom: 8%;
              transform: scaleX(-1);
            }
          }

          &-title {
            display: flex;
            justify-content: space-between;
            padding: 10px;

            &-item {
              display: inline-flex;
              padding: 4px 6px;
              justify-content: center;
              align-items: center;
              gap: 4px;
              border-radius: 99px;
              border: 1px solid #BCDDF1;
              background: rgba(0, 0, 0, 0.08);
              color: rgba(0, 0, 0, 0.66);
              text-align: center;
              font-family: 'MiSans-Demibold';
              font-size: 10px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              box-sizing: border-box;

              img {
                width: 14px;
              }
            }
          }
        }

        &-p {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 14px;

          &-text1 {
            color: #FFF;
            text-align: center;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          &-text2 {
            display: flex;
            align-items: center;
            color: #FFF;
            text-align: center;
            font-family: 'MiSans-Demibold';
            font-size: 14px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;

            img {
              width: 24px;
            }
          }
        }

        &-btn {
          height: 61px;
          flex-shrink: 0;
          border-radius: 18px;
          background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4D33EF;
          box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.60) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.60) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.30) inset, -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
          color: #FFF;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'MiSans-Demibold';
          font-size: 17px;
          font-style: normal;
          font-weight: 900;
          line-height: 32px;
          margin-top: 17.5px;
          /* 188.235% */
        }
      }
    }
  }

  .Mining-box4 {
    margin-top: 14px;

    &-content {
      background: #2A2F36;
      border-radius: 16px;
      padding: 10px;

      &-box {
        height: 100%;
        background-image: url('../../../assets/imgs/1105-2.png');
        background-size: 100%;
        background-repeat: no-repeat;
        border-radius: 16px;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-img {
          width: 146px;
          height: 54px;
          margin-top: -11px;
          background-image: url('../../../assets/imgs/power_high_buy_select 1.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          color: #FFF;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          display: flex;
          justify-content: center;
          padding-top: 10px;
        }

        &-top {
          height: 164px;
          background-image: url('../../../assets/imgs/coin7\ 1.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          border-radius: 16px;
          overflow: hidden;
          position: relative;
          width: 100%;

          &-gif {
            position: absolute;
            height: 60px;

            &:nth-last-child(1) {
              bottom: 10%;
              left: 7%;
            }

            &:nth-last-child(2) {
              right: 34%;
              top: 31%;
              transform: scaleX(-1);
              height: 38px;
            }

            &:nth-last-child(3) {
              right: 2%;
              bottom: 8%;
              transform: scaleX(-1);
              height: 48px;
            }

            &:nth-last-child(4) {
              right: 19%;
              bottom: 26%;
              transform: scaleX(-1);
              height: 38px;
            }

            &:nth-last-child(5) {
              bottom: 37%;
              left: 28%;
              height: 38px;
            }

            &:nth-last-child(6) {
              bottom: 27%;
              left: 39%;
              height: 38px;
            }
          }

          &-title {
            display: flex;
            justify-content: space-between;
            padding: 10px;

            &-item {
              display: inline-flex;
              padding: 4px 6px;
              justify-content: center;
              align-items: center;
              gap: 4px;
              border-radius: 99px;
              border: 1px solid #BCDDF1;
              background: rgba(0, 0, 0, 0.08);
              color: rgba(0, 0, 0, 0.66);
              text-align: center;
              font-family: 'MiSans-Demibold';
              font-size: 10px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              box-sizing: border-box;

              img {
                width: 14px;
              }
            }
          }
        }

        &-p {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 14px;
          width: 100%;

          &-text1 {
            color: #FFF;
            text-align: center;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          &-text2 {
            display: flex;
            align-items: center;
            color: #FFF;
            text-align: center;
            font-family: 'MiSans-Demibold';
            font-size: 14px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;

            img {
              width: 24px;
            }
          }
        }

        &-btn {
          border-radius: 18px;
          background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #2F6EFF;
          box-shadow: 4px 38px 62px 0px rgba(0, 0, 0, 0.50), -3px -4px 7px 0px rgba(255, 255, 255, 0.15) inset;
          /* 188.235% */
          color: #FFF;
          text-align: center;
          font-family: 'MiSans-Demibold';
          font-size: 17px;
          font-style: normal;
          font-weight: 900;
          line-height: 32px;
          /* 188.235% */
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 61px;
          margin-top: 17.5px;
          margin-bottom: 10px;
        }
      }
    }
  }
}