#starSourceKey {
  width: 100%;
  min-height: 100vh;
  background-color: #000;
  color: #FFF;

  .starSourceKey-top {
    margin-top: 62px;
    padding-bottom: 10px;
    height: 198px;
    border: 1px solid #000;
    background: url(../../../../assets/imgs/starSourceKey-img2.png) no-repeat right;
    background-size: 47%;


    .starSourceKey-top-left {
      // border: 1px solid red;
      width: 90%;
      height: 78px;
      padding-top: 20px;
      padding-left: 28px;

      .starSourceKey-top-left-p {
        color: #FFF;
        font-family: 'MiSans-Demibold';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }

      .starSourceKey-top-left-div {
        display: flex;
        align-items: center;
        color: #FFF;
        text-align: center;
        font-family: 'MiSans-Demibold';
        font-size: 22px;
        font-style: normal;
        font-weight: 900;
        line-height: 22px;
        margin-top: 12px;
      }

      .starSourceKey-top-left-div-img {
        width: 24px;
        margin-left: 4px;
      }

      .starSourceKey-top-left-p2 {
        color: #999;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin-top: 8px;
        line-height: normal;
      }
    }



    .starSourceKey-title {
      // width: 324px;
      width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding: 16px 20px;
      margin-top: 32px;
      background: #191A1E;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      background: linear-gradient(258deg, rgba(255, 255, 255, 0.29) 2.27%, rgba(255, 255, 255, 0.00) 35.4%), linear-gradient(104deg, rgba(255, 255, 255, 0.23) 3.44%, rgba(255, 255, 255, 0.00) 36.41%), rgba(255, 255, 255, 0.04);
      box-shadow: 0px 4px 12px 0px rgba(192, 40, 27, 0.14), 0px 2px 4px 0px rgba(255, 255, 255, 0.25) inset;
      backdrop-filter: blur(12px);

      .starSourceKey-title-left-top {
        color: #FFF;
        font-family: 'MiSans-Demibold';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        /* 166.667% */
      }

      .starSourceKey-title-left--text {
        color: rgba(255, 255, 255, 0.66);
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .act {
    color: #000;
    font-family: PingFang SC;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 21.6px */
  }

  .noStyles {
    display: none;
  }

  .starSourceKey-center {
    // border: 1px solid red;
    // z-index: 99;
    width: 100%;
    min-height: 72vh;
    background-color: #FFF;
    color: #000;
    // margin-top: 10px;

    .starSourceKey-center-nav {
      margin-left: 16px;
      margin-right: 16px;
      padding-top: 18px;
      padding-bottom: 18px;
      // border: 1px solid red;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);
      display: flex;
      align-items: center;
      color: #999;
      font-family: PingFang SC;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;

      .starSourceKey-center-nav-two {
        margin-right: 32px;
      }
    }


    .starSourceKey-foot {
      // border: 1px solid blue;
      height: 350px;
      overflow: auto;
      margin-left: 16px;
      margin-right: 16px;

      .starSourceKey-center-box {

        padding-top: 14px;
        padding-bottom: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // border: 1px solid red;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);

        .starSourceKey-center-box-left {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .starSourceKey-center-box-left-h3 {
            color: #999;
            text-align: right;
            font-family: 'MiSans-Demibold';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
          }

          .starSourceKey-center-box-left-div {
            display: flex;
            align-items: center;
            margin-left: 25px;
            color: #1D1D1F;
            font-family: 'MiSans-Demibold';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;

            /* 16.8px */
            .starSourceKey-center-box-left-div-img {
              margin-right: 4px;
            }
          }
        }

        .starSourceKey-center-box-right {
          display: flex;
          align-items: center;
          color: var(--333, #333);
          text-align: center;
          font-family: 'MiSans-Demibold';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

          .starSourceKey-center-box-right-div-img {
            width: 22px;
          }
        }
      }
    }

    .starSourceKey-foot2 {
      height: 350px;
      overflow: auto;
      margin-left: 16px;
      margin-right: 16px;
      // border: 1px solid red;

      .starSourceKey-foot2-box {
        // border: 1px solid blue;
        padding-top: 14px;
        padding-bottom: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);

        .starSourceKey-foot2-left {
          color: var(--black, #1D1D1F);
          font-family: 'MiSans-Demibold';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;

          /* 16.8px */
          .starSourceKey-foot2-left-p {
            color: rgba(0, 0, 0, 0.66);
            text-align: right;
            font-family: 'MiSans-Demibold';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            margin-top: 10px;
            /* 14.4px */
          }

        }

        .starSourceKey-foot2-right {
          color: var(--black, #1D1D1F);
          text-align: right;
          font-family: 'MiSans-Demibold';
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          /* 19.2px */
        }
      }
    }

  }

  .starSourceKey-btn {
    // border: 1px solid red;
    margin-left: 16px;
    margin-right: 16px;
    height: 52px;
    border-radius: 12px;
    background: #000;
    color: #F5F8FA;
    font-family: PingFang SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px;
    text-align: center;
    margin-top: 90px;
    /* 19.2px */
  }


}