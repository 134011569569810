@import url('./globals.scss');

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--theme-color);
  width: 100%;
  box-sizing: border-box;
  color: var(--color);
  height: 100%;
  max-height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  max-width: 600px;
  margin: 0 auto;

  // user-select: none;
  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
}

a {
  text-decoration: none;
}

.adm-tab-bar-item {
  color: var(--gray);
  margin-top: 4px;
}

.adm-tab-bar-item-active {
  color: var(--primary);
  font-weight: bold;
  transition: all 0.3s ease;
}

.adm-tab-bar-item-title-with-icon {
  margin-top: 1px;
}


// .adm-tab-bar{
//   box-sizing: border-box;
//     max-width: 600px;
//     margin: 0 auto;
//     left: 0;
//     right: 0;
// }
.adm-tab-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
  background: #fff;
  backdrop-filter: blur(6px);
  padding: 10px 0 22px 0;
  box-sizing: border-box;
}


.adm-center-popup-body {
  border-radius: 10px;
}

.adm-dialog-title {
  font-size: 16px;
}

.adm-dialog-content {
  color: var(--color);

  text-align: center;
  font-size: 14px;

  .adm-input-element {
    color: var(--white);
    font-size: 15px;
    text-align: center;
    // border: 1px solid #120f0f22;
    padding: 8px;
    margin: 4px;
    border-radius: 8px;
    font-weight: bold;

    &::placeholder {
      color: var(--gray);
    }

  }
}

.adm-dialog-button {
  font-size: 16px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-webkit-input-placeholder {
  color: var(--gray);
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: var(--gray);
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: var(--gray);
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--gray);
}

$prefix: animated-router !default;


// 路由切换动画

.#{$prefix} {
  &-container {
    /* 动画容器节点 */

  }

  &-in-transition {
    /* 页面动画中 */
    position: relative;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
  }

  /** START: 自定义进出场动画 **/
  &-forward-appear,
  &-forward-enter {
    transform: translate(100%);
    ;
  }

  &-forward-appear-active,
  &-forward-enter-active {
    transform: translate(0);
  }

  &-forward-exit {
    transform: translate(0);
  }

  &-forward-exit-active {
    transform: translate(-100%);
  }

  &-backward-appear,
  &-backward-enter {
    transform: translate(-100%);
  }

  &-backward-appear-active,
  &-backward-enter-active {
    transform: translate(0);
  }

  &-backward-exit {
    transform: translate(0);
  }

  &-backward-exit-active {
    transform: translate(100%);
  }

  /** END **/

  &-forward-appear-active,
  &-forward-enter-active,
  &-forward-exit-active,
  &-backward-appear-active,
  &-backward-enter-active,
  &-backward-exit-active {
    /* 不同过渡阶段需要的过渡时间与缓动效果 */
    transition: all 400ms ease;
  }

  &-forward-exit,
  &-backward-exit {
    position: absolute !important;
    width: 100%;
    top: 0;
    left: 0;
  }
}

.primary-btn {
  box-sizing: border-box;
  color: #AEB9CA;
  font-weight: bold;
  height: 56px;
  width: 100%;
  background: linear-gradient(0deg, rgba(8, 4, 27, 0.12), rgba(0, 0, 0, 0.42));
  // linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 0px 0px 12px rgba(121, 159, 255, 0.44),
    // 0px 0px 20px rgba(255, 255, 255, 0.36),
    inset 0px 0px 5px rgba(255, 255, 255, 0.17);
  backdrop-filter: blur(12px);
  /* Note: backdrop-filter has minimal browser support */
  box-shadow: 0px 0px 32px 0px rgba(102, 94, 177, 0.37) inset;

  border-radius: 10px;
  margin: 14px 0;
  border: 2px solid rgba(255, 255, 255, 0.14);
  font-size: 15px;
  align-items: center;
  display: flex;
  justify-content: center;
  // justify-content: center;
  padding: 12px 10px;
  transition: all 0.5s ease;
  // margin-top: 18px;
  cursor: pointer;
}

.primary-btn:active {
  transition: all 0.4s ease-in-out;
  box-shadow: none;
}

.adm-popup-body {
  backdrop-filter: blur(24px);
  border: 1px solid rgba(255, 255, 255, 0.28);
  font-size: 15px;
  padding: 20px;
  line-height: 1.75;
  border-radius: 24px 24px 0 0;
  box-sizing: border-box;
  max-height: 66vh;
  overflow-y: auto;
}