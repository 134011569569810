#invite {
  background: linear-gradient(180deg, #110F2F 0%, #221F53 100%);
  min-height: 100vh;
  padding: 30px 18px;
  box-sizing: border-box;

  .invite-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &-text1 {
      color: #FFF;
      text-align: center;
      font-family: PingFang SC;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      /* 129.412% */
    }

    &-text2 {
      color: #FFF;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      position: absolute;
      right: 0;
      z-index: 1;
      top: 23px;
      /* 157.143% */
    }
  }

  .invite-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 11px;

    &-img1 {
      margin-bottom: 40px;
    }

    &-img2 {
      margin-right: -20px;
    }
  }

  .invite-box1 {
    display: flex;
    padding: 0px 18px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid rgba(174, 185, 202, 0.22);
    background: rgba(0, 0, 0, 0.22);
    backdrop-filter: blur(12px);
    margin-top: -37px;

    &-item {
      display: flex;
      padding: 20px 0px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      &-text {
        color: var(--White, #F5F8FA);
        font-family: 'MiSans-Demibold';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
      }

      &-right {
        display: flex;
        align-items: center;

        &-text {
          color: var(--txt, #AAB8C2);
          text-align: right;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          margin-right: 12px;
          /* 16.8px */
        }

        &-img {
          display: flex;
          padding: 4px;
          align-items: center;
          border-radius: 4px;
          border: 1px solid rgba(255, 255, 255, 0.24);
        }
      }

      &:first-of-type {
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      }
    }
  }

  .invite-btn {
    display: flex;
    height: 54px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    color: var(--fff, #FFF);
    font-family: PingFang SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18.75px;
    /* 117.188% */
    letter-spacing: 0.08px;
    border-radius: 12px;
    background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4C43CD;
    box-shadow: 4px 38px 62px 0px rgba(0, 0, 0, 0.50), -3px -4px 7px 0px rgba(255, 255, 255, 0.15) inset;
    margin-top: 14px;
  }

  .invite-box2 {
    display: flex;
    padding: 18px;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid rgba(174, 185, 202, 0.22);
    background: rgba(0, 0, 0, 0.22);
    margin-top: 14px;

    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &-text {
        color: #FFF;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        /* 16.8px */
      }

      &-text2 {
        color: rgba(255, 255, 255, 0.66);
        text-align: right;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    p {
      color: rgba(255, 255, 255, 0.66);
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 175%;
      /* 21px */
    }

    &-bottom {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      &-item {
        display: flex;
        flex-direction: column;

        &-text1 {
          color: #FFF;
          font-family: 'MiSans-Demibold';
          font-size: 16px;
          font-style: normal;
          font-weight: 900;
          line-height: 120%;
          /* 19.2px */
        }

        &-text2 {
          color: var(--Gray-Light, #AAB8C2);
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          margin-top: 8px;
          /* 14.4px */
        }

        &:last-of-type {
          margin-right: 42px;
        }
      }
    }
  }

  .invite-list {
    display: flex;
    padding: 18px;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    align-self: stretch;
    margin-top: 14px;
    border-radius: 10px;
    border: 1px solid rgba(174, 185, 202, 0.22);
    background: rgba(0, 0, 0, 0.22);
    height: 500px;
    overflow: auto;

    &-title {
      display: flex;
      align-items: center;

      &-item {
        position: relative;
        margin: 0 19px;

        &-text {
          font-weight: 700;
          font-size: 17px;
          color: #FFFFFF;
        }

        &-img {
          width: 13px;
          position: absolute;
          bottom: -5px;
          right: 0;
          left: 0;
          margin: 0 auto;
        }
      }
    }

    &-data {
      width: 100%;
      margin-top: 36/2px;
      display: flex;
      flex-direction: column;
      margin-bottom: 8/2px;

      &-noData {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 100px;

        .synthesis-img {
          width: 130.32px;
        }

        .synthesis-text {
          // font-weight: 600;
          font-size: 16px;
          margin-top: 8px;
          color: #AAB8C2;
        }
      }

      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        &-left {
          display: flex;
          align-items: center;

          &-img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            margin-right: 6px;
          }

          &-text {
            font-weight: 600;
            font-size: 14px;
            color: #F5F8FA;
          }
        }

        &-right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          &-text1 {
            font-weight: 700;
            font-size: 14px;
            color: #F5F8FA;
          }

          &-text2 {
            font-weight: 400;
            font-size: 12px;
            color: #AAB8C2;
            margin-top: 10px;
          }
        }
      }
    }
  }

  #invite-canvas {
    position: relative;
    z-index: 9;
    background-size: 100%;
    overflow: hidden;
    background-image: url('../../assets/imgs/会员用户.png');
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 16px;
    background-color: #000;
    width: 50%;

    .invite-canvas-top {
      background: #000;
      padding-bottom: 55px;
      overflow: hidden;

      &-video {
        width: 264px;
        position: absolute;
        right: -63px;
        top: 27px;
      }
    }

    .invite-canvas-box {
      width: 100%;
      position: absolute;
      bottom: 59px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      &-left {
        display: flex;
        flex-direction: column;

        &-top {
          display: flex;
          align-items: center;

          span {
            color: #FFF;
            font-family: 'MiSans-Demibold';
            font-size: 26px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            margin-right: 5px;
            /* 31.2px */
          }
        }

        &-text {
          color: #D0E0F3;
          font-family: PingFang SC;
          font-size: 12.133px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          /* 14.56px */
        }
      }

      &-right {
        width: 67.6px;
        height: 67.6px;
      }
    }
  }

  .invite-dialog {
    width: 100%;
    height: 100%;
    opacity: 1;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(12px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;

    .copy {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;

      &-img {
        width: 60px;
        height: 60px;
        margin-bottom: 10px;
      }

      &-text {
        ont-weight: 700;
        font-size: 12px;
        color: #BFBFBF;
      }
    }
  }

  .invite-desc {
    display: flex;
    padding: 18px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid rgba(174, 185, 202, 0.22);
    background: rgba(0, 0, 0, 0.22);
    margin-top: 14px;

    &-text1 {
      font-size: 16px;
      color: #ffffff;
      text-align: center;
      font-weight: bold;
    }

    &-text2 {
      margin-top: 7px;
      font-size: 28/2px;
      line-height: 1.75;
      color: #AAB8C2;
      text-align: left;
    }
  }

  .invit-wxbox {
    display: flex;
    padding: 24px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: var(--fff, #FFF);
    position: relative;
    width: 270px;
    height: 290px;
    box-sizing: border-box;
    margin-bottom: 69px;

    span {
      color: var(--black, #1D1D1F);
      text-align: center;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 29px;
      /* 207.143% */
      letter-spacing: 0.258px;
      margin-top: 14px;
    }

    &-close {
      position: absolute;
      bottom: -65px;
    }
  }
}