#onChainInformation {
  position: relative;
  min-height: 100vh;

  .noStyle {
    display: none;
  }

  .onChainInformation-box {
    padding-bottom: 50px;
    // border: 1px solid blue;
  }

  .nftDetail-img {
    display: block;
    margin-top: 20px;
    width: 100%;
    height: 382px;
    margin-top: 61px;
  }

  .onChainInformation-title-box1 {
    width: 100%;
    // border: 1px solid blue;

    .onChainInformation-title {
      width: 100%;
      padding-left: 20px;
      height: 110px;
      // border: 1px solid red;
      margin: 0 auto;
      margin-top: 14px;
      border-bottom: 1px solid #AAB8C2;

      .onChainInformation-title-one {
        width: 174px;
        height: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--black, #1D1D1F);
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        // border: 1px solid blue;

        .nChainInformation-title-one-img {
          width: 16px;
          height: 16px;
        }
      }

      .onChainInformation-title-h3 {
        margin-top: 8px;
        color: var(--black, #1D1D1F);
        font-family: PingFang SC;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
      }

      .onChainInformation-title-two {
        width: 168px;
        display: flex;
        margin-top: 8px;
        // border: 1px solid blue;

        .onChainInformation-title-box {
          display: flex;
          width: 80px;
          color: #000;
          text-align: center;
          font-family: PingFang SC;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          /* 160% */

          .onChainInformation-title-box-left {
            color: #fff;
            display: flex;
            padding: 2px 8px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 4px 0px 0px 4px;
            background: var(--black, #1D1D1F);
          }

          .onChainInformation-title-box-right {
            display: flex;
            padding: 2px 8px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 0px 4px 4px 0px;
            background: var(--grey_bg, #F6F6F6);
          }
        }

        .onChainInformation-title-box2 {
          display: flex;
          width: 80px;
          margin-left: 8px;
          color: #000;
          text-align: center;
          font-family: PingFang SC;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          /* 160% */

          .onChainInformation-title-box-left {
            color: #fff;
            display: flex;
            padding: 2px 8px;
            border-radius: 4px 0px 0px 4px;
            background: var(--black, #1D1D1F);
          }

          .onChainInformation-title-box-right {
            display: flex;
            padding: 2px 8px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 0px 4px 4px 0px;
            background: var(--grey_bg, #F6F6F6);
          }
        }
      }
    }
  }

  .onChainInformation-center-box {
    // border: 1px solid red;
    width: 100%;

    .onChainInformation-center {
      // width: 347px;
      padding-left: 20px;
      height: 146px;
      // border: 1px solid red;
      margin: 0 auto;
      margin-top: 14px;
      color: var(--black, #1D1D1F);
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      border-bottom: 1px solid #AAB8C2;


      .onChainInformation-center-h4 {
        color: var(--black, #1D1D1F);
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;

      }

      .onChainInformation-center-div {
        margin-top: 10px;
        color: var(--black, #1D1D1F);
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;

        /* 16.8px */
        .onChainInformation-center-span {
          color: var(--black, #1D1D1F);
          font-family: 'MiSans-Demibold';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          margin-left: 6px;
        }
      }

      .onChainInformation-center-div2 {
        margin-top: 10px;
        display: flex;

        .onChainInformation-center-div2-img {
          width: 63px;
          height: 18px;
          margin-left: 6px;
        }
      }
    }

    .onChainInformation-record {
      width: 100%;

      .onChainInformation-record-top {
        // width: 347px;
        padding-left: 20px;
        padding-right: 20px;
        height: 30px;
        // border: 1px solid red;   
        margin: 0 auto;
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--black, #1D1D1F);
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 19.2px */

        .onChainInformation-record-right {
          display: flex;
          align-items: center;
          // margin-left: 14px;
          width: 81px;
          height: 30px;
          border-radius: 4px;
          background: rgba(0, 0, 0, 0.06);
          color: var(--black, #1D1D1F);
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;

          /* 183.333% */
          .onChainInformation-record-right-text {
            margin-left: 14px;
          }
        }
      }

      .onChainInformation-record-over {
        height: 256px;
        overflow: auto;

        // border: 1px solid red;
        .onChainInformation-record-box {
          padding-left: 20px;
          padding-right: 20px;
          height: 67px;
          // border: 1px solid red;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid rgba(0, 0, 0, 0.10);

          .onChainInformation-record-box-left {
            color: var(--black, #1D1D1F);
            font-family: 'MiSans-Demibold';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;

            .onChainInformation-record-box-left-p {
              color: #999;
              font-family: PingFang SC;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%;
              /* 14.4px */
            }
          }

          .onChainInformation-record-box-right {
            color: var(--success-success-4-hover, #56C08D);
            text-align: right;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            /* 16.8px */
          }

          .onChainInformation-record-box-right2 {
            color: var(--error-error-5-hover, #F6685D);
            text-align: right;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            /* 16.8px */
          }
        }
      }

    }
  }


  .onChainInformation-btn {
    .onChainInformation-btn-box {
      width: 100%;
      padding: 16px 14px 16px 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      bottom: 0;
      // border: 1px solid red;
      background: rgba(255, 255, 255, 0.88);
      backdrop-filter: blur(2px);

      .onChainInformation-btn-left {
        width: 176px;
        color: var(--black, #1D1D1F);
        font-family: PingFang SC;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        // border: 1px solid blue;
        padding-left: 14px;

        .onChainInformation-btn-left-shop {
          font-size: 12px;
          color: #999;
        }
      }

      .onChainInformation-btn-right {
        width: 176px;
        height: 61px;
        margin-right: 22px;
        border-radius: 18px;
        background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4D33EF;
        box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.60) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.60) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.30) inset, -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
        color: #FFF;
        text-align: center;
        font-family: 'MiSans-Demibold';
        font-size: 17px;
        font-style: normal;
        font-weight: 900;
        line-height: 61px;
        /* 188.235% */

      }
    }
  }
}