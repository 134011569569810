#commonDialog {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  animation: background .5s ease;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(12px);
  z-index: 999;

  @keyframes background {
    0% {
      background: rgba(0, 0, 0, 0);
      backdrop-filter: blur(0px);
    }

    100% {
      background: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(12px);
    }
  }
}