#Pool {
  .Pool-box1 {
    position: relative;
    overflow: hidden;
    margin-top: 27px;
    padding-bottom: 60px;
    background-image: url('../../../../../assets/imgs/deal.gif');
    background-size: 248px auto;
    background-repeat: no-repeat;
    background-position: 123% 72%;

    &-left {
      display: flex;
      flex-direction: column;

      &-top {
        display: flex;
        align-items: center;

        &-text {
          color: #FFF;
          text-align: center;
          font-family: 'MiSans-Demibold';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          margin-right: 8px;
          /* 157.143% */
        }
      }

      &-text {
        color: #FFF;
        font-family: 'MiSans-Demibold';
        font-size: 22px;
        font-style: normal;
        font-weight: 900;
        line-height: 22px;
        z-index: 1;
        margin-top: 12px;
        /* 100% */
      }

      &-bottom {
        display: flex;
        align-items: center;
        padding: 4px 10px;
        border-radius: 65px;
        border: 1px solid rgba(255, 255, 255, 0.12);
        background: rgba(255, 255, 255, 0.10);
        z-index: 1;
        box-sizing: border-box;
        position: absolute;
        bottom: 13px;

        &-text {
          color: rgba(255, 255, 255, 0.85);
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          margin-left: 4px;
          /* 183.333% */
        }
      }
    }

    &-video {
      position: absolute;
      top: -47px;
      right: -46px;
      width: 71%;

      /* 隐藏播放条 */
      &::-webkit-media-controls {
        display: none !important;
      }

      &::-webkit-media-controls-enclosure {
        display: none !important;
      }

      &::-webkit-media-controls-panel {
        display: none !important;
      }

      &::-webkit-media-controls-timeline {
        display: none !important;
      }
    }
  }

  .Pool-box2 {
    padding: 18px;
    min-height: 600px;
    gap: 14px;
    flex-shrink: 0;
    border-radius: 22px 22px 0px 0px;
    background: #FFF;
    box-shadow: 0px -9px 12px 0px rgba(0, 0, 0, 0.12);
    position: absolute;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    margin-top: 10px;

    &-top {
      display: flex;
      align-items: center;
      justify-content: space-around;

      &-item {
        display: flex;
        align-items: center;

        &-span {
          color: var(--gray);
          text-align: right;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          /* 171.429% */
        }
      }
    }

    &-list {
      height: 60vh;
      padding-bottom: 30px;
      box-sizing: border-box;
      overflow-y: auto;
      overflow-x: hidden;

      &-item {
        display: flex;
        padding: 12px;
        align-items: center;
        border-radius: 16px;
        background: #F6F6F6;
        box-sizing: border-box;
        margin-top: 14px;

        >span {
          width: 21px;
          height: 21px;
          background: #ebebeb;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 11px;
          font-weight: 900;
          color: #999;
        }

        &-img {
          border-radius: 12px;
          margin-left: 8px;
          margin-right: 14px;
          object-fit: cover;
        }

        &-content {
          white-space: nowrap;

          &-text {
            color: var(--color);
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            /* 24px */
          }

          &-bottom {
            margin-top: 4px;
            display: flex;
            align-items: center;

            &-text1 {
              color: #F06A31;
              font-family: PingFang SC;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
              margin-right: 9px;
              display: flex;
              align-items: center;
              /* 18px */

              span {
                width: 53px;
                white-space: nowrap;
                display: flex;
                align-items: center;

                .jDHIsP {
                  font-size: 12px;
                }
              }
            }

            &-text2 {
              color: var(--333, #333);
              font-family: PingFang SC;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%;
              margin-left: 9px;
              margin-right: 5px;
              /* 14.4px */
            }

            &-text3 {
              color: #000;
              font-family: 'MiSans-Demibold';
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%;
            }
          }
        }
      }
    }
  }
}