#sms {
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    .text1 {
        font-weight: 700;
        font-size: 22px;
        color: var(--color);
        margin-top: 85px;
    }

    .text2 {
        font-weight: 400;
        font-size: 12px;
        color: var(--gray);
        margin-top: 4px;
    }

    .authCode {
        margin-top: 30px;
        position: relative;

        .keyword {
            width: 100%;
            position: absolute;
            top: 0;
            height: 66px;
            opacity: 0;
        }

        .showCode {
            display: flex;
            justify-content: space-between;

            .codeItem {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 14%;
                height: 66px;
                background: #1D1D1F;
                border-radius: 4px;
                font-size: 24px;
                color: #FFFFFF;
                font-family: ''MiSans-Demibold'-Demibold';
            }
        }
    }

    .text3 {
        font-weight: 400;
        font-size: 12px;
        color: var(--gray);
        margin-top: 30px;
    }
}