#MySpiritualGold {
  height: 100vh;
  position: relative;
  padding-top: 62px;
  box-sizing: border-box;
  overflow-y: auto;

  .MySpiritualGold-top {
    background: url(../../../assets/imgs/MySpiritualGold-top-bj.png)no-repeat right center;
    background-size: 38%;
    background-color: #000;
    background-position: 95% 0%;
    padding-bottom: 40px;

    .MySpiritualGold-top-my {
      width: 100px;
      display: flex;
      color: #FFF;
      margin-left: 12px;
      font-size: 14px;
      padding-top: 10px;
    }

    .MySpiritualGold-top-my img {
      width: 16px;
      height: 16px;
      margin-top: 3px;
      margin-left: 8px;
    }

    .MySpiritualGold-top-time {
      width: 90px;
      height: 22px;
      font-size: 22px;
      font-weight: 900;
      color: #FFF;
      margin-top: 12px;
      margin-left: 12px;
    }

    .MySpiritualGold-top-btn {
      width: 92px;
      height: 34px;
      border-radius: 12px;
      font-size: 14px;
      line-height: 34px;
      color: #fff;
      margin-top: 24px;
      margin-left: 12px;
      text-align: center;
      background: radial-gradient(76.16% 76.16% at 31.97% 19.67%, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #FF602E;
      box-shadow: 4px 38px 62px 0px rgba(0, 0, 0, 0.50), -3px -4px 7px 0px rgba(255, 255, 255, 0.15) inset;
    }
  }

  .MySpiritualGold-center {
    align-items: center;

    .MySpiritualGold-foot {
      height: 66px;
      padding: 0 15px;

      .MySpiritualGold-foot-top {
        height: 66px;
        line-height: 66px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }

      .MySpiritualGold-foot-top .MySpiritualGold-icome {
        width: 36px;
        height: 22px;
        font-size: 18px;
        color: #999;
        margin-right: 32px;
      }

      .MySpiritualGold-font {
        width: 36px;
        height: 22px;
        font-size: 18px;
        color: #999;
        margin-right: 32px;
      }
    }

    .MySpiritualGold-foot-obtain {
      height: 41px;
      margin: 18px 15px 0;
      padding-bottom: 14px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      display: flex;
      justify-content: space-between;

      .MySpiritualGold-foot-obtain-left {
        height: 41px;
      }

      .MySpiritualGold-foot-obtain-left p {
        width: 110px;
        height: 14px;
        margin-top: 10px;
        font-size: 12px;
        color: var(--black, #1D1D1F);
        ;
      }

      .MySpiritualGold-foot-obtain-right {
        line-height: 41px;
        font-weight: 600;
        font-size: 16px;
      }

      .MySpiritualGold-foot-obtain-right span {
        margin-right: 5px;
      }
    }
  }





}