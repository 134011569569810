#MiyaoList {
  height: 100vh;
  overflow-y: auto;
  position: relative;
  padding-top: 62px;
  box-sizing: border-box;

  .MiyaoList-top {
    background: url('../../../../assets/imgs/MiyaoList-top-bj.png')no-repeat right center;
    background-size: auto 100%;
    background-color: #000;
    background-position: 95% 0%;
    padding-bottom: 40px;

    .MiyaoList-top-my {
      width: 100px;
      display: flex;
      color: #FFF;
      margin-left: 12px;
      font-size: 14px;
      padding-top: 10px;
    }

    .MiyaoList-top-my img {
      width: 22px;
      height: 22px;
      margin-left: 2px;
    }

    .MiyaoList-top-time {
      width: 90px;
      height: 22px;
      font-size: 22px;
      font-weight: 900;
      color: #FFF;
      margin-top: 12px;
      margin-left: 12px;
    }

    .MiyaoList-top-btn {
      width: 92px;
      height: 34px;
      border-radius: 12px;
      font-size: 14px;
      line-height: 34px;
      color: #fff;
      margin-top: 24px;
      margin-left: 12px;
      text-align: center;
      border-radius: 12px;
      background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4D33EF;
      box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.60) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.60) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.30) inset, -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
    }
  }

  .MiyaoList-center {
    align-items: center;

    .MiyaoList-foot {
      height: 66px;
      padding: 0 15px;

      .MiyaoList-foot-top {
        height: 66px;
        line-height: 66px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }

      .MiyaoList-foot-top .MiyaoList-icome {
        width: 36px;
        height: 22px;
        font-size: 18px;
        color: #999;
        margin-right: 32px;
      }

      .MiyaoList-font {
        width: 36px;
        height: 22px;
        font-size: 18px;
        color: #999;
        margin-right: 32px;
      }
    }

    .MiyaoList-foot-obtain {
      height: 41px;
      margin: 18px 15px 0;
      padding-bottom: 14px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      display: flex;
      justify-content: space-between;

      .MiyaoList-foot-obtain-left {
        height: 41px;
      }

      .MiyaoList-foot-obtain-left p {
        width: 110px;
        height: 14px;
        margin-top: 10px;
        font-size: 12px;
        color: var(--black, #1D1D1F);
        ;
      }

      .MiyaoList-foot-obtain-right {
        line-height: 41px;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        align-items: center;
      }

      .MiyaoList-foot-obtain-right span {
        margin-right: 5px;
      }
    }
  }





}