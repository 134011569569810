#action {
  height: 100vh;
  box-sizing: border-box;
  padding: 80px 15px 0;

  .order-title {
    padding-top: 15px;
    margin-left: 18px;
    margin-right: 18px;
    display: flex;
    align-items: center;

    &-right {
      width: 170px;
      height: 39px;
      // border: 1px solid blue;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 0 auto;
      border-radius: 12px;
      background: #F3F3F3;
      font-size: 14px;

      &-sell {
        padding: 0 10px;
        height: 32px;
        border-radius: 10px;
        overflow: hidden;
        color: #1D1D1F;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
      }

      .switch {
        background: #fff;
      }
    }
  }

  .box {
    border-radius: 12px;
    background: var(--grey_bg, #F6F6F6);
    display: flex;
    justify-content: space-between;
    padding: 22px 14px;
    box-sizing: border-box;

    &-item {
      display: flex;
      height: 70px;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;


      &-text {
        color: #999;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      &-content {
        display: flex;
        align-items: center;

        &-img {
          margin-right: 8px;
          width: 24px;
          height: 24px;
        }

        &-text {
          color: #1D1D1F;
          font-family: PingFang SC;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
        }

        &-input {
          border: none;
          background: none;
          width: 40px;
          color: #1D1D1F;
          font-family: PingFang SC;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          text-align: center;
        }
      }
    }
  }

  .img {
    display: flex;
    justify-content: center;
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .box2 {
    border-radius: 12px;
    border: 1px solid #E1E1E1;
    padding: 18px 14px;
    margin-top: 16px;

    &-item {
      display: flex;
      justify-content: space-between;

      &-text {
        color: #999;
        text-align: center;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 175%;
        /* 21px */
      }
    }
  }

  .btn {
    margin-top: 14px;
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 12px;
    background: #000;
    color: #F5F8FA;
    font-family: PingFang SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 19.2px */
  }

  .Record {
    &-title {
      display: flex;
      justify-content: space-between;

      &-text1 {
        color: var(--black, #1D1D1F);
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 19.2px */
      }

      &-text2 {
        color: #666;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 14.4px */
      }
    }

    &-list {
      &-item {
        padding: 20px 0;
        position: relative;

        &-top {
          display: flex;
          justify-content: space-between;

          &-left {
            color: #333;
            text-align: center;
            font-family: 'MiSans-Demibold';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            align-items: center;

            img {
              margin: 0 4px;
            }
          }

          &-right {
            color: #333;
            text-align: right;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            /* 16.8px */
          }
        }

        &-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 10px;

          &-left {
            color: #666;
            text-align: right;
            font-family: PingFang SC;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            /* 14.4px */
          }

          &-right {
            color: #666;
            text-align: right;
            font-family: PingFang SC;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            display: flex;
            align-items: center;
            /* 14.4px */
          }
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          height: 1px;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.12);
        }
      }
    }
  }
}