#setting {
  padding: 40px 20px 0;
  min-height: 100vh;
  box-sizing: border-box;
  // transform: translateZ(0);
  display: flex;
  flex-direction: column;

  .setting-list {
    margin-top: 14px;

    &-item {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      padding: 24px 0;
      font-size: 15px;

      div {
        align-items: center;
        display: flex;
        gap: 4px;
      }

      &-avatar-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid #ffffff23;
        box-sizing: border-box;
        object-fit: cover;
      }

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        background: #E1E1E1;
        transform: scaleY(.5);
      }
    }


  }

  .logout-btn {
    height: 56px;
    border: none;
    font-size: 16px;
    font-weight: bold;
    margin-top: 80px;
    width: 100%;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    z-index: 4;
    border-radius: 12px;
    background: #1D1D1F;
    position: absolute;
    bottom: 45px;
    width: 90%;
    left: 0;
    right: 0;
    margin: auto;
  }

  .PersonalSetting-CenterPopup {
    &-h3 {
      padding-top: 24px;
      margin-left: 24px;
      margin-right: 24px;
      margin-top: 32px;
      // border: 1px solid blue;
      text-align: center;
      color: var(--text-icon-font-gy-190, rgba(0, 0, 0, 0.90));
      font-family: PingFang SC;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
      /* 144.444% */
    }

    &-center {
      // border: 1px solid red;
      margin-left: 24px;
      margin-right: 24px;

      &-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 14px;
        background: #F6F6F6;
        height: 52px;
        margin-top: 14px;

        &-left {
          display: flex;
          align-items: center;
          margin-left: 8px;
          color: #999;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;

          /* 157.143% */
          &-text {
            margin-left: 10px;
          }
        }
        &-right{
          margin-right: 8px;
        }
      }

      &-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 14px;
        background: #F6F6F6;
        height: 52px;
        margin-top: 14px;

        &-left {
          display: flex;
          align-items: center;
          margin-left: 8px;
          color: #999;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;

          /* 157.143% */
          &-text {
            margin-left: 10px;
          }
        }

        &-right {
          margin-right: 8px;
          // border: 1px solid red;
        }

      }
    }

    &-btnBox {
      margin: 24px;
      padding-bottom: 24px;

      &-btn {
        height: 52px;
        border-radius: 12px;
        border: 1px solid #000;
        background: var(--black, #1D1D1F);
        color: var(--text-icon-font-wh-1100, #FFF);
        text-align: center;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 52px;
        /* 150% */
      }
    }

  }

}
