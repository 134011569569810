@font-face {
  font-family: 'MiSans-Demibold';
  src: url('/assets/fonts/MiSans-Demibold.ttf');
}

@font-face {
  font-family: 'ZX-Medium';
  src: url('/assets/fonts/ZX-Medium.ttf');
}

@mixin light {
  /* color */
  --theme-color: #fff;
  --color: #000000;
  --gray: #666;
  --box-bj: rgba(255, 255, 255, 1);
  --btn-color: #1D1D1F;
  --box-shadow: inset 0px 0px 4px rgba(255, 255, 255, 0.15);
  --tarbar-color: rgba(29, 29, 44, 0.66);
  --adm-color-primary: #4E5BF2;
}

@mixin dark {
  /* color */
  --theme-color: #040415;
  --color: #f3f3f3;
  --gray: #AAB8C2;
  --box-bj: rgba(255, 255, 255, 0.05);
  --box-shadow: inset 0px 0px 4px rgba(255, 255, 255, 0.15);
  --tarbar-color: rgba(29, 29, 44, 0.66);
  --primary: #4E5BF2;
  --adm-color-primary: #4E5BF2;
}

:root:root {
  --adm-color-primary: #4E5BF2;
}

.light {
  @include light()
}

.dark {
  @include dark()
}

:root {
  @include light();
  scrollbar-width: none;
  -ms-overflow-style: none;
  /* IE 和 Edge */
  overflow-x: hidden !important;
  --animate-duration: 0.5s;
}

:root::-webkit-scrollbar {
  display: none;
}

/* Hide the scrollbar in Firefox */
:root {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* Hide the scrollbar in IE and Edge */
:root {
  -ms-overflow-style: none;
}

.adm-toast-main {
  max-width: 360px !important;
}

.btn {
  transition: transform 0.3s;

  &:active {
    transform: scale(0.95);
    opacity: 0.85;
  }
}

.noData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 250px;
  margin: auto;
  width: 45%;

  &-img {
    width: 100%;
  }

  &-text {
    font-size: 16px;
    margin-top: 8px;
    color: #AAB8C2;
  }
}

.adm-center-popup-wrap {
  min-width: none;
  max-width: none;
  width: 84%;
}

.login-ctx2 {
  display: flex;
  align-items: center;
  padding: 24px 0;

  &-check {
    margin-right: 5px;

    .adm-checkbox-icon {
      width: 14px;
      height: 14px;
      border: none;
      border-radius: 3px;
      font-size: 18px;
      background: #DADADA !important;
      color: #fff;
      border: 1px solid rgba(70, 64, 64, 0.068) !important;
    }
  }

  &-p {
    font-weight: 400;
    font-size: 12px;
    color: var(--gray);
  }
}