#CollectionSubscription {
  min-height: 100vh;
  padding-top: 62px;
  position: relative;
  box-sizing: border-box;

  .CollectionSubscription-top {
    height: 155px;
    background: url(../../../assets/imgs/CollectionSubscription-Bj.png)no-repeat right center;
    background-size: 104px 111px;
    background-color: #000;
  }

  .CollectionSubscription-top-my {
    width: 100px;
    display: flex;
    color: #FFF;
    margin-left: 12px;
    font-size: 14px;
  }

  .CollectionSubscription-top-my img {
    width: 24px;
    height: 24px;
    margin-left: 8px;
  }

  .CollectionSubscription-top-time {
    width: 90px;
    height: 22px;
    font-size: 22px;
    font-weight: 900;
    color: #FFF;
    margin-left: 12px;
  }

  .CollectionSubscription-top-text {
    color: rgba(255, 255, 255, 0.66);
    text-align: center;
    font-family: 'MiSans-Demibold';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-left: 12px;
    margin-top: 12px;
    display: block;
    width: 30px;
    white-space: nowrap;
  }

  .CollectionSubscription-top-btn {
    width: 92px;
    height: 34px;
    border-radius: 12px;
    font-size: 14px;
    line-height: 34px;
    color: #fff;
    margin-top: 12px;
    margin-left: 12px;
    text-align: center;
    border: 2px solid rgba(255, 255, 255, 0.08);
    background: #1C1E25;
  }

  .CollectionSubscription-center {
    background-color: #FFF;
    padding: 16px;

    .CollectionSubscription-center-box {
      height: 334px;
      margin-top: 17px;
      border-radius: 14px;
      padding-top: 17px;
      background: var(--grey_bg, #F6F6F6);
      padding: 14px;

      .CollectionSubscription-center-box-img {
        height: 180px;
        margin: 0 auto;
        position: relative;
        border-radius: 14px;
        overflow: hidden;

        img {
          height: 100%;
          object-fit: cover;
        }

        .CollectionSubscription-center-box-img-time {
          height: 30px;
          padding: 1px 8px;
          border-radius: 7px;
          background: rgba(49, 59, 69, 0.66);
          text-align: center;
          line-height: 30px;
          color: #FFF;
          position: absolute;
          top: 12px;
          left: 12px;
        }
      }

      .CollectionSubscription-center-box-h3 {
        height: 24px;
        width: 100%;
        margin-top: 14px;
        font-weight: 600;
        color: var(--black, #1D1D1F);
        font-family: PingFang SC;
        font-size: 16px;
        // border: 1px solid red;
      }

      .CollectionSubscription-center-box-fon {
        height: 100px;
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        font-size: 14px;
        // border: 1px solid red;

        .CollectionSubscription-center-box-text {
          line-height: 23px;
        }

        .CollectionSubscription-center-box-num {
          line-height: 23px;
          text-align: right;
          color: var(--black, #1D1D1F);
          font-weight: 600;
        }
      }
    }
  }
}