#DealPassword {
  padding: 32px 24px;
  text-align: center;

  .DealPassword-text {
    color: var(--text-icon-font-gy-190, rgba(0, 0, 0, 0.90));
    text-align: center;
    /* Title/Large */
    font-family: PingFang SC;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    /* 144.444% */
  }

  .DealPassword-text2 {
    color: #666;
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
  }

  .DealPassword-center {
    position: relative;
    margin: 24px 0;
  }

  .keyword {
    width: 100%;
    position: absolute;
    top: 0;
    height: 48px;
    opacity: 0;
    left: 0;
  }

  .showCode {
    display: flex;
    justify-content: space-between;

    .codeItem {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 48px;
      background: #000;
      border-radius: 4px;
      font-size: 24px;
      color: #FFFFFF;
      font-family: ''MiSans-Demibold'-Demibold';
      font-weight: 600;
    }
  }
}

.DealPassword {
  .adm-center-popup-wrap {
    min-width: 92% !important;
    transform: translate(-50%, -75%);
  }
}