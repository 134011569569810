#MyPer {
  height: 100vh;
  background-color: #000;
  box-sizing: border-box;

  .MyPer-video {
    width: 100%;
    padding-top: 174px;
  }

  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .MyPer-btn {
    height: 52px;
    flex-shrink: 0;
    border-radius: 10px;
    color: #1D1D1F;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    background: var(--fff, #FFF);
    text-align: center;
    line-height: 52px;
    position: absolute;
    bottom: 73px;
    width: 90%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}