#WinningRecord {
  width: 100%;
  min-height: 100vh;
  padding-top: 78px;
  box-sizing: border-box;

  .WinningRecord-title {
    margin-left: 14px;
    margin-right: 14px;
    border-radius: 12px;
    background: rgba(77, 51, 239, 0.06);
    // border: 1px solid red;

    .WinningRecord-title-box {
      padding: 12px;
      color: var(--purple, #4D33EF);
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      /* 183.333% */
    }
  }

  .WinningRecord-center {
    margin-left: 14px;
    margin-right: 14px;

    .WinningRecord-center-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border: 1px solid red;
      padding-top: 25px;
      padding-bottom: 25px;
      border-bottom: 1px solid rgba(170, 184, 194, 0.14);
      color: var(--black, #1D1D1F);
      text-align: right;
      font-family: 'MiSans-Demibold';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;

      /* 14.4px */
      .WinningRecord-center-box-left {
        color: var(--black, #1D1D1F);
        font-family: 'MiSans-Demibold';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 16.8px */
      }
    }
  }
}