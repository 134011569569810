#SubscriptionRecords {
  width: 100%;
  min-height: 100vh;
  padding-top: 68px;
  box-sizing: border-box;

  .SubscriptionRecords-box {
    padding: 0 14px;
    height: 73px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(170, 184, 194, 0.14);

    .SubscriptionRecords-box-name {
      line-height: 73px;
      color: var(--black, #1D1D1F);
      font-family: 'MiSans-Demibold';
      font-size: 14px;
      font-weight: 600;
    }

    .SubscriptionRecords-box-time h3 {
      margin-top: 16px;
      color: #1D1D1F;
      text-align: right;
      font-family: 'MiSans-Demibold';
      font-size: 14px;
      font-weight: 600;
    }

    .SubscriptionRecords-box-time p {
      margin-top: 6px;
      color: #999;
      font-size: 12px;
    }
  }

  .SubscriptionRecords-box2 {
    margin: 0 auto;
    margin-top: 10px;
    width: 347px;
    height: 73px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(170, 184, 194, 0.14);

    .SubscriptionRecords-box2-name {
      line-height: 73px;
      color: var(--black, #1D1D1F);
      font-family: 'MiSans-Demibold';
      font-size: 14px;
      font-weight: 600;
    }

    .SubscriptionRecords-box2-time h3 {
      margin-top: 16px;
      color: #1D1D1F;
      text-align: right;
      font-family: 'MiSans-Demibold';
      font-size: 14px;
      font-weight: 600;
    }

    .SubscriptionRecords-box2-time p {
      margin-top: 6px;
      color: #999;
      font-size: 12px;
    }
  }

}