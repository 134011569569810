#CommodityDetail {
  min-height: 100vh;
  padding-bottom: 33px;
  padding-top: 20px;

  .title {
    font-weight: 700;
    font-size: 16px;
    color: var(--color);
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #header {
    background: #1d1d2c33;
    backdrop-filter: blur(9px);
  }

  .main {
    padding: 20px 0px 100px;

    .CommodityDetail-img {
      margin-top: 20px;
      width: 100%;
      height: 382px;
    }

    .box1 {
      padding: 0 15px;

      &-item {
        display: flex;
        padding: 12px;
        align-items: center;
        border-radius: 12px;
        border: 0.5px solid rgba(0, 0, 0, 0.06);
        background: rgba(0, 0, 0, 0.06);
        box-sizing: border-box;
        margin-top: 14px;

        &-img {
          width: 54px;
          margin-right: 14px;
          height: 54px;
          border-radius: 12px;
        }

        &-right {
          display: flex;
          flex-direction: column;

          &-text1 {
            color: var(--color);
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
          }

          &-text2 {
            color: var(--gray);
            font-family: 'MiSans-Demibold';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            /* 14.4px */
          }
        }
      }
    }

    .box2 {
      display: flex;
      align-items: center;
      margin-top: 14px;
      justify-content: space-between;
      padding: 0 15px;

      &-text1 {
        font-family: PingFang SC;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 24px */
        color: var(--color);
        white-space: wrap;
        width: 232px;
      }

      &-text2 {
        color: var(--gray);
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        white-space: nowrap;
        /* 16.8px */
      }
    }

    .box3 {
      padding: 0 15px;

      &-text {
        color: #1D1D1F;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        /* 14.4px */
      }

      &-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      &-item {
        display: flex;
        padding: 12px;
        align-items: center;
        border-radius: 12px;
        border: 0.5px solid rgba(0, 0, 0, 0.06);
        background: rgba(0, 0, 0, 0.06);
        box-sizing: border-box;
        width: 48%;
        margin-top: 14px;

        &-img {
          width: 54px;
          margin-right: 14px;
          border-radius: 12px;
        }

        &-text {
          color: var(--color);
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          /* 21px */
        }
      }
    }

    .box4 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 14px;
      padding: 0 15px;

      &-text {
        font-weight: 400;
        font-size: 12px;
        color: var(--color);
      }
    }

    .box7 {
      display: flex;
      flex-direction: column;
      padding: 0 15px;

      .desc {
        display: flex;
        flex-direction: column;
        color: var(--gray);
        text-align: justify;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 21px */
        margin-top: 10px;

        img {
          width: 100%;
        }
      }
    }
  }

  .CommodityDetail-footer {
    display: flex;
    padding: 26px 14px 15px 13px;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.66);
    backdrop-filter: blur(2px);
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 117px;
    box-sizing: border-box;

    &-btn {
      width: 100%;
      height: 61px;
      flex-shrink: 0;
      border-radius: 18px;
      background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4D33EF;
      box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.60) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.60) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.30) inset, -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
      color: #FFF;
      font-family: 'MiSans-Demibold';
      font-size: 17px;
      font-style: normal;
      font-weight: 900;
      line-height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      /* 188.235% */
    }
  }
}