#OnChainRecords {
  height: 100vh;
  overflow: auto;
  padding-top: 66px;
  box-sizing: border-box;

  .OnChainRecords-center {
    // border: 1px solid blue;
    height: 700px;
    overflow: auto;

    .OnChainRecords-center-box {
      margin-left: 12px;
      margin-right: 12px;
      // margin-top: 62px;
      padding-top: 14px;
      padding-bottom: 14px;
      // border: 1px solid red;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.10);

      .OnChainRecords-center-box-left {
        color: var(--black, #1D1D1F);
        font-family: 'MiSans-Demibold';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;

        /* 16.8px */
        .OnChainRecords-center-box-left-p {
          color: #999;
          margin-top: 8px;
          text-align: right;
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          /* 14.4px */
        }
      }

      .OnChainRecords-center-box-right {
        color: var(--success-success-4-hover, #56C08D);
        text-align: right;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        /* 16.8px */
      }


      .OnChainRecords-center-box-right2 {
        color: var(--error-error-5-hover, #F6685D);
        text-align: right;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        /* 16.8px */
      }
    }
  }

}