

.modalLayout {
  position: fixed;
  width: calc(100vw);
  height: calc(100vh);
  top: 0;
  left: 0;
  z-index: 50;
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.88);
    z-index: 1;
  }
  .modalContant {
    position: absolute;
    left: 50%;
    z-index: 2;
    &.center {
      top: 50%;
      transform: translate(-50%,-50%);
    }
    &.bottom {
      bottom: 0;
      transform: translateX(-50%);
    }
    &.top {
      top: 0;
      transform: translateX(-50%);
    }
  }
}