#Dela {
  padding-top: 20px;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;

  .Dela-nav {
    display: flex;
    width: 80%;
    height: 39px;
    padding: 2px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.10);
    margin: 0 auto;
    box-sizing: border-box;

    &-text {
      display: flex;
      padding: 3px 10px;
      align-items: center;
      flex: 1 0 0;
      align-self: stretch;
      overflow: hidden;
      color: var(--labels-primary-dark, #FFF);
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      justify-content: center;
      border-radius: 12px;
    }

    .active {
      background: var(--fff, #FFF);
      color: #000;
      font-weight: 600;
    }
  }
}