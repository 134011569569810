#InviteRankingList {
  height: 100vh;
  padding-top: 12px;
  color: #fff;
  background: linear-gradient(180deg, #110F2F 0%, #221F53 100%);
  box-sizing: border-box;


  .InviteRankingList-header {
    display: flex;
    align-items: center;
    // border: 1px solid red;
    margin-top: 10px;
    margin-left: 14px;
    margin-right: 14px;

    .InviteRankingList-header-topBtn {
      margin: 0 auto;
      display: flex;
      align-items: center;
      text-align: center;
      padding: 0 8px;
      height: 30px;
      border-radius: 8px;
      background: rgba(184, 180, 255, 0.12);
      color: rgba(255, 255, 255, 0.85);
      font-family: 'MiSans-Demibold';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;

      /* 16.8px */
      .InviteRankingList-header-topBtn-div {
        margin-left: 10px;
      }
    }
  }
 

  .InviteRankingList-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 11px;

    .InviteRankingList-nav-left {
      // border: 1px solid red;
      margin-bottom: 20px;
      margin-left: 24px;

      .InviteRankingList-nav-left-time {
        width: 65px;
        height: 18px;
        color: #D0E0F3;
        font-family: 'MiSans-Demibold';
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        border-radius: 99px;
        background: rgba(184, 180, 255, 0.12);
      }

      .InviteRankingList-nav-left-h2 {
        color: var(--fff, #FFF);
        font-family: 'MiSans-Demibold';
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        margin-top: 14px;
      }

      .InviteRankingList-nav-left-p {
        margin-top: 8px;
        color: #FFF;
        font-family: 'MiSans-Demibold';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        /* 14.4px */
      }
    }
  }

  .InviteRankingList-center {
    margin-left: 14px;
    margin-right: 14px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(4px);
    margin-top: -64px;
    // border: 1px solid red;


    .InviteRankingList-center-box {
      padding: 18px;

      .InviteRankingList-center-top {
        display: flex;
        justify-content: space-between;
        color: var(--fff, #FFF);
        font-family: PingFang SC;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;

        /* 129.412% */
        .InviteRankingList-center-top-p {
          color: var(--fff, #FFF);
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          /* 183.333% */
        }
      }

      .InviteRankingList-center-title {
        display: flex;
        justify-content: space-between;
        // border: 1px solid red;
        margin-top: 18px;
        margin-bottom: 4px;
        color: var(--txt, #AAB8C2);
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;

        .InviteRankingList-center-title-left {
          width: 100px;
          display: flex;
          justify-content: space-between;
          // border: 1px solid blue;
        }

      }

      .InviteRankingList-list {
        height: 55vh;
        overflow: auto;
        position: relative;

        .InviteRankingList-listBox {
          // border: 1px solid red;
          margin-top: 18px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .InviteRankingList-listBox-left {
            display: flex;
            align-items: center;
            // border: 1px solid red;
          }

          .InviteRankingList-listBox-center {
            // border: 1px solid blue;
            display: flex;
            align-items: center;
            padding-left: 28px;

            img {
              margin-right: 6px;
              border-radius: 50%;
            }
          }
        }
      }
    }


  }
}