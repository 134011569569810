#MyMiningCave {
  width: 100%;
  height: 100vh;
  padding-top: 70px;
  box-sizing: border-box;
  overflow: auto;

  .MyMiningCave-center {
    color: #fff;
    width: 100%;
    height: 146px;
    display: flex;
    overflow-x: auto;
    padding-right: 10px;
    box-sizing: border-box;

    .MyMiningCave-bronze {
      width: 293px;
      height: 146px;
      border-radius: 12px;
      background: #282828;
      white-space: nowrap;
      margin-left: 20px;
    }

    .MyMiningCave-bronze-top {
      width: 252px;
      height: 34px;
      margin-top: 10px;
      padding-top: 12px;
      margin-left: 12px;
      display: flex;
      justify-content: space-between;
      text-align: center;
      line-height: 34px;
    }

    .MyMiningCave-bronze-top div {
      width: 92px;
      height: 34px;
      margin-right: 20px;
      border-radius: 12px;
      background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4D33EF;
      box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.60) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.60) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.30) inset, -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
    }

    .MyMiningCave-bronze-accumulate {
      width: 144px;
      height: 17px;
      font-size: 12px;
      margin-top: 30px;
      margin-left: 12px;
      margin-bottom: 12px;
    }

    .MyMingCave-Progressbar {
      padding: 0 16px 0 12px;
    }

    .MyMiningCave-silver {
      width: 293px;
      height: 146px;
      border-radius: 12px;
      margin-left: 10px;
      white-space: nowrap;
      background: #6B7A97;
    }

    .MyMiningCave-team {
      width: 293px;
      height: 146px;
      border-radius: 12px;
      margin-left: 10px;
      white-space: nowrap;
      background: #202476;
    }

    .MyMiningCave-gold {
      width: 293px;
      height: 146px;
      border-radius: 12px;
      margin-left: 10px;
      white-space: nowrap;
      background: #AE9469;
    }
  }

  .MyMiningCave-foot {
    height: 66px;
    line-height: 66px;
    margin: 0 auto;
    padding: 0 14px;

    .MyGem-foot-top {
      height: 66px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    .MyMiningCave-mining {
      width: 54px;
      height: 22px;
      font-size: 18px;
      font-weight: 600;
      line-height: 120%;
      font-weight: 400;
      line-height: 120%;
      color: #999;
      font-family: PingFang SC;
    }

    .MyMiningCave-end {
      width: 54px;
      height: 22px;
      font-size: 18px;
      margin-left: 32px;
      font-weight: 400;
      line-height: 120%;
      color: #999;
      font-family: PingFang SC;
    }
  }

  .MyMiningCave-foot-Bronze {
    height: 59px;
    margin: 0 auto;
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    padding: 0 14px;

    .MyMiningCave-foot-Bronze-left {
      width: 163px;
      height: 41px;
      display: flex;

      .MyMiningCave-foot-Bronze-left-fon {
        margin-left: 12px;
        font-size: 14px;
        font-weight: 600;
        white-space: nowrap;
      }

      .MyMiningCave-foot-Bronze-left-fon div {
        margin-top: 10px;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .MyMiningCave-foot-Bronze-right {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: end;
      text-align: end;
    }

    .MyMiningCave-foot-Bronze-right h3 {
      width: 72px;
      height: 17px;
      font-size: 14px;
    }

    .MyMiningCave-foot-Bronze-right-fn {
      display: flex;
      margin-top: 10px;
    }

    .MyMiningCave-img {
      width: 26px;
      height: 26px;
    }

    .MyMiningCave-foot-Bronze-right-two {
      color: #999;
      font-size: 14px;
      font-weight: 600;
      line-height: 120%;
    }
  }

  .MyMiningCave-Popup {
    &-top {
      display: flex;
      align-items: center;

      &-img {
        border-radius: 12px;
        overflow: hidden;
      }

      &-content {
        margin-left: 8px;
        display: flex;
        flex-direction: column;

        &-text1 {
          color: var(--333, #333);
          font-family: PingFang SC;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          /* 26.4px */
        }

        &-text2 {
          color: var(--999, #999);
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          /* 24px */
        }
      }
    }

    &-list {
      display: grid;
      grid-template-columns: 48% 48%;
      justify-content: space-between;
      margin-top: 8px;
      height: 200px;
      overflow: auto;

      &-item {
        height: 50px;
        margin-top: 8px;
        display: flex;
        padding: 0px 20px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: 12px;
        /* 16.8px */
        box-sizing: border-box;
        background: var(--grey_bg, #F6F6F6);
        color: var(--black, #1D1D1F);
        font-family: 'MiSans-Demibold';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        position: relative;
        /* 16.8px */

        &-LOCK {
          position: absolute;
          right: 0;
          top: 0;
          display: flex;
          height: 17px;
          padding: 0 6px;
          justify-content: center;
          align-items: center;
          border-radius: 0px 4px;
          background: #333;
          color: var(--fff, #FFF);
          font-family: 'MiSans-Demibold';
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          box-sizing: border-box;
          /* 12px */
        }
      }

      .active {
        background: var(--purple, #4D33EF);
        mix-blend-mode: darken;
        color: var(--fff, #FFF);
        font-family: 'MiSans-Demibold';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
      }
    }
  }
}