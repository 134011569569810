#BoxDialog-opening {
    width: 330px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 2px solid rgba(255, 255, 255, 0.20);
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 0px 0px 0.9375px rgba(0, 0, 0, 0.50), 0px 16px 32px -16px rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(64px);
    padding: 24px 18px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 90px;

    .BoxDialog-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &-text {
            color: var(--fff, #FFF);
            font-family: 'MiSans-Demibold';
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 18.75px;
            /* 93.75% */
            letter-spacing: 0.1px;
        }

        &-line {
            flex: .9;
            height: 2px;
            opacity: 0.5;
            background: linear-gradient(90deg, #4940C3 5.62%, rgba(67, 59, 179, 0.20) 94.37%);
        }
    }

    .BoxDialog-img {
        margin-top: 30px;
        border-radius: 12px;
        width: 100%;
    }

    .BoxDialog-text {
        color: var(--White, #F5F8FA);
        text-align: center;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 18.75px;
        /* 117.188% */
        letter-spacing: 0.08px;
        margin-top: 14px;
    }

    .BoxDialog-btn {
        width: 100%;
        height: 61px;
        flex-shrink: 0;
        border-radius: 18px;
        background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4D33EF;
        box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.60) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.60) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.30) inset, -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFF;
        text-align: center;
        font-family: 'MiSans-Demibold';
        font-size: 17px;
        font-style: normal;
        font-weight: 900;
        line-height: 32px;
        margin-top: 41px;
        /* 188.235% */
    }

    .BoxDialog-bottom {
        display: flex;
        width: 93px;
        height: 54px;
        padding: 0px 4px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex-shrink: 0;
        border-bottom: 1px solid var(--grey_bg, #F6F6F6);
        position: absolute;
        left: 0;
        right: 0;
        bottom: -75px;
        margin: auto;

        &-text {
            color: var(--fff, #FFF);
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 19.2px */
        }
    }
}

#BoxDialog-opened {
    width: 330px;
    flex-shrink: 0;
    border-radius: 12px;
    padding: 24px 18px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    // margin-bottom: 70px;

    .BoxDialog-img {
        margin-top: 30px;
        border-radius: 12px;
        z-index: 1;
        width: 100%;
    }

    .BoxDialog-bj {
        width: 116%;
        position: absolute;
        top: -224px;
        // animation: rotate 5s linear infinite;

        // @keyframes rotate {
        //     0% {
        //         transform: rotate(0deg);
        //     }

        //     100% {
        //         transform: rotate(360deg);
        //     }
        // }
    }

    .BoxDialog-btn {
        width: 100%;
        height: 61px;
        flex-shrink: 0;
        border-radius: 18px;
        background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4D33EF;
        box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.60) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.60) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.30) inset, -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFF;
        text-align: center;
        font-family: 'MiSans-Demibold';
        font-size: 17px;
        font-style: normal;
        font-weight: 900;
        line-height: 32px;
        margin-top: 41px;
        z-index: 1;
        /* 188.235% */
    }

    .BoxDialog-bottom {
        display: flex;
        width: 128px;
        height: 54px;
        padding: 0px 4px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex-shrink: 0;
        border-bottom: 1px solid var(--grey_bg, #F6F6F6);
        position: absolute;
        left: 0;
        right: 0;
        bottom: -50px;
        margin: auto;
        z-index: 1;

        &-text {
            color: var(--fff, #FFF);
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 19.2px */
        }
    }
}