#Market {
  margin-top: 22px;
  height: 100vh;
  overflow: hidden;

  .Market-nav {
    display: flex;
    align-items: center;
    justify-content: space-around;

    &-item {
      display: flex;
      align-items: center;

      &-text {
        color: var(--gray);
        text-align: right;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
        /* 171.429% */
      }
    }
  }

  .Market-content {
    position: absolute;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 14px 18px;
    background-color: #fff;
    margin-top: 13px;
    height: 700px;
    overflow: auto;
    padding-bottom: 100px;

    &-search {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      background: #F6F6F6;
      height: 38px;

      &-input {
        margin-left: 4px;
        background-color: #F6F6F6;
        border: none;
        height: 100%;

        &::placeholder {
          color: var(--gray);
        }
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &-item {
        display: flex;
        flex-direction: column;
        padding: 12px;
        box-sizing: border-box;
        border-radius: 12px;
        background: var(--grey_bg, #F6F6F6);
        margin-top: 14px;
        width: 48%;
        position: relative;

        &-grade {
          position: absolute;
          left: 16px;
          top: 21px;
        }

        &-img {
          width: 100%;
          border-radius: 12px;
          border: 1px solid var(--e1e1e1, #E1E1E1);
          height: 144px;
          object-fit: contain;
        }

        &-text1 {
          color: var(--black, #1D1D1F);
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          margin-top: 7px;
          /* 21px */
        }

        &-text2 {
          color: var(--black, #1D1D1F);
          font-family: 'MiSans-Demibold';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          margin-top: 12px;
        }
      }
    }
  }
}