#Password {
  width: 100%;
  min-height: 100vh;

  .noClass {
    display: none;
  }

  .Password-h3 {
    // border: 1px solid red;
    padding-top: 90px;
    margin-left: 16px;
    margin-right: 14px;
    color: var(--black, #1D1D1F);
    font-family: PingFang SC;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    // border: 1px solid red;
  }

  .Password-center {
    // border: 1px solid red;
    margin-left: 17px;
    margin-right: 17px;
    padding-top: 32px;

    .Password-inp {
      width: 100%;
      // border: 1px solid red;
      position: relative;
      margin-right: 14px;

      .Password-inp-one {
        width: 100%;
        height: 52px;
        padding-left: 20px;
        border-radius: 10px;
        background: #F6F6F6;
        outline: none;
        border: none;
        // border: 1px solid blue;
        color: #000;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        box-sizing: border-box;
      }

      .Password-inp-one::placeholder {
        color: #999;
      }

      .Password-inp-one-send {
        min-width: 48px;
        padding: 0 4px;
        height: 28px;
        border-radius: 8px;
        border: 1px solid #000;
        color: #000;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        text-align: center;
        line-height: 28px;
        position: absolute;
        top: 20%;
        right: 2%;
        /* 14.4px */
      }
    }

    .Password-inputBox {
      margin-top: 14px;
      // border: 1px solid red;

      .Password-inputBox-eye {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
      }

      .Password-inputBox-inp1 {
        width: 100%;
        height: 52px;
        padding-left: 18px;
        border-radius: 12px;
        background: #F6F6F6;
        outline: none;
        border: none;
        color: #000;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        box-sizing: border-box;
        /* 157.143% */
      }

      .Password-inputBox-inp1::placeholder {
        color: #999;
      }

      .Password-inputBox-inp2 {
        width: 100%;
        height: 52px;
        padding-left: 18px;
        border-radius: 12px;
        background: #F6F6F6;
        outline: none;
        border: none;
        color: #000;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        box-sizing: border-box;
      }

      .Password-inputBox-inp2::placeholder {
        color: #999;
      }
    }
  }


  .Password-center-notice {
    display: flex;
    margin-top: 16px;

    .Password-center-notice-checkbox {
      margin-left: 6px;
      border: none;
    }
    /* 设置未选中样式 */
    input[type="checkbox"] {
      position: relative;
      width: 16px;
      height: 16px;
      line-height: 16px;
      border: 1px solid #949494;
      border-radius: 4px;
      margin-top: 2px;

      /* 取消默认样式 */
      -webkit-appearance: none;
    }

    /* 设置选中样式 */
    input[type="checkbox"]:checked {
      background-color: #E1E1E1;
      border-radius: 4px;
      border: none;
    }

    input[type="checkbox"]:checked::after {
      content: "✓";
      position: absolute;
      top: 0;
      left: 1px;
      width: 15px;
      height: 15px;
      color: #fff;
      text-align: center;
    }

   

    .Password-center-notice-p {
      margin-left: 4px;
      color: #999;
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;

      /* 183.333% */
      .Password-center-notice-p-a {
        color: var(--purple, #4D33EF);
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }

      .giveRoug-center-notice-p-a {
        color: var(--purple, #4D33EF);
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }
    }
  }

  .Password-btn-box {
    margin-top: 16px;

    .Password-btn {
      // border: 1px solid red;
      height: 52px;
      border-radius: 12px;
      background: var(--black, #1D1D1F);
      color: #FFF;
      text-align: center;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 52px;
    }
  }

}