#score {
  min-height: 100vh;
  background-color: #00081E;
  background-image: url('../../../assets/imgs/image 491.png');
  background-repeat: no-repeat;
  background-position: 100% 0%;
  background-size: 81% auto;
  padding: 30px 14px;

  .box1 {
    margin-top: 35px;

    &-text1 {
      color: #FFF;
      text-align: center;
      font-family: 'MiSans-Demibold';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      img {
        margin-left: 2px;
      }
    }

    &-text2 {
      color: #FFF;
      text-align: center;
      font-family: 'MiSans-Demibold';
      font-size: 22px;
      font-style: normal;
      font-weight: 900;
      line-height: 22px;
      /* 100% */
    }
  }

  .btn {
    border-radius: 18px;
    background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4D33EF;
    box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.60) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.60) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.30) inset, -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
    color: #FFF;
    text-align: center;
    font-family: 'MiSans-Demibold';
    font-size: 17px;
    font-style: normal;
    font-weight: 900;
    line-height: 32px;
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    /* 188.235% */
  }

  .box2 {
    border-bottom: 1px solid var(--grey_bg, #F6F6F6);
    color: #FFF;
    font-family: PingFang SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    width: 128px;
    margin: 10px auto;
    padding: 15px 0;
    /* 19.2px */
  }

  .box3 {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    &-title {
      color: #FFF;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      /* 19.2px */
    }

    &-content {
      margin-top: 14px;
      color: rgba(255, 255, 255, 0.70);
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 175%;
      /* 24.5px */
    }
  }

  .box4 {
    width: 100%;
    margin: 32px 0;
  }

  .box5 {
    display: flex;
    padding: 14px 22px;
    justify-content: space-between;
    align-items: flex-start;
    gap: 37px;
    align-self: stretch;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.06);
    margin-top: 22px;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-img {}

      &-text {
        color: var(--white, #FFF);
        text-align: center;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 6px;
      }
    }
  }
}