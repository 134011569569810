#AboutUs {
  height: 100vh;
  padding-top: 62px;
  box-sizing: border-box;

  .AboutUs-logo {
    padding-top: 9%;
    padding-bottom: 30px;

    .AboutUs-logo-box {
      width: 103px;
      height: 120px;
      margin: 0 auto;
    }

    .AboutUs-logo-img1 {
      width: 77px;
      height: 77px;
      margin-left: 12%;
    }

    .AboutUs-logo-img2 {
      margin-top: 12px;
    }
  }

  .AboutUs-Terms-box {
    // border: 1px solid red;
    margin-top: 20px;
    margin-left: 22px;
    margin-right: 22px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    color: #000;
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: #E1E1E1;
      transform: scaleY(50%);
    }
  }

  .AboutUsICP {
    color: #999;
    font-family: PingFang SC;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
  }
}