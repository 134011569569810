#ConsignmentGoods {
  height: 100vh;
  padding-bottom: 50px;
  box-sizing: border-box;
  overflow: auto;

  .ConsignmentGoods-select {
    position: fixed;
    left: 0;
    top: 60px;
    z-index: 1;
    background-color: #fff;

    &-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      // height: 100px;
      //   width: 800px;
      padding: 14px 10px 0;

      &-item {
        font-size: 14px;
        color: #AAB8C2;
        padding-bottom: 10px;
      }

      .active {
        color: var(--color);
        position: relative;
        font-weight: bold;
      }

      .active::after {
        content: '';
        height: 3px;
        width: 100%;
        background: #4D33EF;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  .ConsignmentGoods-list {
    width: 370px;
    height: 197px;
    margin: 0 auto;
    border-radius: 18px;

    &-noData {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 90px 0;

      .synthesis-img {
        width: 110px;
        height: auto;
      }

      .synthesis-text {
        font-size: 16px;
        margin-top: 8 px;
        color: #AAB8C2;
      }
    }
  }



  .order-title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    background: #1d1d2c88;
    backdrop-filter: blur(6px);
    top: 0;
    left: 0;
    padding: 14px;
    z-index: 1;
    box-sizing: border-box;

    &-item {
      position: relative;
      margin: 0 19px;

      &-text {
        font-weight: 700;
        font-size: 17px;
        color: #FFFFFF;
      }

      &-img {
        width: 13px;
        position: absolute;
        bottom: -2.5px;
        right: 0;
        left: 0;
        margin: 0 auto;
      }
    }

    &-img {
      position: absolute;
      left: 14px;
    }
  }

  .order-select {
    position: fixed;
    left: 0;
    top: 60px;
    z-index: 1;
    background-color: #fff;
    border-bottom: 1px solid #AAB8C222;

    &-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 14px 10px 0;

      &-item {
        font-size: 14px;
        color: #AAB8C2;
        padding-bottom: 10px;
      }

      .active {
        color: var(--color);
        position: relative;
        font-weight: bold;
      }

      .active::after {
        content: '';
        height: 3px;
        width: 100%;
        background: #4D33EF;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  .order-box2 {
    background: #F6F6F6;
    border-radius: 4px;
    padding: 12px;
    margin-top: 18px;
    color: var(--color);

    &-top {
      display: flex;
      position: relative;
      padding-bottom: 12px;

      .order-box2-top-img {
        width: 60px;
        height: 60px;
        border-radius: 4px;
        margin-right: 12px;
      }

      .order-box2-top-img2 {
        width: 78px;
        height: 78px;
        border-radius: 12px;
        margin-right: 12px;
      }

      &-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        &-title {
          font-weight: 700;
          width: 258px;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .order-box2-top-container-title-div {
            padding-bottom: 16px;
          }

          .order-box2-top-container-title-num {
            color: #666;
            text-align: center;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            text-align: right;
          }
        }

        &-text {
          font-size: 12px;
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: flex-end;
          font-family: ''MiSans-Demibold'-demibold';

          .order-box2-top-container-text-img {
            margin-right: 5px;
            width: 14.67px;
            height: 14px;
          }

          .order-box2-top-container-status {
            height: 21px;
            font-weight: 700;
            font-size: 10px;
            color: #fff;
            background: rgba(68, 117, 242, 0.12);
            border: 1px solid #4475F2;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 5px;
          }

          .order-box-ing {
            padding: 0 8px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-family: PingFang SC;
            font-size: 10px;
            font-style: normal;
            border-radius: 8px;
            border: 1px solid #666;
            color: #333;
            font-family: PingFang SC;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            background-color: #fff;
          }

          .one {
            width: 46px;
            height: 24px;
            text-align: center;
            line-height: 24px;
            border-radius: 8px;
            border: 1px solid #666;
            margin-left: 210px;
          }
        }

        &-write {
          display: flex;
          align-items: center;

          &-via {
            width: 14px;
            height: 14px;
            border-radius: 11px;
            margin-right: 7px;
          }

          &-text {
            font-weight: 400;
            font-size: 10px;
            color: #F5F8FA;
          }
        }

        &-loaction {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 7.5px;
          margin-left: 2.5px;
          width: 100%;
          position: relative;

          &-img {
            width: 9.6px;
            height: 12.8px;
          }

          &-text {
            font-weight: 400;
            font-size: 12.5px;
            color: #AAB8C2;
            width: 125px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &-status {
            padding: 2px 8px;
            border-radius: 4px;
            border: 1px solid var(--purple, #4D33EF);
            background: rgba(68, 117, 242, 0.04);
            color: var(--purple, #4D33EF);
            font-family: PingFang SC;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            position: absolute;
            right: 0;
          }
        }
      }
    }

    &-top::after {
      content: '';
      height: 1px;
      width: 100%;
      background: rgba(170, 184, 194, 0.22);
      transform: scaleY(50%);
      position: absolute;
      bottom: 0;
    }

    .GivenPay-box1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      padding-bottom: 10px;
      border-bottom: 1px solid #606C77;

      &-left {
        display: flex;
        flex-direction: column;

        &-text1 {
          font-weight: 400;
          font-size: 12px;
          color: #AAB8C2;
          margin-bottom: 2 px;
        }

        &-text2 {
          font-family: ''MiSans-Demibold'-demibold';
          font-size: 15px;
          color: #FFFFFF;
        }
      }

      &-img {}
    }

    &-data {
      margin-top: 12px;

      &-title {
        font-weight: 700;
        font-size: 16px;
        color: #F5F8FA;
      }

      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        color: var(--gray);
        margin-top: 5px;
      }
    }
  }

  .wait_paid {
    background: rgba(255, 79, 53, 0.12);
    border: 2px solid #FF4F35;
    color: #FF4F35;
  }

  .create {
    background: rgba(255, 79, 53, 0.12);
    border: 2px solid #FF4F35;
    color: #FF4F35;
  }

  .cancel {
    background: rgba(170, 184, 194, 0.12);
    border: 2px solid #AAB8C2;
    color: #AAB8C2;
  }

  .close {
    background: rgba(170, 184, 194, 0.12);
    border: 2px solid #AAB8C2;
    color: #AAB8C2;
  }

  .refund {
    background: rgba(170, 184, 194, 0.12);
    border: 2px solid #AAB8C2;
    color: #AAB8C2;
  }

  .fail {
    background: rgba(170, 184, 194, 0.12);
    border: 2px solid #AAB8C2;
    color: #AAB8C2;
  }

  .partial_success {
    background: rgba(170, 184, 194, 0.12);
    border: 2px solid #AAB8C2;
    color: #AAB8C2;
  }

  .receive_success {
    background: rgba(68, 117, 242, 0.12);
    border: 2px solid #11D000;
    color: #11D000;
  }

  .confirm {
    background: rgba(68, 117, 242, 0.12);
    border: 2px solid #11D000;
    color: #11D000;
  }

  .success {
    background: rgba(68, 117, 242, 0.06);
    border: 2px solid #4475F2;
    color: #4475F2;
  }



  .ConsignmentGoods-select {
    position: fixed;
    left: 0;
    top: 60px;
    z-index: 1;
    background-color: #fff;

    &-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 14px 10px 0;

      &-item {
        font-size: 14px;
        color: #AAB8C2;
        padding-bottom: 10px;
      }

      .active {
        color: var(--color);
        position: relative;
        font-weight: bold;
      }

      .active::after {
        content: '';
        height: 3px;
        width: 100%;
        background: #4D33EF;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  .ConsignmentGoods-list2 {
    width: 370px;
    height: 197px;
    margin: 0 auto;
    border-radius: 18px;
    margin-top: 35px;

    .order-box2-top-container-title-num {
      color: #666;
      text-align: center;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      text-align: right;
      margin-top: 4px;
    }
  }

  .ConsignmentGoods-list3 {
    width: 370px;
    height: 197px;
    margin: 0 auto;
    border-radius: 18px;
  }


  .not_delivered,
  .wait_paid {
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #fff !important;
    font-size: 10px;
    border: 1px solid var(--purple, #4D33EF) !important;
    background: var(--purple, #4D33EF) !important;
    /* 200% */
  }

  .success {
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #fff !important;
    font-size: 10px;
    background: #000 !important;
    /* 200% */
  }

}