#AuthModal {
    background-color: var(--box-bj);
    height: 214px;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
    border-radius: 12px;

    .AuthModal-text1 {
        font-size: 18px;
        font-weight: 600;
    }

    .AuthModal-text2 {
        font-size: 16px;
        text-align: center;
        color: rgba(0, 0, 0, 0.60);
        margin-bottom: 24px;
        margin-top: 10px;
    }

    .AuthModal-btn {
        background-color: var(--btn-color);
        border: 1px solid #000;
        border-radius: 12px;
        display: flex;
        height: 52px;
        padding: 0px 4px;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
    }
}