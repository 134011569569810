#Universe {
  width: 100%;
  position: relative;
  padding-bottom: 100px;

  .Universe-title {
    padding-top: 45px;
    margin: 0 auto;
    display: block;
    width: 80%;
  }

  .Universe-btn {
    height: 61px;
    border-radius: 18px;
    background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4D33EF;
    box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.60) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.60) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.30) inset, -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 14px;

    &-top {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        color: #FFF;
        text-align: center;
        font-family: 'MiSans-Demibold';
        font-size: 17px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
      }
    }

    &-bottom {
      color: rgba(255, 255, 255, 0.66);
      text-align: center;
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .Universe-text {
    display: flex;
    padding: 6px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 14px;
    border: 2px solid rgba(255, 255, 255, 0.08);
    background: #1C1E25;
    color: rgba(255, 255, 255, 0.66);
    text-align: center;
    font-family: 'MiSans-Demibold';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    box-sizing: border-box;
    width: 200px;
    margin: 22px auto;

    /* 157.143% */
    .Universe-text-span {
      color: rgba(255, 255, 255, 0.66);
      font-family: 'MiSans-Demibold';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
    }
  }

  .Universe-svg {
    width: 100%;
    position: absolute;
    height: 220px;
    left: 0;
    overflow: hidden;

    img {
      width: 120%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -83px;
    }
  }


  .Universe-box1 {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    margin-top: 264px;
    // border: 1px solid red;

    .Universe-box1-title {
      color: #FFF;
      // border: 1px solid blue;
      display: flex;
      align-items: center;

      .Universe-box1-h3 {
        color: #FFF;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 19.2px */
      }
    }

    .Universe-box1-itemBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &-item {
        // border: 1px solid red;
        // border-radius: 10px;
        // background: linear-gradient(180deg, #090909 0%, rgba(68, 68, 68, 0.54) 100%);
        border-radius: 10px;
        background: linear-gradient(180deg, #212736 0%, #2D3876 100%);
        height: 74px;
        width: 48%;
        padding-top: 12px;
        padding-left: 10px;
        box-sizing: border-box;
        margin-top: 14px;
        display: flex;
        position: relative;
        overflow: hidden;

        &-top {
          display: flex;
          flex-direction: column;

          &-text1 {
            color: var(--fff, #FFF);
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          &-text2 {
            color: rgba(255, 255, 255, 0.66);
            font-family: PingFang SC;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 3px;
          }
        }

        img {
          height: 100%;
          position: absolute;
          right: 2px;
          top: 9px;
        }
      }

      &-item2 {
        // border: 1px solid red;
        border-radius: 10px;
        background: linear-gradient(180deg, #191A31 0%, #3B117F 100%);
        height: 74px;
        width: 48%;
        padding-top: 12px;
        padding-left: 10px;
        box-sizing: border-box;
        margin-top: 14px;
        display: flex;
        position: relative;
        overflow: hidden;

        &-top {
          display: flex;
          flex-direction: column;

          &-text1 {
            color: var(--fff, #FFF);
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          &-text2 {
            color: rgba(255, 255, 255, 0.66);
            font-family: PingFang SC;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 3px;
          }
        }

        img {
          height: 100%;
          position: absolute;
          right: 2px;
          top: 9px;
        }
      }

      &-item3 {
        // border: 1px solid red;
        border-radius: 10px;
        background: linear-gradient(180deg, #2B1C18 0%, #704235 100%);
        height: 74px;
        width: 48%;
        padding-top: 12px;
        padding-left: 10px;
        box-sizing: border-box;
        margin-top: 14px;
        display: flex;
        position: relative;
        overflow: hidden;

        &-top {
          display: flex;
          flex-direction: column;

          &-text1 {
            color: var(--fff, #FFF);
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          &-text2 {
            color: rgba(255, 255, 255, 0.66);
            font-family: PingFang SC;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 3px;
          }
        }

        img {
          height: 100%;
          position: absolute;
          right: 2px;
          top: 9px;
        }
      }

      &-item4 {
        // border: 1px solid red;
        border-radius: 10px;
        background: linear-gradient(180deg, #2B1818 0%, #712424 100%);
        height: 74px;
        width: 48%;
        padding-top: 12px;
        padding-left: 10px;
        margin-left: 10px;
        box-sizing: border-box;
        margin-top: 14px;
        display: flex;
        position: relative;
        overflow: hidden;

        &-top {
          display: flex;
          flex-direction: column;

          &-text1 {
            color: var(--fff, #FFF);
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          &-text2 {
            color: rgba(255, 255, 255, 0.66);
            font-family: PingFang SC;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 3px;
          }
        }

        img {
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }

  }

  .Universe-box2 {
    // border: 1px solid red;
    margin-top: 14px;

    .Universe-box1-title {
      color: #FFF;
      // border: 1px solid blue;
      display: flex;
      align-items: center;

      .Universe-box1-h3 {
        color: #FFF;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 19.2px */
      }
    }

    .Universe-box1-itemBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      // border: 1px solid red;

      &-item {
        // border: 1px solid red;
        border-radius: 10px;
        background: linear-gradient(180deg, #090909 0%, rgba(68, 68, 68, 0.54) 100%);
        height: 74px;
        width: 48%;
        padding-top: 12px;
        padding-left: 10px;
        box-sizing: border-box;
        margin-top: 14px;
        display: flex;
        position: relative;
        overflow: hidden;

        &-top {
          display: flex;
          flex-direction: column;

          &-text1 {
            color: var(--fff, #FFF);
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          &-text2 {
            color: rgba(255, 255, 255, 0.66);
            font-family: PingFang SC;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 3px;
          }
        }

        img {
          height: 100%;
          position: absolute;
          right: 0px;
          top: 9px;
        }
      }

      &-item4 {
        // border: 1px solid red;
        border-radius: 10px;
        background: linear-gradient(180deg, #090909 0%, rgba(68, 68, 68, 0.54) 100%);
        height: 74px;
        width: 48%;
        padding-top: 12px;
        padding-left: 10px;
        margin-left: 10px;
        box-sizing: border-box;
        margin-top: 14px;
        display: flex;
        position: relative;
        overflow: hidden;

        &-top {
          display: flex;
          flex-direction: column;

          &-text1 {
            color: var(--fff, #FFF);
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          &-text2 {
            color: rgba(255, 255, 255, 0.66);
            font-family: PingFang SC;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 3px;
          }
        }

        img {
          height: 100%;
          position: absolute;
          right: 0px;
          top: 9px;
        }
      }
    }

  }

  .Universe-btn {
    margin-top: 20px;
    height: 61px;
    flex-shrink: 0;
    border-radius: 18px;
    background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4D33EF;
    box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.60) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.60) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.30) inset, -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'MiSans-Demibold';
    font-size: 17px;
    font-style: normal;
    font-weight: 900;
    line-height: 32px;
    box-sizing: border-box;
  }

  .Universe-foot {
    width: 358px;
    height: 156px;
    border-radius: 12px;
    // background: linear-gradient(to bottom, #000000, #333333);
    background-color: #202020;
    margin: 0 auto;
    margin-top: 14px;
    color: #fff;

    .Universe-foot-box1 {
      display: flex;
      padding-top: 22px;
      padding-left: 14px;
      width: 313px;

      // border: 1px solid red;
      .Universe-foot-box1-play {
        margin-left: 4px;
        margin-top: 2px;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
      }
    }

    .Universe-foot-text {
      width: 313px;
      height: 72px;
      padding-top: 14px;
      padding-left: 14px;
      // border: 1px solid red;
      color: var(--txt, #AAB8C2);
      text-align: justify;
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      /* 18px */
    }
  }

  .Universe-foot2 {
    width: 358px;
    // height: 156px;
    border-radius: 12px;
    // background: linear-gradient(to bottom, #000000, #333333);
    background-color: #202020;
    margin: 0 auto;
    margin-top: 14px;
    color: #fff;

    .Universe-foot-box1 {
      display: flex;
      padding-top: 22px;
      padding-left: 14px;
      width: 313px;

      // border: 1px solid red;
      .Universe-foot-box1-play {
        margin-left: 4px;
        margin-top: 2px;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
      }
    }

    .Universe-foot-text {
      width: 313px;
      // height: 72px;
      padding-top: 14px;
      padding-left: 14px;
      padding-bottom: 14px;
      // border: 1px solid red;
      color: var(--txt, #AAB8C2);
      text-align: justify;
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      /* 18px */
    }
  }
}