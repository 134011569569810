#order {
  height: 100vh;
  padding: 20px 14px;
  box-sizing: border-box;
  overflow: auto;

  .order-list {
    padding-top: 90px;

    &-noData {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 90px 0;

      .synthesis-img {
        width: 110px;
        height: auto;
      }

      .synthesis-text {
        // font-weight: 600;
        font-size: 16px;
        margin-top: 8 px;
        color: #666;
      }
    }
  }

  .order-title {
    padding-top: 15px;
    margin-left: 18px;
    margin-right: 18px;
    display: flex;
    align-items: center;

    &-right {
      width: 265px;
      height: 39px;
      // border: 1px solid blue;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 0 auto;
      border-radius: 12px;
      background: #F3F3F3;
      font-size: 14px;

      &-sell {
        padding: 0 10px;
        height: 32px;
        border-radius: 10px;
        overflow: hidden;
        color: #1D1D1F;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
      }

      .switch {
        background: #fff;
      }
    }
  }



  .order-title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    background: #1d1d2c88;
    backdrop-filter: blur(6px);
    top: 0;
    left: 0;
    padding: 14px;
    z-index: 1;
    box-sizing: border-box;

    &-item {
      position: relative;
      margin: 0 19px;

      &-text {
        font-weight: 700;
        font-size: 17px;
        color: #FFFFFF;
      }

      &-img {
        width: 13px;
        position: absolute;
        bottom: -2.5px;
        right: 0;
        left: 0;
        margin: 0 auto;
      }
    }

    &-img {
      position: absolute;
      left: 14px;
    }
  }

  .order-select {
    position: fixed;
    left: 0;
    top: 60px;
    z-index: 1;
    background-color: #fff;
    border-bottom: 1px solid #AAB8C222;

    &-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      // height: 100px;
      //   width: 800px;
      padding: 14px 10px 0;

      &-item {
        font-size: 14px;
        color: #666;
        padding-bottom: 10px;
      }

      .active {
        color: var(--color);
        position: relative;
        font-weight: bold;
      }

      .active::after {
        content: '';
        height: 3px;
        width: 100%;
        background: #4D33EF;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  .order-box2 {
    background: #F6F6F6;
    border-radius: 4px;
    padding: 12px;
    margin-top: 18px;
    color: var(--color);

    &-top {
      display: flex;
      position: relative;
      padding-bottom: 12px;

      .order-box2-top-img {
        width: 60px;
        height: 60px;
        border-radius: 4px;
        margin-right: 12px;
      }

      .order-box2-top-img2 {
        width: 78px;
        height: 78px;
        margin-right: 12px;
        border-radius: 12px;
        object-fit: contain;
      }

      &-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        &-title {
          font-weight: 700;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &-text {
          // font-weight: 600;
          font-size: 12px;
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          font-family: ''MiSans-Demibold'-demibold';

          .order-box2-top-container-text-img {
            margin-right: 5px;
            width: 14.67px;
            height: 14px;
          }

          .order-box2-top-container-status {
            height: 21px;
            font-weight: 700;
            font-size: 10px;
            color: #4475F2;
            background: rgba(68, 117, 242, 0.12);
            border: 1px solid #4475F2;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 5px;
          }
        }

        &-write {
          display: flex;
          align-items: center;

          &-via {
            width: 14px;
            height: 14px;
            border-radius: 11px;
            margin-right: 7px;
          }

          &-text {
            font-weight: 400;
            font-size: 10px;
            color: #F5F8FA;
          }
        }

        &-loaction {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 7.5px;
          margin-left: 2.5px;
          width: 100%;
          position: relative;

          &-img {
            width: 9.6px;
            height: 12.8px;
          }

          &-text {
            font-weight: 400;
            font-size: 12.5px;
            color: #666;
            width: 125px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &-status {
            padding: 2px 8px;
            border-radius: 4px;
            border: 1px solid var(--purple, #4D33EF);
            background: rgba(68, 117, 242, 0.04);
            color: var(--purple, #4D33EF);
            font-family: PingFang SC;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            position: absolute;
            right: 0;
            /* 200% */
          }
        }
      }
    }

    &-top::after {
      content: '';
      height: 1px;
      width: 100%;
      background: rgba(170, 184, 194, 0.22);
      transform: scaleY(50%);
      position: absolute;
      bottom: 0;
    }

    .GivenPay-box1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      padding-bottom: 10px;
      border-bottom: 1px solid #606C77;

      &-left {
        display: flex;
        flex-direction: column;

        &-text1 {
          font-weight: 400;
          font-size: 12px;
          color: #666;
          margin-bottom: 2 px;
        }

        &-text2 {
          font-family: ''MiSans-Demibold'-demibold';
          font-size: 15px;
          color: #FFFFFF;
        }
      }

      &-img {}
    }

    &-data {
      margin-top: 12px;

      &-title {
        font-weight: 700;
        font-size: 16px;
        color: #F5F8FA;
      }

      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        color: var(--gray);
        margin-top: 5px;
      }
    }
  }

  .wait_paid {
    background: rgba(255, 79, 53, 0.12);
    border: 2px solid #FF4F35;
    color: #FF4F35;
  }

  .create {
    background: rgba(255, 79, 53, 0.12);
    border: 2px solid #FF4F35;
    color: #FF4F35;
  }

  .cancel {
    background: rgba(170, 184, 194, 0.12);
    border: 2px solid #666;
    color: #666;
  }

  .close {
    background: rgba(170, 184, 194, 0.12);
    border: 2px solid #666;
    color: #666;
  }

  .refund {
    background: rgba(170, 184, 194, 0.12);
    border: 2px solid #666;
    color: #666;
  }

  .fail {
    background: rgba(170, 184, 194, 0.12);
    border: 2px solid #666;
    color: #666;
  }

  .partial_success {
    background: rgba(170, 184, 194, 0.12);
    border: 2px solid #666;
    color: #666;
  }

  .receive_success {
    background: rgba(68, 117, 242, 0.12);
    border: 2px solid #11D000;
    color: #11D000;
  }

  .confirm {
    background: rgba(68, 117, 242, 0.12);
    border: 2px solid #11D000;
    color: #11D000;
  }

  .success {
    background: rgba(68, 117, 242, 0.06);
    border: 2px solid #4475F2;
    color: #4475F2;
  }

  .SellAndSold-box1 {
    margin: 14px 0;
    // border: 1px solid red;
    border-radius: 12px;
    background: #F6F6F6;

    &-top {
      padding-top: 12px;
      padding-bottom: 12px;
      margin-left: 12px;
      margin-right: 12px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(170, 184, 194, 0.22);

      &-left {
        display: flex;

        >img {
          width: 52px;
          height: 52px;
          border-radius: 12px;
        }

        span {
          font-size: 12px;
          color: #333;
        }

        &-h3 {
          color: #333;
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          margin-left: 12px;

          /* 19.2px */
        }

        &-two {
          color: var(--333, #333);
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          margin-left: 12px;
          margin-bottom: 20px;
          /* 19.2px */
        }
      }

      &-right {
        color: #333;
        font-family: 'MiSans-Demibold';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        text-align: right;

        &-num {
          color: #666;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          margin-top: 4px;
          /* 16.8px */
        }

        &-span {
          color: #333;
          text-align: center;
          font-family: 'MiSans-Demibold';
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          /* 12px */
        }
      }
    }

    &-bottom {
      padding-top: 12px;
      padding-bottom: 12px;
      margin-left: 12px;
      margin-right: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border: 1px solid red;


      &-left {
        color: #333;
        font-family: 'MiSans-Demibold';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;

        &-span {
          color: #333;
          text-align: center;
          font-family: 'MiSans-Demibold';
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          /* 12px */
        }
      }

      &-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // border: 1px solid red;

        &-price {
          width: 56px;
          height: 29px;
          border-radius: 6px;
          background: var(--black, #1D1D1F);
          display: flex;
          align-items: center;
          color: var(--fff, #FFF);
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

          &-img {
            margin-left: 8px;
            margin-right: 2px;
          }
        }

        &-end {
          width: 56px;
          height: 29px;
          border-radius: 6px;
          border: 1px solid rgba(255, 33, 0, 0.19);
          background: rgba(255, 21, 0, 0.04);
          display: flex;
          align-items: center;
          margin-left: 12px;

          &-img {
            margin-left: 8px;
            margin-right: 2px;
          }

          &-text {
            background: linear-gradient(180deg, #BC382C 0%, #FF5C00 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }

    &-bottom2 {
      padding-top: 12px;
      padding-bottom: 12px;
      margin-left: 12px;
      margin-right: 12px;
      // border: 1px solid red;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-left {
        color: #666;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 175%;
        /* 24.5px */
      }

      &-right {
        color: #666;
        font-family: 'MiSans-Demibold';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%;
        text-align: right;

        &-num {
          text-align: right;
          font-family: 'MiSans-Demibold';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 175%;
          /* 24.5px */
        }
      }
    }

    .transfer_state {
      padding: 2px 8px;
      border-radius: 4px;
      border: 1px solid var(--purple, #4D33EF);
      background: rgba(68, 117, 242, 0.04);
      color: var(--purple, #4D33EF);
      font-family: PingFang SC;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      height: 20px;
    }

    .GiveNft-top-right-center {
      display: flex;
      align-items: flex-start;
      color: #999;
      font-family: 'MiSans-Demibold';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      margin-top: 4px;
      /* 14.4px */

      &-text {
        margin-left: 4px;
      }
    }
  }
}