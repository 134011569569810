#Destroy {
  width: 100%;
  height: 100vh;
  padding-top: 62px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  .Destroy-bronze {
    border-radius: 12px;
    background: #191A1E;
    color: #fff;
    // border: 1px solid red;
    margin: 0 18px;
    padding: 22px 17.5px;

    .Destroy-bronze-top {
      height: 34px;
      display: flex;
      // border: 1px solid red;
      justify-content: space-between;
      align-items: center;


      .Destroy-bronze-top-left {
        height: 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #FFF;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        border-radius: 65px;
        border: 1px solid rgba(255, 255, 255, 0.12);
        background: rgba(255, 255, 255, 0.04);

        .Destroy-bronze-top-left-text {
          padding-left: 10px;
        }

        /* 183.333% */
        .Destroy-bronze-top-left-img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
          border-radius: 50%;
        }

        .Destroy-bronze-top-left-name {
          padding-right: 10px;
          // max-width: 60px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .Destroy-bronze-top-right {
        color: #FFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: 'MiSans-Demibold';
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: 22px;
        display: flex;
        justify-content: space-between;

        .Destroy-bronze-top-right-img {
          width: 18px;
          height: 18px;
          margin-top: 2px;
          padding-right: 6px;
        }
      }
    }

    .Destroy-bronze-accumulate {
      margin-top: 34px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
    }

    .Destroy-bronze-btn {
      display: flex;
      justify-content: space-between;
      margin-top: 22px;

      &-item {
        display: flex;
        height: 44px;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.12);
        background: rgba(255, 255, 255, 0.08);
        color: #FFF;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        flex: 0.48;
      }
    }

  }


  .Destroy-center {
    width: 100%;

    // border: 1px solid red;
    .Destroy-center-title {
      // width: 346px;
      padding-left: 24px;
      height: 58px;
      // border: 1px solid red;
      margin: 0 auto;
      margin-top: 20px;

      .Destroy-center-title-h3 {
        color: #333;
        font-family: PingFang SC;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 14px;
        /* 24px */
      }

      .Destroy-center-title-div {
        display: flex;

        .Destroy-center-title-box {
          height: 20px;
          display: flex;
          align-items: center;
          margin-right: 8px;

          .Destroy-center-title-box-left {
            width: 36px;
            border-radius: 4px 0px 0px 4px;
            background: var(--black, #1D1D1F);
            color: var(--fff, #FFF);
            text-align: center;
            font-family: PingFang SC;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
          }

          .Destroy-center-title-box-right {
            padding: 0 4px;
            border-radius: 0px 4px 4px 0px;
            background: var(--grey_bg, #F6F6F6);
            color: var(--black, #1D1D1F);
            text-align: center;
            font-family: PingFang SC;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            /* 160% */
          }
        }
      }

    }

    .Destroy-center-sort {
      height: 52px;
      border-top: 1px solid var(--e1e1e1, #E1E1E1);
      border-bottom: 1px solid var(--e1e1e1, #E1E1E1);
      margin: 0 24px;
      // padding-left: 24px;
      // margin-left: 24px;
      margin-top: 14px;
      display: flex;
      justify-content: space-around;

      .Destroy-center-sort-left {
        width: 168px;
        line-height: 52px;
        display: flex;
        color: var(--purple, #999);
        text-align: right;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;

        // border: 1px solid blue;
        .Destroy-center-sort-left-text {
          padding-left: 40px;
        }

        /* 171.429% */
        .Destroy-center-sort-left-img {
          width: 24px;
          height: 24px;
          margin-top: 14px;
          margin-left: 4px;
        }
      }

      .Destroy-center-sort-right {
        width: 168px;
        line-height: 52px;
        // border: 1px solid red;
        display: flex;
        color: var(--purple, #999);
        text-align: right;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;

        .Destroy-center-sort-right-text {
          padding-left: 40px;
        }

        /* 171.429% */
        .Destroy-center-sort-right-img {
          width: 24px;
          height: 24px;
          margin-top: 14px;
          margin-left: 4px;
        }
      }
    }

    .Destroy-center-foot {
      padding-left: 24px;
      padding-right: 24px;
      height: 440px;
      overflow: auto;
      padding-bottom: 250px;
      box-sizing: border-box;

      .Destroy-center-foot-box {
        height: 53px;
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        // border: 1px solid blue;
        border-bottom: 0.5px solid var(--e1e1e1, #E1E1E1);

        .Destroy-center-foot-left-top {
          width: 130px;
          height: 19px;
          margin-bottom: 6px;
          // border: 1px solid blue;
          line-height: 19px;
          display: flex;
          color: #333;
          font-family: 'MiSans-Demibold';
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
        }

        .Destroy-center-foot-left-top2 {
          width: 130px;
          height: 19px;
          margin-bottom: 6px;
          // border: 1px solid blue;
          line-height: 19px;
          display: flex;
          color: var(--purple, #4D33EF);
          text-align: right;
          font-family: 'MiSans-Demibold';
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          /* 16.8px */

        }

        .Destroy-center-foot-left-p {
          color: #666;
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
        }

        .Destroy-center-foot-ritgh {
          display: flex;
          // border: 1px solid red;
          align-items: center;
          padding-bottom: 14px;
          color: #333;
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;

          /* 14.4px */
          .Destroy-center-foot-ritgh-text {
            color: #333;
            font-family: 'MiSans-Demibold';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            /* 14.4px */
          }



          .Destroy-center-sort-right-img {
            width: 16px;
            height: 16px;
            margin-top: 16px;
          }
        }
      }

    }
  }


  .RankingList-foot-box {
    // border: 1px solid red;
    width: 100%;
    height: 117px;
    margin: 0 auto;
    position: fixed;
    bottom: 0;
    background: rgba(255, 255, 255, 0.66);
    backdrop-filter: blur(2px);
  }

  .RankingList-quick {
    margin: 0 15px;
    height: 62px;
    border-radius: 18px;
    background: radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #4D33EF;
    box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.60) inset, 0px -8px 26px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.60) inset, 0px 3px 9px 0px rgba(255, 255, 255, 0.30) inset, -3px -3px 9px 0px rgba(255, 255, 255, 0.25) inset;
    text-align: center;
    line-height: 62px;
    color: #FFF;
    font-family: 'MiSans-Demibold';
    font-size: 17px;
    font-style: normal;
    font-weight: 900;
    margin-top: 26px;
    margin-bottom: 15px;
  }

  .MyMiningCave-Popup {
    &-top {
      display: flex;
      align-items: center;

      &-img {
        border-radius: 12px;
        overflow: hidden;
      }

      &-content {
        margin-left: 8px;
        display: flex;
        flex-direction: column;

        &-text1 {
          color: var(--333, #333);
          font-family: PingFang SC;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          /* 26.4px */
        }

        &-text2 {
          color: var(--999, #999);
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          /* 24px */
        }
      }
    }

    &-list {
      display: grid;
      grid-template-columns: 48% 48%;
      justify-content: space-between;
      margin-top: 8px;
      height: 200px;
      overflow: auto;

      &-item {
        height: 50px;
        margin-top: 8px;
        display: flex;
        padding: 0px 20px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: 12px;
        /* 16.8px */
        box-sizing: border-box;
        background: var(--grey_bg, #F6F6F6);
        color: var(--black, #1D1D1F);
        font-family: 'MiSans-Demibold';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        position: relative;
        /* 16.8px */

        &-LOCK {
          position: absolute;
          right: 0;
          top: 0;
          display: flex;
          height: 17px;
          padding: 0 6px;
          justify-content: center;
          align-items: center;
          border-radius: 0px 4px;
          background: #333;
          color: var(--fff, #FFF);
          font-family: 'MiSans-Demibold';
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          box-sizing: border-box;
          /* 12px */
        }
      }

      .active {
        background: var(--purple, #4D33EF);
        mix-blend-mode: darken;
        color: var(--fff, #FFF);
        font-family: 'MiSans-Demibold';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
      }
    }
  }

}