#giveRoug {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background-color: #000;
  padding-top: 62px;
  overflow: hidden;

  .giveRoug-top {
    width: 100%;
    color: #fff;
    background-color: black;
    height: 192px;
    display: flex;
    justify-content: space-between;
    position: relative;

    .giveRoug-top-video {
      position: absolute;
      width: 226px;
      right: -20px;
    }

    .giveRoug-top-left {
      width: 191px;
      z-index: 1;

      .giveRoug-top-left-h3 {
        padding-top: 55px;
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        margin-left: 12px;
      }
    }

    .giveRoug-top-left-logo {
      height: 22px;
      margin-left: 12px;
      border-radius: 14px;
      border: 2px solid rgba(255, 255, 255, 0.08);
      background: #1C1E25;
      display: flex;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      margin-top: 12px;

      .giveRoug-top-left-logo-img {
        margin-top: 4px;
      }
    }

    .giveRoug-video {
      width: 196px;
      height: 192px;
    }
  }

  .giveRoug-center {
    height: 600px;
    margin: 0 auto;
    border: 1px solid #fff;
    border-radius: 22px 22px 0px 0px;
    background: #FFF;
    box-shadow: 0px -9px 12px 0px rgba(0, 0, 0, 0.12);
    padding: 0 14px;


    .giveRoug-center-illustrate {
      height: 64px;
      margin: 0 auto;
      margin-top: 22px;
      border-radius: 12px;
      background: rgba(77, 51, 239, 0.08);
      padding: 12px;
      color: var(--purple, #4D33EF);
      font-family: PingFang SC;
      font-size: 13px;
      font-weight: 600;
      line-height: 22px;
      white-space: nowrap;
    }

    .giveRoug-center-box {}

    .giveRoug-center-id {
      height: 52px;
      line-height: 52px;
      margin-top: 14px;
      border-radius: 12px;
      background: var(--grey_bg, #F6F6F6);
      color: #999;
      font-family: PingFang SC;
      font-size: 14px;
      // border: 1px solid red;

      .giveRoug-center-id-p {
        margin-left: 15px;
        width: 318px;
        height: 48px;
        background: var(--grey_bg, #F6F6F6);
        border: none;
        outline: none;
        border-radius: 12px;
        // border: 1px solid blueviolet;
      }
    }

    .giveRoug-center-num {
      height: 52px;
      line-height: 52px;
      margin-top: 14px;
      border-radius: 12px;
      background: var(--grey_bg, #F6F6F6);
      color: #999;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .giveRoug-center-num-p {
        margin-left: 15px;
        border: none;
        background: var(--grey_bg, #F6F6F6);
        width: 250px;
        outline: none;
      }

      .giveRoug-center-num-big {
        width: 49px;
        height: 28px;
        border-radius: 8px;
        text-align: center;
        line-height: 26px;
        color: #000;
        border: 1px solid #000;
        margin-right: 20px;
      }
    }

    .giveRoug-center-notice {
      width: 347px;
      height: 22px;
      display: flex;
      margin-top: 14px;
      margin-bottom: 14px;
      color: var(--purple, #4D33EF);
      font-family: PingFang SC;
      font-size: 12px;
      line-height: 22px;
      font-weight: 500;

      //组件Checkbox的style名字
      // .adm-checkbox-icon svg{
      //     width: 16px;
      //     height: 16px;
      //     background-color: #999;
      // }

      .giveRoug-center-notice-p {
        color: #4D33EF;
        margin-left: 4px;

        .giveRoug-center-notice-p-span {
          color: #999;
        }

        .giveRoug-center-notice-p-a {
          color: #4D33EF;
        }
      }
    }

    .giveRoug-center-notice input {
      width: 15px;
      height: 15px;
      margin-top: 2px;
      margin-right: 4px;
      background-color: #999;
    }

    .giveRoug-center-notice span {
      color: #999;
    }

    .giveRoug-center-notice-btn {
      width: 100%;
      height: 52px;
      border-radius: 12px;
      background: var(--black, #1D1D1F);
      color: #FFF;
      font-size: 16px;
      // margin-left: 18px;
      border: none;
    }
  }

}